import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import 'date-fns';
import ruLocale from "date-fns/locale/ru";
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import {Loader} from '.'

export default function ChampProfile(props) {
    const {data, isLoading} = props;
    const {title, time_start, time_finish} = data;

    const {paper, input} = useStyles();

    return (
        <Paper elevation={2} className={paper}>
            <TextField
                id="standard-read-only-input"
                label="Название чемпионата"
                value={title}
                variant="outlined"
                className={input}
                multiline
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
                <div className={input}>
                    <KeyboardDatePicker
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{width: '100%'}}
                        id="date-picker-inline"
                        label="Дата начала"
                        value={time_start}
                        inputVariant="outlined"
                        TextFieldComponent={TextFieldComponent}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        style={{width: '100%'}}
                        id="date-picker-inline"
                        label="Дата конца"
                        value={time_finish}
                        inputVariant="outlined"
                        TextFieldComponent={TextFieldComponent}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                </div>
            </MuiPickersUtilsProvider>
            <Loader isLoading={isLoading}/>
        </Paper>
    );
}

const TextFieldComponent = (props) => {
    return <TextField 
            {...props} 
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <EventIcon color={'inherit'}/>
                    </InputAdornment>
                ),
                readOnly: true
            }}
        />
  }

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
        padding: theme.spacing(3),
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
    },
    input: {
        width: '100%'
    }
}));
