import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

import { TripleSlider } from '.';

export default function BattlesRightsDialog(props) {
  const { open, onClose, onConfirm, title } = props;
  const [rights, setRights] = useState({});
  const styles = useStyles();
  useEffect(() => {
    if (!open) {
      setRights({});
    }
  }, [open]);

  const onRightCommentsViewChange = (value) => {
    setRights({
      ...rights,
      comments: value,
      changed_comments: typeof value === 'undefined' ? false : true,
    });
  };

  const onRightCommentsWriteChange = (value) => {
    setRights({
      ...rights,
      write: value,
      changed_write: typeof value === 'undefined' ? false : true,
    });
  };

  const onRightVideoViewChange = (value) => {
    setRights({
      ...rights,
      watch: value,
      changed_watch: typeof value === 'undefined' ? false : true,
    });
  };

  const onRightVideoUploadChange = (value) => {
    setRights({
      ...rights,
      upload: value,
      changed_upload: typeof value === 'undefined' ? false : true,
    });
  };

  const onRightGradesGiveChange = (value) => {
    setRights({
      ...rights,
      give_grades: value,
      changed_give_grades: typeof value === 'undefined' ? false : true,
    });
  };

  const onRightGradesSeeChange = (value) => {
    setRights({
      ...rights,
      see_grades: value,
      changed_see_grades: typeof value === 'undefined' ? false : true,
    });
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      TransitionComponent={Transition}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {title ?? 'Изменить права доступа всех упражнений'}
      </DialogTitle>
      <DialogContent className={styles.mainContainer}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TripleSlider
            title={'Какие комментарии доступны для просмотра:'}
            onChange={onRightCommentsViewChange}
            value={rights?.comments}
            data={[
              {
                value: undefined,
                label: 'Не\nизменять',
              },
              {
                value: null,
                label: 'Н/У',
              },
              {
                value: 'none',
                label: 'Никакие',
              },
              {
                value: 'self',
                label: 'Только свои',
              },
              {
                value: 'all',
                label: 'Все',
              },
            ]}
          />
          <TripleSlider
            title={'Оставлять и редактировать свои комментарии:'}
            onChange={onRightCommentsWriteChange}
            value={rights?.write}
            data={[
              {
                value: undefined,
                label: 'Не изменять',
              },
              {
                value: null,
                label: 'Н/У',
              },
              {
                value: false,
                label: 'Нет',
              },
              {
                value: true,
                label: 'Да',
              },
            ]}
          />
          <TripleSlider
            title={'Загружать видео на упражнение:'}
            onChange={onRightVideoUploadChange}
            value={rights?.upload}
            data={[
              {
                value: undefined,
                label: 'Не изменять',
              },
              {
                value: null,
                label: 'Н/У',
              },
              {
                value: false,
                label: 'Нет',
              },
              {
                value: true,
                label: 'Да',
              },
            ]}
          />
          <TripleSlider
            title={'Просматривать видео упражнений:'}
            onChange={onRightVideoViewChange}
            value={rights?.watch}
            data={[
              {
                value: undefined,
                label: 'Не изменять',
              },
              {
                value: null,
                label: 'Н/У',
              },
              {
                value: false,
                label: 'Нет',
              },
              {
                value: true,
                label: 'Да',
              },
            ]}
          />
          <TripleSlider
            title={'Ставить оценки:'}
            onChange={onRightGradesGiveChange}
            value={rights?.give_grades}
            data={[
              {
                value: undefined,
                label: 'Не изменять',
              },
              {
                value: null,
                label: 'Н/У',
              },
              {
                value: false,
                label: 'Нет',
              },
              {
                value: true,
                label: 'Да',
              },
            ]}
          />
          <TripleSlider
            title={'Видеть оценки:'}
            onChange={onRightGradesSeeChange}
            value={rights?.see_grades}
            data={[
              {
                value: undefined,
                label: 'Не изменять',
              },
              {
                value: null,
                label: 'Н/У',
              },
              {
                value: 'no',
                label: 'Нет',
              },
              {
                value: 'self',
                label: 'Только свои',
              },
              {
                value: 'yes',
                label: 'Да',
              },
            ]}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Отмена
        </Button>
        <Button onClick={() => onConfirm(rights)} color="primary">
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    backgroundColor: '#FFF',
    padding: 80,
    paddingBottom: 30,
    paddingTop: 10,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
