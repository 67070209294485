import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { TextareaAutosize } from '@material-ui/core';

const TextAreaAutosize = ({
  value,
  wrapperClassName,
  textAreaClassName,
  isInitiallyEditable = false,
  onChange = () => {},
  ...props
}) => {
  const styles = useStyles();

  return (
    <TextareaAutosize
      {...props}
      className={classNames(styles.editableTextArea, textAreaClassName)}
      value={value}
      onChange={onChange}
    />
  );
};

const useStyles = makeStyles({
  editableTextArea: {
    resize: 'none',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '18px',
    outline: 'none',
    width: '100%',
    border: 'none',
    borderRadius: 6,
    padding: 14,
    boxSizing: 'border-box',
  },
});

export default TextAreaAutosize;
