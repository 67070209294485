import React, { forwardRef, useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';

import { UserItem, CaseItem, Loader, SearchInput, TripleSlider } from '.';
import {
  getCaseList,
  getChampUserList,
  getStageRoleList,
} from '../network/fetchApi';

export default function EditBattle(props) {
  const {
    headerRoles,
    newData,
    setNewData,
    defaultActiveStep,
    open,
    onComplete,
    submitButtonText,
    isLoading,
  } = props;
  const { root, backButton, content, buttonsContainer, stepContent } =
    useStyles();

  const [activeStep, setActiveStep] = useState(
    defaultActiveStep ? defaultActiveStep : 0,
  );
  const [completed, setCompleted] = useState({});
  const [allCompleted, setAllCompleted] = useState(false);
  const [battleData, setBattleData] = useState([]);

  const makeBattleData = () => {
    let result = [];

    result.push({
      title: 'Права доступа',
      content: (
        <StepRights
          key={'rights'}
          {...newData}
          onRightCommentsViewChange={onRightCommentsViewChange}
          onRightCommentsWriteChange={onRightCommentsWriteChange}
          onRightVideoViewChange={onRightVideoViewChange}
          onRightVideoUploadChange={onRightVideoUploadChange}
          onRightGradesGiveChange={onRightGradesGiveChange}
          onRightGradesSeeChange={onRightGradesSeeChange}
        />
      ),
    });

    result.push({
      title: 'Роль Участника',
      content: (
        <Step2 key={'player'} {...newData} onPlayerChange={onNewPlayerChange} />
      ),
    });

    result.push({
      title: 'Кейс',
      content: (
        <Step1
          key={'case'}
          {...newData}
          onStageCaseChange={onNewStageCaseChange}
        />
      ),
    });

    result.push({
      title: 'Роль Клиента',
      content: (
        <Step3 key={'client'} {...newData} onClientChange={onNewClientChange} />
      ),
    });

    // result.push({
    //     title: 'Роль Внутренний эксперт',
    //     content: <Step4 {...newData} onExpertsInChange={onNewExpertsInChange}/>
    // })

    // result.push({
    //     title: 'Роль Внешних эксперт',
    //     content: <Step5 {...newData} onExpertsExChange={onNewExpertsExChange}/>
    // })

    headerRoles
      .filter((header) => header.code_name === '')
      .map((item) => {
        result.push({
          title: `Роль ${item.title}`,
          content: (
            <StepRole
              key={item.stage_roles_id}
              headerRoleData={item}
              {...newData}
              onRoleUsersChange={onNewRoleUsersChange}
            />
          ),
        });
      });

    return result;
  };

  const onCompleteEditBattle = () => {
    const {
      stage,
      stageCase,
      player,
      client,
      experts_internal,
      experts_external,
      roles,
      rights,
    } = newData;
    onComplete({
      // id: data.championship.id,
      stage_id: stage.id,
      case_id: stageCase ? stageCase.id : null,
      player_id: player ? player.id : null,
      client_id: client ? client.id : null,
      experts_internal: experts_internal
        ? [...experts_internal.map((item) => item.id)]
        : [],
      experts_external: experts_external
        ? [...experts_external.map((item) => item.id)]
        : [],
      roles: roles
        ? roles.map((role) => {
            return {
              stage_roles_id: role.stage_roles_id,
              users_id: role.users ? role.users.map((user) => user.id) : [],
            };
          })
        : [],
      rights: rights
        ? rights
        : {
            comments: null,
            upload: null,
            watch: null,
            write: null,
            give_grades: null,
            see_grades: null,
          },
    });
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const getStepContent = (index) => {
    if (index < battleData.length) return battleData[index].content;
    else return 'Unknown stepIndex';
  };

  const onNewStageCaseChange = (stageCase) => {
    setNewData({ ...newData, stageCase });
  };

  const onNewPlayerChange = (player) => {
    const playerRole = headerRoles.find((role) => role.code_name === 'player');
    const newPlayerData = {
      player,
      roles: getRoleUsersData(playerRole, player),
    };
    setNewData((prevData) => ({ ...prevData, ...newPlayerData }));
  };

  const onNewClientChange = (client) => {
    const clientRole = headerRoles.find((role) => role.code_name === 'client');
    const newClientData = {
      client,
      roles: getRoleUsersData(clientRole, client),
    };
    setNewData((prevData) => ({ ...prevData, ...newClientData }));
  };

  // const onNewExpertsInChange = (expert) => {
  //     let experts_internal = []
  //     if(newData != null && newData.experts_internal != null){
  //         experts_internal = [...newData.experts_internal];
  //         const currentIndex = experts_internal.findIndex((element, index, array) => element.id == expert.id);

  //         if (currentIndex === -1) {
  //             experts_internal.push(expert);
  //         } else {
  //             experts_internal.splice(currentIndex, 1);
  //         }
  //     }else experts_internal.push(expert);

  //     setNewData({...newData, experts_internal})
  // }

  const onRightCommentsViewChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        comments: value,
      },
    });
  };

  const onRightCommentsWriteChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        write: value,
      },
    });
  };

  const onRightVideoViewChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        watch: value,
      },
    });
  };

  const onRightVideoUploadChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        upload: value,
      },
    });
  };

  const onRightGradesGiveChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        give_grades: value,
      },
    });
  };

  const onRightGradesSeeChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        see_grades: value,
      },
    });
  };

  // const onNewExpertsExChange = (expert) => {
  //     let experts_external = []
  //     if(newData.experts_external != null){
  //         experts_external = [...newData.experts_external];
  //         const currentIndex = experts_external.findIndex((item, index, array) => item.id == expert.id);

  //         if (currentIndex === -1) {
  //             experts_external.push(expert);
  //         } else {
  //             experts_external.splice(currentIndex, 1);
  //         }
  //     }else experts_external.push(expert);

  //     setNewData({...newData, experts_external})
  // }

  const onNewRoleUsersChange = (role, user) => {
    setNewData((prevData) => ({
      ...prevData,
      roles: getRoleUsersData(role, user),
    }));
  };

  const getRoleUsersData = (role, user) => {
    let roles = [];

    if (newData.roles != null) {
      roles = [...newData.roles];
      const roleCurrentIndex = roles.findIndex(
        (item) => item.stage_roles_id === role.stage_roles_id,
      );

      if (roleCurrentIndex === -1) {
        roles.push({
          ...role,
          users: [user],
        });
      } else if (role.code_name === '') {
        const roleUserCurrentIndex = roles[roleCurrentIndex].users.findIndex(
          (item) => item.id === user.id,
        );

        if (roleUserCurrentIndex === -1) {
          roles[roleCurrentIndex].users.push(user);
        } else {
          roles[roleCurrentIndex].users.splice(roleUserCurrentIndex, 1);
        }
      } else {
        const roleUserCurrentIndex = roles[roleCurrentIndex].users.findIndex(
          (item) => item.id === user.id,
        );

        if (roleUserCurrentIndex === -1) {
          roles[roleCurrentIndex].users = [user];
        } else {
          roles[roleCurrentIndex].users = [];
        }
      }
    } else {
      roles.push({
        ...role,
        users: [user],
      });
    }

    return roles;
  };

  useEffect(() => {
    setBattleData(makeBattleData());
  }, []);

  const validateStepCase = () => {
    return Boolean(newData.stageCase);
  };

  const validatePlayer = () => {
    return Boolean(newData.player);
  };

  useEffect(() => {
    if (newData != null) {
      setBattleData(makeBattleData());
      let completed = {};

      setAllCompleted(validatePlayer());
      setCompleted(completed);
    }
  }, [newData]);

  useEffect(() => {
    if (!open) setNewData();
  }, [open]);

  return (
    <div className={root}>
      <Stepper
        style={{ overflow: 'auto' }}
        nonLinear
        activeStep={activeStep}
        alternativeLabel
      >
        {battleData &&
          battleData.map((step, index) => (
            <Step key={step.title}>
              <StepButton onClick={handleStep(index)}>{step.title}</StepButton>
            </Step>
          ))}
      </Stepper>
      <div className={content}>
        <div className={stepContent}>{getStepContent(activeStep)}</div>
        <div className={buttonsContainer}>
          <Button
            style={{ flex: 1 }}
            disabled={!allCompleted}
            variant="contained"
            color="primary"
            onClick={onCompleteEditBattle}
          >
            {submitButtonText ? submitButtonText : 'Создать'}
          </Button>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    </div>
  );
}

const StepRights = (props) => {
  const {
    rights,
    onRightCommentsViewChange,
    onRightCommentsWriteChange,
    onRightVideoViewChange,
    onRightVideoUploadChange,
    onRightGradesGiveChange,
    onRightGradesSeeChange,
  } = props;

  return (
    <div>
      <TripleSlider
        title={'Какие комментарии доступны для просмотра:'}
        onChange={onRightCommentsViewChange}
        value={rights?.comments}
        data={[
          {
            value: null,
            label: 'Н/У',
          },
          {
            value: 'none',
            label: 'Никакие',
          },
          {
            value: 'self',
            label: 'Только свои',
          },
          {
            value: 'all',
            label: 'Все',
          },
        ]}
      />
      <TripleSlider
        title={'Оставлять и редактировать свои комментарии:'}
        onChange={onRightCommentsWriteChange}
        value={rights?.write}
        data={[
          {
            value: null,
            label: 'Н/У',
          },
          {
            value: false,
            label: 'Нет',
          },
          {
            value: true,
            label: 'Да',
          },
        ]}
      />
      <TripleSlider
        title={'Загружать видео на упражнение:'}
        onChange={onRightVideoUploadChange}
        value={rights?.upload}
        data={[
          {
            value: null,
            label: 'Н/У',
          },
          {
            value: false,
            label: 'Нет',
          },
          {
            value: true,
            label: 'Да',
          },
        ]}
      />
      <TripleSlider
        title={'Просматривать видео упражнений:'}
        onChange={onRightVideoViewChange}
        value={rights?.watch}
        data={[
          {
            value: null,
            label: 'Н/У',
          },
          {
            value: false,
            label: 'Нет',
          },
          {
            value: true,
            label: 'Да',
          },
        ]}
      />
      <TripleSlider
        title={'Ставить оценки:'}
        onChange={onRightGradesGiveChange}
        value={rights?.give_grades}
        data={[
          {
            value: null,
            label: 'Н/У',
          },
          {
            value: false,
            label: 'Нет',
          },
          {
            value: true,
            label: 'Да',
          },
        ]}
      />
      <TripleSlider
        title={'Видеть оценки:'}
        onChange={onRightGradesSeeChange}
        value={rights?.see_grades}
        data={[
          {
            value: null,
            label: 'Н/У',
          },
          {
            value: 'no',
            label: 'Нет',
          },
          {
            value: 'self',
            label: 'Только свои',
          },
          {
            value: 'yes',
            label: 'Да',
          },
        ]}
      />
    </div>
  );
};

const Step1 = (props) => {
  const { stage, stageCase, onStageCaseChange } = props;
  const { stepContainer, listContainer } = useStyles();

  const [stageCases, setStageCases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [casesFilters, setCasesFilters] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const loadStageCases = (params = {}) => {
    setIsLoading(true);
    if (params.filters == undefined) params.filters = [];
    params.filters.push({
      field: 'stage_id',
      equal: stage.id,
    });
    params.order_by = [
      {
        field: 'number',
        order: 'asc',
      },
    ];
    getCaseList(params, (responseJson) => {
      setIsLoading(false);
      if (responseJson.status == 200) {
        setStageCases(responseJson.body);
      }
    });
  };

  const onCasesFiltersChange = (filters) => {
    setCasesFilters(filters);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    loadStageCases();
  }, []);

  useEffect(() => {
    loadStageCases({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      filters: casesFilters,
    });
  }, [page, rowsPerPage, casesFilters]);

  return (
    <Grid container direction="row" className={stepContainer} spacing={2}>
      <Grid item xs={12} md={5}>
        <Typography variant="h5" style={{ marginBottom: 10, paddingLeft: 5 }}>
          Выбранный кейс:
        </Typography>
        <Paper elevation={3} className={listContainer}>
          {stageCase && <CaseItem data={stageCase} />}
        </Paper>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Список кейсов:
        </Typography>
        <Paper elevation={3} className={listContainer}>
          <div style={{ padding: 10, paddingBottom: 0 }}>
            <SearchInput
              onFiltersChange={onCasesFiltersChange}
              fields={[{ title: 'По названию', value: 'title' }]}
            />
          </div>
          <List>
            {stageCases.items &&
              stageCases.items.map((value) => {
                const labelId = `checkbox-list-label-${value.number}`;

                return (
                  <ListItem
                    key={value.number}
                    role={undefined}
                    dense
                    button
                    onClick={() => onStageCaseChange(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={stageCase ? stageCase.id == value.id : false}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId}>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>Номер: </span>
                        {value.number}
                      </div>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>Название:</span>
                        {value.title}
                      </div>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <Loader isLoading={isLoading} />
        </Paper>
        <TablePagination
          labelRowsPerPage={'Количество строк'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} из ${count !== -1 ? count : `более ${to}`}`
          }
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={stageCases.hits ? stageCases.hits : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

const Step2 = (props) => {
  const { championship, player, onPlayerChange } = props;
  const { stepContainer, listContainer } = useStyles();

  const [champUsers, setChampUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [usersFilters, setUsersFilters] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const loadChampUsers = (params = {}) => {
    setIsLoading(true);
    params.order_by = [
      {
        field: 'last_name',
        order: 'asc',
      },
      {
        field: 'first_name',
        order: 'asc',
      },
      {
        field: 'middle_name',
        order: 'asc',
      },
    ];
    getChampUserList({ id: championship.id }, params, (responseJson) => {
      setIsLoading(false);
      if (responseJson.status == 200) {
        setChampUsers(responseJson.body);
      }
    });
  };

  const onUsersFiltersChange = (filters) => {
    setUsersFilters(filters);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    loadChampUsers();
  }, []);

  useEffect(() => {
    loadChampUsers({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      filters: usersFilters,
    });
  }, [page, rowsPerPage, usersFilters]);

  return (
    <Grid container direction="row" className={stepContainer} spacing={2}>
      <Grid item xs={12} md={5}>
        <Typography variant="h5" style={{ marginBottom: 10, paddingLeft: 5 }}>
          Выбранный аккаунт:
        </Typography>
        <Paper elevation={3} className={listContainer}>
          {player && <UserItem expanded data={player} />}
        </Paper>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Список аккаунтов:
        </Typography>
        <Paper elevation={3} className={listContainer}>
          <div style={{ padding: 10, paddingBottom: 0 }}>
            <SearchInput
              onFiltersChange={onUsersFiltersChange}
              fields={[
                { title: 'По фамилии', value: 'last_name' },
                { title: 'По имени', value: 'first_name' },
                { title: 'По отчеству', value: 'middle_name' },
                { title: 'По почте', value: 'email' },
                { title: 'По должности', value: 'post' },
                { title: 'По руководителю', value: 'chief' },
                { title: 'По команде', value: 'team' },
              ]}
            />
          </div>
          <List>
            {champUsers.items &&
              champUsers.items.map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem
                    key={value.id}
                    role={undefined}
                    dense
                    button
                    onClick={() => onPlayerChange(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={player ? player.id == value.id : false}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId}>
                      <span style={{ fontWeight: 'bold' }}>ФИО: </span>
                      {`${value.last_name} ${value.first_name} ${value.middle_name}`}
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <Loader isLoading={isLoading} />
        </Paper>
        <TablePagination
          labelRowsPerPage={'Количество строк'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} из ${count !== -1 ? count : `более ${to}`}`
          }
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={champUsers.hits ? champUsers.hits : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

const Step3 = (props) => {
  const { championship, client, onClientChange } = props;
  const { stepContainer, listContainer } = useStyles();

  const [champUsers, setChampUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [usersFilters, setUsersFilters] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const loadChampUsers = (params = {}) => {
    setIsLoading(true);
    params.order_by = [
      {
        field: 'last_name',
        order: 'asc',
      },
      {
        field: 'first_name',
        order: 'asc',
      },
      {
        field: 'middle_name',
        order: 'asc',
      },
    ];
    getChampUserList({ id: championship.id }, params, (responseJson) => {
      setIsLoading(false);
      if (responseJson.status == 200) {
        setChampUsers(responseJson.body);
      }
    });
  };

  const onUsersFiltersChange = (filters) => {
    setUsersFilters(filters);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    loadChampUsers();
  }, []);

  useEffect(() => {
    loadChampUsers({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      filters: usersFilters,
    });
  }, [page, rowsPerPage, usersFilters]);

  return (
    <Grid container direction="row" className={stepContainer} spacing={2}>
      <Grid item xs={12} md={5}>
        <Typography variant="h5" style={{ marginBottom: 10, paddingLeft: 5 }}>
          Выбранный аккаунт:
        </Typography>
        <Paper elevation={3} className={listContainer}>
          {client && <UserItem expanded data={client} />}
        </Paper>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Список аккаунтов:
        </Typography>
        <Paper elevation={3} className={listContainer}>
          <div style={{ padding: 10, paddingBottom: 0 }}>
            <SearchInput
              onFiltersChange={onUsersFiltersChange}
              fields={[
                { title: 'По фамилии', value: 'last_name' },
                { title: 'По имени', value: 'first_name' },
                { title: 'По отчеству', value: 'middle_name' },
                { title: 'По почте', value: 'email' },
                { title: 'По должности', value: 'post' },
                { title: 'По руководителю', value: 'chief' },
                { title: 'По команде', value: 'team' },
              ]}
            />
          </div>
          <List>
            {champUsers.items &&
              champUsers.items.map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem
                    key={value.id}
                    role={undefined}
                    dense
                    button
                    onClick={() => onClientChange(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={client ? client.id == value.id : false}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId}>
                      <span style={{ fontWeight: 'bold' }}>ФИО: </span>
                      {`${value.last_name} ${value.first_name} ${value.middle_name}`}
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <Loader isLoading={isLoading} />
        </Paper>
        <TablePagination
          labelRowsPerPage={'Количество строк'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} из ${count !== -1 ? count : `более ${to}`}`
          }
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={champUsers.hits ? champUsers.hits : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

const StepRole = (props) => {
  const { headerRoleData, championship, roles, onRoleUsersChange } = props;
  const { stepContainer, listContainer } = useStyles();

  const [champUsers, setChampUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [usersFilters, setUsersFilters] = useState([]);
  const [roleUsers, setRoleUsers] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const loadChampUsers = (params = {}) => {
    setIsLoading(true);
    params.order_by = [
      {
        field: 'last_name',
        order: 'asc',
      },
      {
        field: 'first_name',
        order: 'asc',
      },
      {
        field: 'middle_name',
        order: 'asc',
      },
    ];
    getChampUserList({ id: championship.id }, params, (responseJson) => {
      setIsLoading(false);
      if (responseJson.status == 200) {
        setChampUsers(responseJson.body);
      }
    });
  };

  const onUsersFiltersChange = (filters) => {
    setUsersFilters(filters);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getRoleUsers = () => {
    const newRoleUsers = roles?.find(
      (item) => item.stage_roles_id === headerRoleData.stage_roles_id,
    );
    if (newRoleUsers) {
      return newRoleUsers.users;
    }
    return [];
  };

  useEffect(() => {
    loadChampUsers();
    setRoleUsers(getRoleUsers());
  }, []);

  useEffect(() => {
    setRoleUsers(getRoleUsers());
  }, [roles, headerRoleData]);

  useEffect(() => {
    loadChampUsers({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      filters: usersFilters,
    });
  }, [page, rowsPerPage, usersFilters]);

  return (
    <Grid container direction="row" className={stepContainer} spacing={2}>
      <Grid item xs={12} md={5}>
        <Typography variant="h5" style={{ marginBottom: 10, paddingLeft: 5 }}>
          {headerRoleData.title}
        </Typography>
        <Paper elevation={3} className={listContainer}>
          {roleUsers.map((user) => (
            <UserItem expanded data={user} key={user.id} />
          ))}
        </Paper>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Список аккаунтов:
        </Typography>
        <Paper elevation={3} className={listContainer}>
          <div style={{ padding: 10, paddingBottom: 0 }}>
            <SearchInput
              onFiltersChange={onUsersFiltersChange}
              fields={[
                { title: 'По фамилии', value: 'last_name' },
                { title: 'По имени', value: 'first_name' },
                { title: 'По отчеству', value: 'middle_name' },
                { title: 'По почте', value: 'email' },
                { title: 'По должности', value: 'post' },
                { title: 'По руководителю', value: 'chief' },
                { title: 'По команде', value: 'team' },
              ]}
            />
          </div>
          <List>
            {champUsers.items &&
              champUsers.items.map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem
                    key={value.id}
                    role={undefined}
                    dense
                    button
                    onClick={() => onRoleUsersChange(headerRoleData, value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={roleUsers.some(
                          (expert) => expert.id == value.id,
                        )}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId}>
                      <span style={{ fontWeight: 'bold' }}>ФИО: </span>
                      {`${value.last_name} ${value.first_name} ${value.middle_name}`}
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <Loader isLoading={isLoading} />
        </Paper>
        <TablePagination
          labelRowsPerPage={'Количество строк'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} из ${count !== -1 ? count : `более ${to}`}`
          }
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={champUsers.hits ? champUsers.hits : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    margin: theme.spacing(5),
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepContent: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsContainer: {
    marginBottom: 20,
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stepContainer: {
    width: '80%',
  },
  listContainer: {
    height: 450,
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
  },
}));
