import * as tus from 'tus-js-client';
import {
  ADMINBATTLE,
  ADMINCASE,
  ADMINCHAMP,
  ADMINCHECKUSERS,
  ADMINCOMPANY,
  ADMINPARSE,
  ADMINSTAGE,
  ADMINUSER,
  ADMINUSERS,
  ADMINVIDEO,
  BATTLELIST,
  BATTLESCSV,
  CASELIST,
  CHAMPLIST,
  COMMENTS,
  COMMENTSCSV,
  COMPANYLIST,
  CSV,
  EMAILSCSV,
  GETBATTLE,
  GETCASE,
  GETCHAMP,
  GETCOMMENT,
  GETCOMPANY,
  GETFILE,
  GETSTAGE,
  GRADES,
  HISTORY,
  INVITESSTAGE,
  ROLE,
  ROLES,
  STAGELIST,
  UPDATERIGHTS,
  UPDATERIGHTSBYIDS,
  USERGET,
  USERLOGOUT,
  USERSIGNIN,
  VIMEOCREATE,
} from './url';

export const fetchApi = (url, method, headers, body, resolve, reject) => {
  fetch(
    url,
    method === 'POST' || body != null
      ? {
          method: method,
          headers: headers,
          body: JSON.stringify(body),
          credentials: 'include',
        }
      : {
          method: method,
          headers: headers,
          credentials: 'include',
        },
  )
    .then((response) => {
      return Promise.all([response.ok, response.status, response.json()]);
    })
    .then((res) => ({ ok: res[0], status: res[1], body: res[2] }))
    .then((responseJson) => {
      if (resolve) resolve(responseJson);
    })
    .catch((error) => {
      console.error(`Error( ${url} ): ${error}`);
      Promise.reject(error);
    });
};

//// API v1
export const userSignIn = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(USERSIGNIN, 'POST', headers, body, response);
};

export const userLogOut = (response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(USERLOGOUT, 'POST', headers, null, response);
};

export const getUserInfo = (response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(USERGET, 'GET', headers, null, response);
};

export const getUserInfoByID = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(`${USERGET}/${body.id}`, 'GET', headers, null, response);
};

export const updateUserInfo = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${ADMINUSER}/${body.id}`, 'POST', headers, body, response);
};

export const checkUsers = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(ADMINCHECKUSERS, 'POST', headers, body, response);
};

export const registerUsers = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(ADMINUSERS, 'POST', headers, body, response);
};

export const updateUsersInfo = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(ADMINUSERS, 'PUT', headers, body, response);
};

export const getFile = (url) => {
  return GETFILE + url;
};

export const parseExcel = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(ADMINPARSE, 'POST', headers, body, response);
};

export const getCompanyList = (params, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let filter = '';
  if (params) filter = '?filter=' + JSON.stringify(params);

  fetchApi(COMPANYLIST + filter, 'GET', headers, null, response);
};

export const addCompany = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(ADMINCOMPANY, 'POST', headers, body, response);
};

export const getCompany = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(`${GETCOMPANY}/${body.id}`, 'GET', headers, null, response);
};

export const updateCompany = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(`${ADMINCOMPANY}/${body.id}`, 'POST', headers, body, response);
};

export const deleteCompany = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(`${ADMINCOMPANY}/${body.id}`, 'DELETE', headers, null, response);
};

export const getChampList = (params, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let filter = '';
  if (params) filter = '?filter=' + JSON.stringify(params);

  fetchApi(CHAMPLIST + filter, 'GET', headers, null, response);
};

export const addChamp = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(ADMINCHAMP, 'POST', headers, body, response);
};

export const getChamp = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${GETCHAMP}/${body.id}`, 'GET', headers, null, response);
};

export const updateChamp = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${ADMINCHAMP}/${body.id}`, 'POST', headers, body, response);
};

export const deleteChamp = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${ADMINCHAMP}/${body.id}`, 'DELETE', headers, null, response);
};

export const getChampUserList = (body, params, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let filter = '';
  if (params) filter = '?filter=' + JSON.stringify(params);

  fetchApi(
    `${ADMINCHAMP}/${body.id}/users` + filter,
    'GET',
    headers,
    null,
    response,
  );
};

export const addChampUsers = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(
    `${ADMINCHAMP}/${body.id}/users`,
    'POST',
    headers,
    body.data,
    response,
  );
};

export const updateChampUser = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(
    `${ADMINCHAMP}/${body.championship_id}/user/${body.id}`,
    'POST',
    headers,
    body,
    response,
  );
};

export const deleteChampUser = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(
    `${ADMINCHAMP}/${body.championship_id}/user/${body.id}`,
    'DELETE',
    headers,
    null,
    response,
  );
};

export const confirmChampUsers = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(
    `${ADMINCHAMP}/${body.id}/confirmations`,
    'POST',
    headers,
    null,
    response,
  );
};

export const getStageList = (params, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let filter = '';
  if (params) filter = '?filter=' + JSON.stringify(params);

  fetchApi(STAGELIST + filter, 'GET', headers, null, response);
};

export const addStage = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(ADMINSTAGE, 'POST', headers, body, response);
};

export const getStage = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${GETSTAGE}/${body.id}`, 'GET', headers, null, response);
};

export const updateStage = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${ADMINSTAGE}/${body.id}`, 'POST', headers, body, response);
};

export const deleteStage = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${ADMINSTAGE}/${body.id}`, 'DELETE', headers, null, response);
};

export const enableComments = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(
    `${ADMINSTAGE}/${body.id}/comments`,
    'POST',
    headers,
    body,
    response,
  );
};

export const generateStageBattles = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(`${ADMINSTAGE}/${body.id}/battles`, 'POST', headers, null, response);
};

export const inviteStageBattles = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(`${ADMINSTAGE}/${body.id}/invites`, 'POST', headers, null, response);
};

export const getEmailsCSVByFilter = (body, response) => {
  fetchApi(
    `/admin/emailsfilter?start=` + body.start + '&end=' + body.end,
    'POST',
    {},
    null,
    response,
  );
};

export const resendEmails = (id, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(`${EMAILSCSV}/resend/${id}`, 'GET', headers, null, response);
};

export const autoInviteStageBattles = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(`${INVITESSTAGE}/${body.id}`, 'POST', headers, body.data, response);
};

export const inviteStageBattle = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(
    `${ADMINBATTLE}/${body.id}/invites`,
    'POST',
    headers,
    null,
    response,
  );
};

export const getBattlesCSV = (body) => {
  window.open(`${ADMINSTAGE}/${body.id}${BATTLESCSV}`);
};

export const getEmailsCSV = (body) => {
  window.open(`${EMAILSCSV}?id=${body}`);
};

export const getCommentsForStageCSV = (body) => {
  window.open(`${ADMINSTAGE}/${body.id}${COMMENTSCSV}`);
};

export const getCaseList = (params, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let filter = '';
  if (params) filter = '?filter=' + JSON.stringify(params);

  fetchApi(CASELIST + filter, 'GET', headers, null, response);
};

export const addCase = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(ADMINCASE, 'POST', headers, body, response);
};

export const getCase = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${GETCASE}/${body.id}`, 'GET', headers, null, response);
};

export const updateCase = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${ADMINCASE}/${body.id}`, 'POST', headers, body, response);
};

export const deleteCase = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${ADMINCASE}/${body.id}`, 'DELETE', headers, null, response);
};

export const getBattleList = (params, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let filter = '';
  if (params) filter = '?filter=' + JSON.stringify(params);

  fetchApi(BATTLELIST + filter, 'GET', headers, null, response);
};

export const addBattle = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(ADMINBATTLE, 'POST', headers, body, response);
};

export const getBattle = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${GETBATTLE}/${body.id}`, 'GET', headers, null, response);
};

export const getBattleFull = (body, params, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let filter = '';
  if (params) filter = '?filter=' + JSON.stringify(params);

  fetchApi(
    `${GETBATTLE}/${body.id}/full` + filter,
    'GET',
    headers,
    null,
    response,
  );
};

export const updateBattle = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${ADMINBATTLE}/${body.id}`, 'POST', headers, body, response);
};

export const deleteBattle = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${ADMINBATTLE}/${body.id}`, 'DELETE', headers, null, response);
};

// export const updateBattlesRights = (body, response) => {
//     var headers = {
//         Accept: 'application/json',
//         'Content-Type': 'application/json'
//     }

//     fetchApi(`${ADMINBATTLES}/${body.id}${ROLES}`, 'PUT', headers, body.data, response)
// }

export const updateBattlesRights = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(
    `${ADMINSTAGE}/${body.id}${UPDATERIGHTS}`,
    'PUT',
    headers,
    body.data,
    response,
  );
};

export const updateBattlesRightsByIds = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetchApi(
    `${ADMINSTAGE}/${body.id}${UPDATERIGHTSBYIDS}`,
    'PUT',
    headers,
    body.data,
    response,
  );
};

export const addBattleVideo = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${GETBATTLE}/${body.id}/video`, 'POST', headers, body, response);
};

export const addBattleComment = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${GETBATTLE}/${body.id}/comment`, 'POST', headers, body, response);
};

export const updateComment = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${GETCOMMENT}/${body.id}`, 'POST', headers, body, response);
};

export const deleteComment = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${GETCOMMENT}/${body.id}`, 'DELETE', headers, body, response);
};

export const deleteAllComment = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(
    `${ADMINBATTLE}/${body.id}${COMMENTS}`,
    'DELETE',
    headers,
    body,
    response,
  );
};

export const getVideoHistory = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(
    `${ADMINBATTLE}/${body.id}${HISTORY}`,
    'GET',
    headers,
    null,
    response,
  );
};

export const getVideoCSV = (body) => {
  window.open(`${ADMINVIDEO}/${body.id}${CSV}`);
};

export const addStageRoles = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(
    `${ADMINSTAGE}/${body.id}${ROLES}`,
    'POST',
    headers,
    body.data,
    response,
  );
};

export const getStageRoleList = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(`${GETSTAGE}/${body.id}${ROLES}`, 'GET', headers, null, response);
};

export const deleteStageRole = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(
    `${ADMINSTAGE}/${body.stage_id}${ROLE}/${body.id}`,
    'DELETE',
    headers,
    null,
    response,
  );
};

export const updateStageRoles = (body, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(
    `${ADMINSTAGE}/${body.id}${ROLES}`,
    'PUT',
    headers,
    body.data,
    response,
  );
};

export const createVimeoVideo = (file, response, onProgress) => {
  const body = {
    name: file.name,
    upload: {
      approach: 'tus',
      size: file.size,
    },
    privacy: {
      comments: 'nobody',
      view: 'unlisted',
      download: false,
    },
    embed: {
      buttons: {
        embed: false,
        like: false,
        share: false,
        watchlater: false,
      },
      title: {
        name: 'hide',
        owner: 'hide',
        portrait: 'hide',
      },
      logos: {
        vimeo: false,
      },
      speed: true,
    },
  };

  fetch(VIMEOCREATE, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `bearer ${process.env.REACT_APP_VIMEO_TOKEN}`,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body),
  })
    .then((response) => {
      return Promise.all([response.status, response.json()]);
    })
    .then((res) => ({ status: res[0], body: res[1] }))
    .then((responseJson) => {
      var upload = new tus.Upload(file, {
        uploadUrl: responseJson.body.upload.upload_link,
        onError: function (error) {},
        onSuccess: function () {
          response(responseJson);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          if (onProgress) onProgress(percentage);
        },
      });
      upload.start();
    })
    .catch((error) => {
      console.error('Error(' + VIMEOCREATE + '): ' + error);
    });
};

export const getBattleGrades = (battleId, response) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  fetchApi(
    `${ADMINBATTLE}/${battleId}${GRADES}`,
    'GET',
    headers,
    null,
    response,
  );
};
