const protocolHttps = "https://"
const protocolHttp = "http://"
const postfix = "/api"

const protocol = process.env.NODE_ENV === 'production' ? protocolHttps : protocolHttp;

export const APIHOST = protocol + process.env.REACT_APP_HOST + postfix;

export const USERSIGNIN = APIHOST + '/signin'
export const USERLOGOUT = APIHOST + '/logout'
export const USERGET = APIHOST + '/user'
export const ADMINUSER = APIHOST + '/admin/user'
export const ADMINUSERS = APIHOST + '/admin/users'
export const ADMINCHECKUSERS = APIHOST + '/admin/checkusers'
export const VIMEOCREATE = 'https://api.vimeo.com/me/videos'

export const COMPANYLIST = APIHOST + '/companies'
export const GETCOMPANY = APIHOST + '/company'
export const ADMINCOMPANY = APIHOST + '/admin/company'
export const CHAMPLIST = APIHOST + '/championships'
export const GETCHAMP = APIHOST + '/championship'
export const ADMINCHAMP = APIHOST + '/admin/championship'
export const STAGELIST = APIHOST + '/stages'
export const GETSTAGE = APIHOST + '/stage'
export const ADMINSTAGE = APIHOST + '/admin/stage'
export const CASELIST = APIHOST + '/cases'
export const GETCASE = APIHOST + '/case'
export const ADMINCASE = APIHOST + '/admin/case'
export const BATTLELIST = APIHOST + '/battles'
export const GETBATTLE = APIHOST + '/battle'
export const ADMINBATTLE = APIHOST + '/admin/battle'
export const ADMINBATTLES = APIHOST + '/admin/battles'
export const GETCOMMENT = APIHOST + '/comment'
export const COMMENTS =  '/comments'
export const GETFILE = protocol + process.env.REACT_APP_HOST
export const ADMINPARSE = APIHOST + '/admin/parse'
export const ADMINVIDEO = APIHOST + '/admin/video'
export const HISTORY = '/videohistory'
export const CSV = '/csv'
export const ROLES = '/roles'
export const ROLE = '/role'
export const UPDATERIGHTS = '/battles/rights/update'
export const UPDATERIGHTSBYIDS = '/battles/rights/updateByIds'
export const BATTLESCSV = '/battles/csv'
export const COMMENTSCSV = '/comments/csv'
export const INVITESSTAGE = APIHOST + '/admin/invites/stage'
export const EMAILSCSV = APIHOST + '/admin/emails'
export const GRADES = '/grades'