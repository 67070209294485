import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

import { StepperDialog, PdfViewerPanel } from '.';
import { getFile } from '../network/fetchApi';

export default function NewCaseDialog(props) {
  const {
    data,
    title,
    open,
    onClose,
    rightElement,
    submitButtonText,
    isLoading,
    onComplete,
  } = props;
  const { root } = useStyles();

  const [allCompleted, setAllCompleted] = useState(false);
  const [completed, setCompleted] = useState({});

  const [newData, setNewData] = useState({});

  const onNewNumberChange = (event) => {
    setNewData({ ...newData, number: event.target.value });
  };

  const onNewTitleChange = (event) => {
    setNewData({ ...newData, title: event.target.value });
  };

  const onNewGeneralTaskChange = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setNewData({ ...newData, pdf_data_general: reader.result });
    };
    reader.onerror = function (error) {};
  };

  const onNewPlayerTaskChange = (e) => {
    // setNewData({...newData, task_player: e.target.files[0]})
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setNewData({ ...newData, pdf_data_player: reader.result });
    };
    reader.onerror = function (error) {};
  };

  const onNewClientTaskChange = (e) => {
    // setNewData({...newData, task_client: e.target.files[0]})
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setNewData({ ...newData, pdf_data_client: reader.result });
    };
    reader.onerror = function (error) {};
  };

  const validateStep1 = () => {
    return (
      newData.number &&
      newData.number.length > 0 &&
      newData.title &&
      newData.title.length > 0
    );
  };

  const validateStep2 = () => {
    return (
      ((newData.pdf_data_general && newData.pdf_data_general.length > 0) ||
        newData.pdf_link_general) &&
      ((newData.pdf_data_player && newData.pdf_data_player.length > 0) ||
        newData.pdf_link_general) &&
      ((newData.pdf_data_client && newData.pdf_data_client.length > 0) ||
        newData.pdf_link_general)
    );
  };

  useEffect(() => {
    if (newData) {
      let completed = {};

      completed[0] = validateStep1();
      completed[1] = validateStep2();
      setAllCompleted(validateStep1());

      setCompleted(completed);
    }
  }, [newData]);

  useEffect(() => {
    setNewData(data ? data : {});
  }, [open]);

  return (
    <StepperDialog
      title={title}
      data={[
        {
          title: 'Номер кейса',
          content: (
            <StepNumber {...newData} onNumberChange={onNewNumberChange} />
          ),
        },
        {
          title: 'Название кейса',
          content: <Step1 {...newData} onTitleChange={onNewTitleChange} />,
        },
        {
          title: 'Общее условия',
          content: (
            <StepPdf
              link={newData.pdf_link_general}
              file={newData.pdf_data_general}
              onChange={onNewGeneralTaskChange}
            />
          ),
        },
        {
          title: 'Условие для участника',
          content: (
            <StepPdf
              link={newData.pdf_link_player}
              file={newData.pdf_data_player}
              onChange={onNewPlayerTaskChange}
            />
          ),
        },
        {
          title: 'Условие для клиента',
          content: (
            <StepPdf
              link={newData.pdf_link_client}
              file={newData.pdf_data_client}
              onChange={onNewClientTaskChange}
            />
          ),
        },
      ]}
      completed={completed}
      submitButtonText={submitButtonText}
      allCompleted={allCompleted}
      open={open}
      onClose={onClose}
      rightElement={rightElement}
      onComplete={() => {
        onComplete({
          ...newData,
          pdf_data_general: newData.pdf_data_general
            ? newData.pdf_data_general.split(',')[1]
            : newData.pdf_data_general,
          pdf_data_player: newData.pdf_data_player
            ? newData.pdf_data_player.split(',')[1]
            : newData.pdf_data_player,
          pdf_data_client: newData.pdf_data_client
            ? newData.pdf_data_client.split(',')[1]
            : newData.pdf_data_client,
        });
      }}
      isLoading={isLoading}
    />
  );
}

const StepNumber = (props) => {
  const { number, onNumberChange } = props;

  return (
    <TextField
      value={number}
      onChange={onNumberChange}
      style={{ width: '80%', maxWidth: 500 }}
      multiline
      rowsMax={10}
      id="standard-basic"
      label="Введите номер"
      variant="outlined"
    />
  );
};

const Step1 = (props) => {
  const { title, onTitleChange } = props;

  return (
    <TextField
      value={title}
      onChange={onTitleChange}
      style={{ width: '80%', maxWidth: 500 }}
      multiline
      rowsMax={10}
      id="standard-basic"
      label="Введите название"
      variant="outlined"
    />
  );
};

const StepPdf = (props) => {
  const { link, file, onChange } = props;
  const fileInputRef = useRef();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 30,
      }}
    >
      {(file || link) && <PdfViewerPanel file={file ? file : getFile(link)} />}
      <input
        accept="application/pdf"
        ref={fileInputRef}
        type="file"
        hidden
        onChange={onChange}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <label htmlFor="contained-button-file">
        <Button
          onClick={() => fileInputRef.current.click()}
          variant="contained"
          color="primary"
          component="span"
          startIcon={<GetAppIcon />}
        >
          Загрузить новый PDF
        </Button>
      </label>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    maxWidth: 800,
  },
  input: {
    width: '100%',
  },
}));
