import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';

export default function CommentsMenu(props){
    const {anchor, onClose, onClearClick, onDownloadClick} = props;

    return (
        <Menu
            id="simple-menu"
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={onClose}
        >
            <MenuItem onClick={onDownloadClick}>
                <ListItemIcon>
                    <GetAppIcon color='primary' fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" color='primary'>Скачать</Typography>
            </MenuItem>
            <MenuItem onClick={onClearClick}>
                <ListItemIcon>
                    <DeleteIcon color='secondary' fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" color='secondary'>Очистить</Typography>
            </MenuItem>
        </Menu>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));
