import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  settingsDial: {
    zIndex: 100,
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2)
  },
  addDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  fab1: {
    zIndex: 100,
    position: 'fixed',
    bottom: 85,
    right: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(2)
  },
  downloadIconContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center'
  },
  downloadSubIcon: {
    position: 'absolute',
    top: -6,
    right: -3,
    fontWeight: 'bold',
    fontSize: '0.75rem'
  },
  battlesRights: {
    zIndex: 100,
    position: 'fixed',
    bottom: 85,
    left: theme.spacing(2),
  },
}));
