import React, {forwardRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import {EditBattle, SincBattlesButton} from '.'

export default function NewBattleDialog(props) {
    const {data, headerRoles, open, onClose, onGenerateBattles, onComplete, isLoading} = props;
    const {appBar, header, tabs} = useStyles();

    const [newData, setNewData] = useState();

    return (
        <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
            <AppBar className={appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={header}>
                    Новое упражнение
                </Typography>
                <SincBattlesButton onClick={onGenerateBattles}/>
            </Toolbar>
            </AppBar>
            <EditBattle newData={{...newData, ...data}} setNewData={setNewData} 
                headerRoles={headerRoles}
                open={open} onComplete={onComplete} isLoading={isLoading}/>
        </Dialog>
    );
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    header: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    tabs: {
        margin: theme.spacing(2)
    }
}));
