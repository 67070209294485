import React from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const HelpTooltip = ({ text }) => {
  const styles = useStyles();

  return (
    <Tooltip title={text} disableFocusListener disableTouchListener>
      <div className={styles.tooltipTrigger}>?</div>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  tooltipTrigger: {
    width: 16,
    height: 16,
    borderRadius: 16,
    backgroundColor: '#C4C4C4',
    color: '#fff',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    fontSize: 11,
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
});

export default HelpTooltip;
