import BaseAPI from './BaseAPI';

class CompetenceAPI extends BaseAPI {
  static path = '/admin/competence';

  static getCompetencies(params) {
    const queryParams = params ? '?filter=' + JSON.stringify(params) : '';

    return CompetenceAPI.call(`${CompetenceAPI.path}${queryParams}`, {
      method: 'GET',
    });
  }

  static getCompetence(id) {
    return CompetenceAPI.call(`${CompetenceAPI.path}/${id}`);
  }

  static updateCompetence(id, data) {
    return CompetenceAPI.call(`${CompetenceAPI.path}/${id}`, {
      method: 'PUT',
      body: data,
    });
  }
}

export default CompetenceAPI;
