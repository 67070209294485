import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

import { TextAreaAutosize, SymbolCircle } from '../../../../../../components';
import { useStyles } from './styles';

const GradesList = ({ grades, onGradeDataChange }) => {
  const [selectedGrade, setSelectedGrade] = useState(null);

  const styles = useStyles();

  const handleDescriptionChange = (event) => {
    if (!selectedGrade) return;

    const selectedGradeIndex = grades.findIndex(
      (gradeData) => selectedGrade.grade === gradeData.grade,
    );
    const updatedGradeData = {
      ...grades[selectedGradeIndex],
      grade: grades[selectedGradeIndex].grade,
      description: event.target.value,
    }
    onGradeDataChange([
      ...grades.slice(0, selectedGradeIndex),
      updatedGradeData,
      ...grades.slice(selectedGradeIndex + 1),
    ]);
    setSelectedGrade(updatedGradeData);
  };

  return (
    <div className={styles.gradesWrapper}>
      <div className={styles.gradesTitle}>Оценки</div>
      <Grid container justify="flex-start">
        {grades.map((gradeData, index) => (
          <div className={styles.gradeWrapper} key={index}>
            <SymbolCircle
              symbol={gradeData.grade}
              selected={
                (selectedGrade && selectedGrade.grade) === gradeData.grade
              }
              onClick={() => setSelectedGrade(gradeData)}
            />
          </div>
        ))}
      </Grid>
      {selectedGrade && (
        <div className={styles.editableTextWrapper}>
          <TextAreaAutosize
            value={selectedGrade.description}
            onChange={handleDescriptionChange}
          />
        </div>
      )}
    </div>
  );
};

export default GradesList;
