import React, { useEffect, useReducer, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import {
  competenceDataSelector,
  competenceLoadingSelector,
  competenceUpdateSuccessSelector,
  competenceUpdatingSelector,
} from '../../../redux/selectors/competence.selectors';
import {
  getCompetence,
  updateCompetence,
  resetUpdateSuccess,
} from '../../../redux/slices/competence.slice';
import { useStyles } from './styles';
import CompetenceGrades from './CompetenceGrades';
import Criteria from './Criteria';
import CompetenceRoles from './CompetenceRoles';
import {
  competenceInitialState,
  competenceReducer,
  setCompetenceData,
} from './reducer';
import { Loader } from '../../../components';
import CompetenceUpdatedModal from './CompetenceUpdatedDialog';
import { useSwitch } from '../../../utils/hooks/useSwitch';

const CompetenceTab = ({ stageId }) => {
  const [competence, competenceDispatch] = useReducer(
    competenceReducer,
    competenceInitialState,
  );

  const [
    isCompUpdatedDialogOpen,
    openCompUpdatedDialog,
    closeCompUpdatedDialog,
  ] = useSwitch();

  const styles = useStyles();

  const competenceData = useSelector(competenceDataSelector);
  const competenceLoading = useSelector(competenceLoadingSelector);
  const competenceUpdating = useSelector(competenceUpdatingSelector);
  const competenceUpdateSuccess = useSelector(competenceUpdateSuccessSelector);

  const dispatch = useDispatch();

  const handleSaveClick = () => {
    dispatch(
      updateCompetence({
        id: competenceData.id,
        data: {
          ...competence,
          criteria: Object.values(competence.criteria),
          roles_with_weights: competence.roles_with_weights,
        },
      }),
    );
  };

  const rolesWeightsSum = useMemo(() => {
    return competence?.roles_with_weights?.reduce(
      (total, role) => total + role?.weight,
      0,
    );
  }, [competence.roles_with_weights]);

  const criteriaWeightsSum = useMemo(
    () =>
      Object.values(competence.criteria).reduce(
        (accum, criterion) => accum + criterion.weight,
        0,
      ),
    [competence.criteria],
  );

  const handleCloseDialog = () => {
    closeCompUpdatedDialog();
    // Сбросить competenceUpdateSuccess на false
    dispatch(resetUpdateSuccess());
  };

  useEffect(() => {
    dispatch(getCompetence({ stageId }));
  }, [dispatch, stageId]);

  useEffect(() => {
    if (Object.keys(competenceData).length)
      competenceDispatch(setCompetenceData(competenceData));
  }, [competenceData]);

  useEffect(() => {
    if (competenceUpdateSuccess) openCompUpdatedDialog();
  }, [competenceUpdateSuccess, openCompUpdatedDialog]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={3}
      className={styles.competenceTabContainer}
      style={{ position: 'relative' }}
    >
      <Grid item container spacing={3} justify="center">
        <Grid className={styles.competenciesContainer} item sm={12} md={6}>
          <Criteria
            criteria={competence.criteria}
            competenceDispatch={competenceDispatch}
          />
        </Grid>
        <Grid
          className={styles.competenceGradesAndRolesWrapper}
          item
          sm={12}
          md={6}
        >
          <CompetenceRoles
            rolesWithWeights={competence.roles_with_weights}
            competenceDispatch={competenceDispatch}
          />
          <CompetenceGrades
            minGrade={competence.min_grade}
            maxGrade={competence.max_grade}
            competenceDispatch={competenceDispatch}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveClick}
          disabled={
            competenceUpdating || rolesWeightsSum > 1 || criteriaWeightsSum > 1
          }
        >
          Сохранить
        </Button>
      </Grid>
      <Loader isLoading={competenceLoading || competenceUpdating} />
      <CompetenceUpdatedModal
        open={isCompUpdatedDialogOpen}
        onClose={handleCloseDialog}
      />
    </Grid>
  );
};

export default CompetenceTab;
