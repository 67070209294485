import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { Loader } from '.';

export default function DataTable(props) {
  const { initialOrder, headers, data, onRowClick, isLoading, onOrderChange } =
    props;

  const { root, container, cell } = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [orderBy, setOrderBy] = useState(initialOrder ? initialOrder : []);
  const [orderByAsc, setOrderByAsc] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const onSortClick = (orderBy) => {
    if (orderBy) {
      orderBy.map((item) => (item.order = orderByAsc ? 'asc' : 'desc'));
      setOrderBy(orderBy);
      setOrderByAsc(!orderByAsc);
    }
  };

  useEffect(() => {
    if (onOrderChange)
      onOrderChange({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        order_by: orderBy,
      });
  }, [page, rowsPerPage, orderBy, orderByAsc]);

  return (
    <Paper className={root}>
      <TableContainer className={container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  onClick={() => {
                    onSortClick(header.orderBy);
                  }}
                  className={cell}
                  style={header.style}
                  key={header.id}
                  align={header.align}
                >
                  {typeof header.label === 'function'
                    ? header.label({ data: data.items || [] })
                    : header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.items &&
              data.items.map((row) => {
                return (
                  <TableRow
                    style={{ cursor: onRowClick ? 'pointer' : 'default' }}
                    onClick={() => onRowClick(row)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    {headers.map((header) => {
                      const value = row[header.id];
                      return (
                        <TableCell
                          className={cell}
                          style={{ wordBreak: 'break-word', ...header.style }}
                          key={header.id}
                          align={header.align}
                        >
                          {header.format
                            ? header.format({ value, row })
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={'Количество строк'}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} из ${count !== -1 ? count : `более ${to}`}`
        }
        rowsPerPageOptions={[1, 10, 50, 100]}
        component="div"
        count={data.hits ? data.hits : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Loader isLoading={isLoading} />
    </Paper>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
  },
  container: {},
  cell: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
});
