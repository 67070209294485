import {Tooltip} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'


const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fff',
      color: '#828282',
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.161)',
      fontSize: '1rem',
      borderRadius: '1rem',
      textAlign: 'center',
      minWidth: 'max-content'
    },
  }))(Tooltip);




  export default CustomTooltip 