import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import ExportChampionship from '../utils/champExport';

export default function ExportDialog(props) {
  const { data, open, onClose, stages, championshipID } = props;
  const { root, input, gridBtn } = useStyles();

  const [stagesList, setStagesList] = useState([]);

  const customParams = [
    ['rating_stages', 'Рейтинг за этап'],
    ['rating_diff', 'Расхождение оценок внутреннего и внешнего эксперта'],
    ['rating_region', 'Рейтинг по регионам'],
    ['rating_manager', 'Рейтинг по руководителям'],
    ['rating_stages_grades', 'Оценки за этап'],
    ['rating_position', 'Рейтинг по местам'],
    ['rating_dynamic', 'Динамика']
  ];

  const [customParamsList, setCustomParamsList] = useState(customParams.map((param) => {
    return {
      id: param[0],
      name: param[1],
      checked: true
    }
  }));


  useEffect(() => {
    console.log('champStages are ', stages);

    if (!Array.isArray(stages?.items)) return;

    const newStagesListData = stages?.items?.map((stage) => {
      return {
        id: stage.id,
        name: stage.title,
        checked: true
      }
    });

    setStagesList(newStagesListData);

  }, [stages]);

  const handleStageClick = (index) => {
    const newStagesList = [...stagesList];
    newStagesList[index].checked = !newStagesList[index].checked;
    setStagesList(newStagesList);
  };

  const handleCustomParamClick = (index) => {
    const newCustomParamsList = [...customParamsList];
    newCustomParamsList[index].checked = !newCustomParamsList[index].checked;
    setCustomParamsList(newCustomParamsList);
  };

  const handleExportClick = () => {
    const stagesToAdd = stagesList.filter((stage) => stage.checked).map((stage) => stage.id);
    const paramsToAdd = {};
    for (const param of customParamsList) {
      paramsToAdd[param.id] = param.checked;
    }
    ExportChampionship(championshipID, stagesToAdd, paramsToAdd);
  }

  return (<div>
    <Dialog
      open={open}
      fullWidth
      maxWidth={'md'}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Экспорт чемпионата"}</DialogTitle>
      <DialogContent>
        <Grid className={root} container direction='column' spacing='1'>
          <Grid item xs={12} spacing='5' direction='column'>
            <Typography variant="h6">
              Этапы
            </Typography>
            <Grid direction='column' style={{ display: 'flex' }} >
              {
                stagesList.map((stage, index) => {
                  const onChange = () => {
                    handleStageClick(index);
                  }

                  return (
                    <FormControlLabel
                      control={<Checkbox checked={stage.checked} color='primary' onChange={onChange} />}
                      label={stage.name}
                    />
                  )
                })
              }
            </Grid>
          </Grid>

          <Grid item xs={12} spacing='5' direction='column'>
            <Typography variant="h6">
              Рейтинги, оценки
            </Typography>
            <Grid direction='column' style={{ display: 'flex' }} >
              {
                customParamsList.map((param, index) => {
                  const onChange = () => {
                    handleCustomParamClick(index);
                  }

                  return (
                    <FormControlLabel
                      control={<Checkbox checked={param.checked} color='primary' onChange={onChange} />}
                      label={param.name}
                    />
                  )
                })
              }
            </Grid>
          </Grid>
          <Grid container alignItems='center' justifyContent='center' className={gridBtn}>
            <Button color='primary' variant='contained' size='medium' onClick={handleExportClick}>
              Сохранить .xlsx
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  </div>);
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {

  },
  input: {
  },
  gridBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 16
  }
}));