import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { enableMapSet } from 'immer';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from './rootReducer';

enableMapSet();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;
