export const checkStrNumber = (
  value,
  {
    positive = false,
    length = -Infinity,
    floating = false,
    withZero = false,
  } = {
    positive: false,
    length: -Infinity,
    floating: false,
    withZero: false,
  },
) => {
  const regex = new RegExp(
    `^${withZero ? '0?' : ''}[0-9${positive ? '' : '-'}${
      floating ? '.' : ''
    }]*$`,
  );
  return !(!value.match(regex) || value.length > length);
};
