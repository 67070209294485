import React from 'react';

import { VariableWidthInput } from '../../../../../components';
import { checkStrNumber } from '../../../../../utils/checkStrNumber';
import { setCompetenceGrade } from '../../reducer';
import { useStyles } from './styles';

const CompetenceGradesBlock = ({ maxGrade, minGrade, competenceDispatch }) => {
  const styles = useStyles();

  const handleMinGradeChange = (event) => {
    const value = event.target.value;
    if (checkStrNumber(value, { length: 2, positive: true }))
      competenceDispatch(setCompetenceGrade({ minGrade: Number(value) }));
  };

  const handleMaxGradeChange = (event) => {
    const value = event.target.value;
    if (checkStrNumber(value, { length: 2, positive: true }))
      competenceDispatch(setCompetenceGrade({ maxGrade: Number(value) }));
  };

  return (
    <div className={styles.competenceGradesContainer}>
      <div className={styles.gradeLabel}>Min</div>
      <VariableWidthInput
        value={minGrade}
        onChange={handleMinGradeChange}
        className={styles.gradeInput}
      />
      <div className={styles.horizontalLine} />
      <VariableWidthInput
        value={maxGrade}
        onChange={handleMaxGradeChange}
        className={styles.gradeInput}
      />
      <div className={styles.gradeLabel}>Max</div>
    </div>
  );
};

export default CompetenceGradesBlock;
