import React, {useState, useEffect, useRef} from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import {StepperDialog, TripleSlider} from '.'

export default function EditUserDialog(props) {
    const {data, params, title, open, onClose, rightElement, submitButtonText, isLoading, onComplete} = props;

    const [completed, setCompleted] = useState({});
    const [allCompleted, setAllCompleted] = useState(false);

    const initialData = {is_player: false}
    const [newData, setNewData] = useState(initialData);

    const onEmailChange = (event) => {
        setNewData({...newData, email: event.target.value.trim()})
    }

    const onNameChange = (event) => {
        setNewData({...newData, first_name: event.target.value})
    }
    
    const onLastNameChange = (event) => {
        setNewData({...newData, last_name: event.target.value})
    }
    
    const onMiddleNameChange = (event) => {
        setNewData({...newData, middle_name: event.target.value})
    }

    const onJobChange = (event) => {
        setNewData({...newData, post: event.target.value})
    }
    
    const onBossChange = (event) => {
        setNewData({...newData, chief: event.target.value})
    }

    const onTeamChange = (event) => {
        setNewData({...newData, team: event.target.value})
    }

    const onIsPlayerChange = (value) => {
        setNewData({...newData, is_player: value})
    }

    const validateStep1 = () => {
        return newData.email && newData.email.length > 0
    }

    useEffect(() => {  
        if(newData){  
            let completed = {}

            completed[0] = validateStep1();
            setAllCompleted(validateStep1());
 
            setCompleted(completed)
        }
  
    }, [newData])

    useEffect(()=> {
        setNewData(data?data:initialData)
    },[open])

    return (
        <StepperDialog title={title} data={[
                {
                    title: 'Введите данные аккаунта',
                    content: <StepInput {...newData} disabled={params?params.disabled:{}} 
                        onEmailChange={onEmailChange} 
                        onNameChange={onNameChange} 
                        onLastNameChange={onLastNameChange} 
                        onMiddleNameChange={onMiddleNameChange}
                        onJobChange={onJobChange} 
                        onBossChange={onBossChange} 
                        onTeamChange={onTeamChange}
                        onIsPlayerChange={onIsPlayerChange}
                    />
                }
            ]} 
            completed={completed}
            allCompleted={allCompleted}
            open={open} 
            onClose={onClose}
            submitButtonText={submitButtonText}
            rightElement={rightElement}
            onComplete={() => {onComplete(newData)}}
            isLoading={isLoading}
        />
    );
}
  
const StepInput = (props) => {
    const {disabled, email, first_name, last_name, middle_name, onEmailChange, onNameChange, onLastNameChange, onMiddleNameChange,
        post, chief, team, onJobChange, onBossChange, onTeamChange, is_player, onIsPlayerChange} = props;
    const {root, input} = useStyles();

    return (
            <Grid className={root} container direction='column' spacing='1'>
                <Grid item xs={12}>
                    <TextField disabled={disabled.email} required value={email} onChange={onEmailChange} className={input} id="standard-basic" label="Введите эл. почту" />
                </Grid>
                <Grid container item xs={12} spacing='1'>
                    <Grid item xs={12} sm>
                        <TextField value={last_name} onChange={onLastNameChange} className={input} id="standard-basic" label="Введите фамилию" />
                    </Grid>
                    <Grid item xs={12} sm>
                        <TextField value={first_name} onChange={onNameChange} className={input} id="standard-basic" label="Введите имя" />
                    </Grid>
                    <Grid item xs={12} sm>
                        <TextField value={middle_name} onChange={onMiddleNameChange} className={input} id="standard-basic" label="Введите отчество" />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField value={post} onChange={onJobChange} className={input} id="standard-basic" label="Введите должность" />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={chief} onChange={onBossChange} className={input} id="standard-basic" label="Введите ФИО руководителя" />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={team} onChange={onTeamChange} className={input} id="standard-basic" label="Введите название Команды" />
                </Grid>
                <Grid item xs={12}>
                    <TripleSlider 
                            title={'Играет роль "Участник" в данном чемпионате:'}
                            onChange={onIsPlayerChange}
                            value={is_player}
                            data = {[
                                {
                                    value: false,
                                    label: 'Нет',
                                },
                                {
                                    value: true,
                                    label: 'Да',
                                },
                            ]}
                        />
                </Grid>
            </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '80%',
      maxWidth: 600
    },
    input: {
        width: '100%',
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight: {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    },
    title: {
        flex: '1 1 100%',
    },
}));
