import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import SymbolCircle from '../SymbolCircle';

const GradesPanel = ({ battleGradesByCriterion, openGradesViewOnlyModal }) => {
  const styles = useStyles();
    
    return (
      <>
        <h2 style={{ marginTop: 12 }}>Оценки</h2>
        <div className={styles.ratesContainer}>
          <div className={styles.ratesGroup}>
            {battleGradesByCriterion.map(({ index, criterion_name, average_grade }) => (
              <div key={index} style={{ position: 'relative' }}>
                <SymbolCircle
                  size={40}
                  selected={false}
                  symbol={average_grade}
                  className={styles.circleHover}
                />
                <div className={styles.gradeHoverBlock}>{criterion_name}</div>
              </div>
            ))}
          </div>
          <Button
            onClick={openGradesViewOnlyModal}
            className={styles.detailsButton}
          >
            УЗНАТЬ ПОДРОБНЕЕ
          </Button>
        </div>
      </>
    );
  }
  

  const useStyles = makeStyles ({

    ratesContainer: {
      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)',
      width: '100%',
      borderRadius: 16,
      padding: "18px 24px 18px 24px",
      marginBottom: 18,
      display: "flex",
      justifyContent: "space-between"
    },
    ratesGroup: {
      display: "flex",
      gap: "12px",
      alignItems: "center"
    },
    gradeHoverBlock: {
      display: "none",
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
      top: 40,
      position: "absolute",
      padding: "10px",
      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
      color: "#828282",
      width: 200,
    },
    circleHover: {
      '&:hover + $gradeHoverBlock': {
        display: 'inline',
      },
    },
    detailsButton: {
      backgroundColor: '#000',
      color: '#fff',
      height: 36,
      borderRadius: 8,
      fontSize: 10, 
      fontWeight: 700,
      padding: "20px 30px 20px 30px",
      marginLeft: 12,
      '&:hover': {
        backgroundColor: '#000'
      }
    },
  });

  export default GradesPanel;