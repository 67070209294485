import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default function PdfViewerPanel(props){
  const {file} = props
  const {container} = useStyles();
  const [base64, setBase64] = useState();

  function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
    
    console.log(url.protocol === "http:" || url.protocol === "https:")
    return url.protocol === "http:" || url.protocol === "https:";
  }
  
  useEffect(() => {
    if(isValidHttpUrl(file)){
      var xhr = new XMLHttpRequest();
      xhr.open("GET", file, true); 
      xhr.responseType = "blob";
      xhr.onload = function (e) {
          if (this.status === 200) {
            console.log(this.response);
            const reader = new FileReader();
            reader.onloadend = () => {
              setBase64(reader.result)   
              console.log(reader.result);
            };
            reader.readAsDataURL(this.response);
          }
      };
      xhr.send();
    }
    else{
      setBase64(file)  
    }
  }, [file])

  return (
        <div className={container}>
            <iframe width={'100%'} height={'100%'} src={base64}></iframe> 
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex', 
      flexDirection: 'row', 
      marginBottom: 30,
      height: 400,
      width: window.screen.width*0.7,
    }
}));
