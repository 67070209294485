import React, {useRef, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import TextField from '@material-ui/core/TextField';

import {StepperDialog} from '.'
import {getFile} from '../network/fetchApi'

export default function NewCompanyDialog(props) {
    const {data, title, open, onClose, rightElement, submitButtonText, isLoading, onComplete} = props;
    const {root} = useStyles();
    const fileInputRef = useRef();

    const [allCompleted, setAllCompleted] = useState(false);
    const [completed, setCompleted] = useState({});

    const [newData, setNewData] = useState({});

    const onImageFileChange  = (e) => {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        setNewData({...newData, logo_data: reader.result})
      };
      reader.onerror = function (error) {
          console.log('Error: ', error);
      };
    }

    const onTitleChange = (event) => {
      setNewData({...newData, name: event.target.value})
    }

    const validateStep1 = () => {
      return newData.logo_data != null || newData.icon_link != null
    }

    const validateStep2 = () => {
        return newData.name && newData.name.length > 0
    }

    useEffect(() => {
        if(newData){  
          let completed = {}

          completed[0] = validateStep1();
          completed[1] = validateStep2();
          setAllCompleted(validateStep1() && validateStep2());

          setCompleted(completed)
        }

    }, [newData])

    useEffect(()=> {
        setNewData(data?data:{})
    },[open])

    return (
      <StepperDialog title={title} data={[
          {
            title: 'Загрузите логотип компании',
            content: <Step1 {...newData} fileInputRef={fileInputRef} onChange={onImageFileChange}/>
          },
          {
            title: 'Введите название компании',
            content: <Step2 {...newData} onTitleChange={onTitleChange}/>
          }
        ]} 
        completed={completed}
        allCompleted={allCompleted}
        open={open} 
        onClose={onClose}
        onComplete={() => {
          onComplete({...newData, logo_data: newData.logo_data?newData.logo_data.split(',')[1]:newData.logo_data})
        }}
        rightElement={rightElement}
        submitButtonText={submitButtonText}
        isLoading={isLoading}
      />
    );
}
  
const Step1 = (props) => {
    const {icon_link, logo_data, fileInputRef, onChange} = props

      return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={logo_data?logo_data:icon_link?getFile(icon_link):null} style={{width: 'auto', height: 'auto', maxHeight: 300, maxWidth: '100%', marginBottom: 20}} />
            <input
              accept="image/*"
              ref={fileInputRef}
              type="file"
              hidden
              onChange={onChange}
            />
            <label htmlFor="contained-button-file">
                <Button onClick={() => fileInputRef.current.click()} variant="contained" color="primary" component="span" startIcon={<GetAppIcon/>}>
                    Загрузить логотип
                </Button>
            </label>
        </div>
      )
}

const Step2 = (props) => {
  const {name, onTitleChange} = props;

  return (
    <TextField 
      multiline 
      value={name} 
      onChange={onTitleChange} 
      style={{width: '80%', maxWidth: 500}} 
      id="standard-basic" 
      label="Введите название" 
      variant="outlined"
    />
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
      
    }
}));
