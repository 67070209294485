import { combineReducers } from '@reduxjs/toolkit';

import admin from './slices/admin.slice';
import stage from './slices/stage.slice';
import competence from './slices/competence.slice';

export const rootReducer = combineReducers({
  admin,
  stage,
  competence,
});
