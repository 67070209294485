import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  competenceTabContainer: {
    padding: '16px 0',
  },
  competenceGradesAndRolesWrapper: {
    display: "flex",
    flexDirection: 'column',

    '@media (max-width: 959px)': {
      alignItems: 'center'
    }
  },
  competenciesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',

    '@media (max-width: 959px)': {
      alignItems: 'center'
    }
  },
  competenceHeaderWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  competenceHeader: {
    fontFamily: 'Helvetica Neue, Roboto, sans-serif',
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '30px',
  },
  competenceContainer: {
    borderRadius: 6,
    boxShadow: '0px 0px 5px 0px grey',
    padding: '9.5px 11px',
    width: '100%',
    boxSizing: 'border-box',
    margin: '24px 0',
  },
  warning: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#f01b11',
    marginTop: '8px',
  },
  totalWeight: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: '8px',
  },
});
