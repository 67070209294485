import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { TextAreaAutosize } from '../../../../../components';
import { useStyles } from './styles';
import GradesList from './GradesList';
import TooltipPercentInput from '../../TooltipPercentInput';

const CriterionCard = ({
  criterion,
  isEditing,
  editCriterion,
  stopEditingCriterion,
  localCriterionId,
  updateCriterion,
  removeCriterion,
}) => {
  const [grades, setGrades] = useState(criterion.grades || []);

  const styles = useStyles();

  const startEditing = () => editCriterion(localCriterionId);

  const handleCancelIconClick = () => {
    if (isEditing) {
      setGrades(criterion.grades);
      stopEditingCriterion(localCriterionId);
      return;
    }
    if (!isEditing) removeCriterion(localCriterionId);
  };

  const handleCheckIconClick = () => {
    updateCriterion(['criteria', localCriterionId, 'grades'], grades);
    stopEditingCriterion(localCriterionId);
  };

  const handleWeightChange = useCallback(
    (value) => {
      updateCriterion(['criteria', localCriterionId, 'weight'], value);
    },
    [localCriterionId, updateCriterion],
  );

  const handleCriterionNameChange = useCallback(
    (event) => {
      updateCriterion(
        ['criteria', localCriterionId, 'name'],
        event.target.value,
      );
    },
    [localCriterionId, updateCriterion],
  );

  const handleGradesChange = useCallback((data) => setGrades(data), []);

  useEffect(() => {
    if (criterion) setGrades(criterion.grades);
  }, [criterion]);

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.criterionCardContainer}
    >
      <Grid item xs={6} className={styles.criterionNameInputWrapper}>
        <TextAreaAutosize
          label="Введите название критерия"
          onChange={handleCriterionNameChange}
          value={criterion.name}
        />
      </Grid>
      <Grid item container alignItems="center" xs={6}>
        <TooltipPercentInput
          tooltipText="Вес критерия"
          weight={criterion.weight}
          label="Вес критерия"
          inputProps={{ style: { paddingLeft: 6 }}}
          onChange={handleWeightChange}
          style={{ alignSelf: 'baseline' }}
        />
        <Grid item container direction="column" justify="center" xs={3}>
          <IconButton
            className={styles.deleteCriteriaIcon}
            onClick={handleCancelIconClick}
          >
            <CancelRoundedIcon fontSize="large" />
          </IconButton>
          {isEditing ? (
            <IconButton
              className={styles.confirmEditCriteriaIcon}
              onClick={handleCheckIconClick}
            >
              <CheckCircleRoundedIcon fontSize="large" />
            </IconButton>
          ) : (
            <IconButton
              className={styles.editCriteriaIcon}
              onClick={startEditing}
            >
              <EditRoundedIcon fontSize="large" />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {isEditing && (
        <GradesList grades={grades} onGradeDataChange={handleGradesChange} />
      )}
    </Grid>
  );
};

export default CriterionCard;
