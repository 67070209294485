import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns';
import {subDays, startOfDay, endOfDay} from 'date-fns'
import { getEmailsCSVByFilter } from '../network/fetchApi';
import {APIHOST} from '../network/url'

export default function EmailStatsDialog(props) {
    const { open, onClose } = props;
    const [dateStart, setDateStart] = useState(subDays(new Date(), 1))
    const [dateEnd, setDateEnd] = useState(new Date())


    const handleDownload = () => {
        const start = startOfDay(dateStart).toISOString()
        const end = endOfDay(dateEnd).toISOString()


        const offset = new Date().getTimezoneOffset()


        window.open(`${APIHOST}/admin/emailsfilter?start=${start}&end=${end}&offset=${offset}`)

    }


    return (
        <Dialog
            open={open}
            fullWidth maxWidth={'sm'}
            TransitionComponent={Transition}
            onClose={onClose}
            size
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Статистика отправки писем</DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">{"Выберите период для выгрузки"}</DialogContentText>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Начало периода"
                            value={dateStart}
                            onChange={(date) => {
                                setDateStart(date)
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            maxDate={subDays(new Date(), 1)}
                            style={{marginRight: "40px"}}
                        />
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Конец периода"
                            value={dateEnd}
                            onChange={(date) => {
                                setDateEnd(date)
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}

                            maxDate={new Date()}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} >
                    Закрыть
                </Button>
                <Button onClick={handleDownload} color="primary">
                    Скачать
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
