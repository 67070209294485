import React from 'react';

import CompetenceGradesBlock from './CompetenceGradesBlock';
import { useStyles } from '../styles';

const CompetenceGrades = ({ minGrade, maxGrade, competenceDispatch }) => {
  const styles = useStyles();

  return (
    <div style={{ maxWidth: '293px' }}>
      <div className={styles.competenceHeader}>Шкала оценки</div>
      <div className={styles.competenceContainer}>
        <CompetenceGradesBlock
          minGrade={minGrade}
          maxGrade={maxGrade}
          competenceDispatch={competenceDispatch}
        />
      </div>
    </div>
  );
};

export default CompetenceGrades;
