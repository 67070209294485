import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { Tooltip } from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import TimerIcon from '@material-ui/icons/Timer';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import GetAppIcon from '@material-ui/icons/GetApp';

import useQuery from '../../utils/useQuery';

import {
  BreadCrumbs,
  StageProfile,
  StageStats,
  FabDial,
  DeleteButton,
  NewStageDialog,
  BattleDialog,
  NewBattleDialog,
  ConfirmActionDialog,
  InfoDialog,
  NewCaseDialog,
  NewRoleDialog,
  BattlesRightsDialog,
} from '../../components';
import StageScreenTabs from './StageScreenTabs';

import {
  autoInviteStageBattles,
  inviteStageBattles,
  updateBattlesRights,
  getBattlesCSV,
  getCommentsForStageCSV,
  updateBattlesRightsByIds,
  getEmailsCSV,
  resendEmails,
} from '../../network/fetchApi';
import EmailResultDialog from '../../components/EmailResultDialog';
import { useStyles } from './styles';
import { mapStageResponseToStats, mapStageRoleToColor } from './const';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeAutoSendInites,
  loadStageData,
  loadStageBattles as loadStageBattlesAction,
  loadStageCases as loadStageCasesAction,
  loadStageRoles as loadStageRolesAction,
  addStageCase,
  addStageRole,
  addStageBattle,
  editStageCase,
  editStageRole,
  editStageBattle,
  editStage,
  deleteStageCase,
  deleteStageRole,
  deleteStageBattle,
  deleteStage,
  generateStageBattles,
} from '../../redux/slices/stage.slice';
import {
  autoSendInitesSelector,
  battleActionLoadingSelector,
  caseActionLoadingSelector,
  roleActionLoadingSelector,
  stageBattlesHeadersSelector,
  stageDataLoadingSelector,
  stageDataSelector,
  stageEditingSelector,
  stageRolesSelector,
} from '../../redux/selectors/stage.selecrtors';
import { useSwitch } from '../../utils/hooks/useSwitch';
import { useToggle } from '../../utils/hooks/useToggle';

export default function StageScreen() {
  const {
    root,
    header,
    fab1,
    addDial,
    settingsDial,
    downloadIconContainer,
    downloadSubIcon,
    battlesRights,
  } = useStyles();

  const query = useQuery();
  const idTemp = query.get('id');
  const history = useHistory();

  const dispatch = useDispatch();

  const [
    openBattlesRightsDialog,
    onBattlesRightsOpen,
    onBattlesRightsDialogClose,
  ] = useSwitch();
  const [openInfoDialog, onInfoDialogOpenSwitch, onInfoDialogClose] =
    useSwitch();
  const [openBattleDialog, onBattleDialogOpen, onBattleDialogClose] =
    useSwitch();
  const [
    openConfirmStageDialog,
    onConfirmStageDialogOpen,
    onConfirmStageDialogClose,
  ] = useSwitch();
  const [
    openConfirmBattleDialog,
    onConfirmBattleDialogOpen,
    onConfirmBattleDialogClose,
  ] = useSwitch();
  const [
    openConfirmInvitesDialog,
    onConfirmInvitesDialogOpen,
    onConfirmInvitesDialogClose,
  ] = useSwitch();
  const [
    openConfirmStageCaseDialog,
    onConfirmStageCaseDialogOpen,
    onConfirmStageCaseDialogClose,
  ] = useSwitch();
  const [
    openConfirmStageRoleDialog,
    onConfirmStageRoleDialogOpen,
    onConfirmStageRoleDialogClose,
  ] = useSwitch();
  const [
    openNewStageCaseDialog,
    onNewStageCaseDialogOpen,
    onNewStageCaseDialogClose,
  ] = useSwitch();
  const [
    openNewStageRoleDialog,
    onNewStageRoleDialogOpen,
    onNewStageRoleDialogClose,
  ] = useSwitch();
  const [
    openEditStageCaseDialog,
    onEditStageCaseDialogOpen,
    onEditStageCaseDialogClose,
  ] = useSwitch();
  const [
    openEditStageRoleDialog,
    onEditStageRoleDialogOpen,
    onEditStageRoleDialogClose,
  ] = useSwitch();
  const [
    openMultipleBattlesRightsDialog,
    onMultipleBattlesRightsOpen,
    onMultipleBattlesRightsDialogClose,
  ] = useSwitch();
  const [openEditStageDialog, onEditStageDialogOpen, onEditStageDialogClose] =
    useSwitch();
  const [openNewBattleDialog, onNewBattleDialogOpen, onNewBattleDialogClose] =
    useSwitch();

  const [openSettingsDial, onToggleSettingsDial] = useToggle();
  const [openAddDial, onToggleAddDial] = useToggle();

  const [infoDialogText, setInfoDialogText] = useState('');
  const [stats, setStats] = useState();
  const [stageBattleData, setStageBattleData] = useState({});
  const [emailResult, setEmailResult] = useState();
  const [stageCaseData, setStageCaseData] = useState({});
  const [stageRoleData, setStageRoleData] = useState({});
  const [battlesSelected, setBattlesSelected] = useState([]);

  const stageData = useSelector(stageDataSelector);
  const isLoadingStageData = useSelector(stageDataLoadingSelector);
  const autoSendInites = useSelector(autoSendInitesSelector);
  const stageBattlesHeaders = useSelector(stageBattlesHeadersSelector);

  const stageRoles = useSelector(stageRolesSelector);
  const isStageCaseLoading = useSelector(caseActionLoadingSelector);
  const isStageRoleLoading = useSelector(roleActionLoadingSelector);
  const isEditBattleLoading = useSelector(battleActionLoadingSelector);
  const isEditStageLoading = useSelector(stageEditingSelector);

  const [isBattleEdited, setIsBattleEdited] = useState(false);

  React.useEffect(() => {
    console.log('stageData', stageData);
  }, [stageData]);

  // setting up stats every time roles or stage changes
  useEffect(() => {
    setStats(
      mapStageResponseToStats(
        stageData,
        mapStageRoleToColor(stageRoles?.items ?? []),
        stageRoles?.items,
      ),
    );
  }, [stageData, stageRoles]);

  const OnEmailResultClosed = () => {
    setEmailResult(undefined);
  };

  const loadStageByID = useCallback(
    () => dispatch(loadStageData({ id: idTemp })),
    [dispatch, idTemp],
  );

  const toggleBattleSelection = (battleId) => {
    const selected = battlesSelected.includes(battleId);
    let newBattles;
    if (selected) {
      newBattles = battlesSelected.filter((id) => id !== battleId);
    } else {
      newBattles = [...battlesSelected, battleId];
    }

    setBattlesSelected(newBattles);
  };

  const loadStageBattles = useCallback(
    (params = { filters: [] }) => {
      params.filters = [
        ...params.filters,
        {
          field: 'stage_id',
          equal: idTemp,
        },
      ];
      if (params.order_by == null) {
        params.order_by = [
          {
            field: 'user_last_name',
            order: 'asc',
          },
          {
            field: 'user_first_name',
            order: 'asc',
          },
          {
            field: 'user_middle_name',
            order: 'asc',
          },
        ];
      }
      if (params.limit == null) {
        params.limit = 100;
      }
      if (params.offset == null) {
        params.offset = 0;
      }
      dispatch(loadStageBattlesAction({ params }));
    },
    [dispatch, idTemp],
  );

  const loadStageCases = useCallback(
    (params = { filters: [] }) => {
      params.filters = [
        ...params.filters,
        {
          field: 'stage_id',
          equal: idTemp,
        },
      ];
      if (params.order_by == null) {
        params.order_by = [
          {
            field: 'number',
            order: 'asc',
          },
        ];
      }
      dispatch(loadStageCasesAction({ params }));
    },
    [dispatch, idTemp],
  );

  const loadStageRoles = useCallback(() => {
    dispatch(loadStageRolesAction({ id: idTemp }));
  }, [dispatch, idTemp]);

  const onFileDownload = () => getBattlesCSV(stageData);
  const onEmailsCSVDownload = (campaignId) => getEmailsCSV(campaignId);
  const onGetAllComments = () => getCommentsForStageCSV(stageData);

  const onCompleteNewStageCase = (data) =>
    dispatch(
      addStageCase({
        data: { ...data, stage_id: stageData.id },
        success: onNewStageCaseDialogClose,
      }),
    );

  const onCompleteNewStageRole = (stageRole) =>
    dispatch(
      addStageRole({
        data: {
          id: stageData.id,
          data: [stageRole],
        },
        success: () => {
          loadStageRoles();
          onNewStageRoleDialogClose();
        },
      }),
    );

  const onCompleteNewBattle = (data) =>
    dispatch(
      addStageBattle({
        success: () => {
          setIsBattleEdited(true);
          loadStageBattles();
          loadStageByID();
          onNewBattleDialogClose();
        },
        data,
      }),
    );

  const onCompleteEditStageCase = (data) =>
    dispatch(editStageCase({ data, success: onEditStageCaseDialogClose }));

  const onCompleteEditStageRole = (stageRole) =>
    dispatch(
      editStageRole({
        data: {
          id: stageData.id,
          data: [
            {
              id: stageRole.id,
              title: stageRole.title,
              rights: {
                comments: stageRole.rights.comments,
                upload: stageRole.rights.upload,
                watch: stageRole.rights.watch,
                write: stageRole.rights.write,
                give_grades: stageRole.rights.give_grades,
                see_grades: stageRole.rights.see_grades,
              },
              graph_color: stageRole.graph_color,
            },
          ],
        },
        success: () => {
          loadStageRoles();
          onEditStageRoleDialogClose();
        },
      }),
    );

  const onRepeat = (campaignId) => {
    setEmailResult(undefined);
    onInfoDialogOpen('Приглашения на упражнение отправляются!');
    resendEmails(campaignId, (responseJson) => {
      if (responseJson.status === 200) {
        setEmailResult(responseJson.body);
      }
    });
  };

  const onCompleteEditStage = (data) =>
    dispatch(editStage({ data, success: onEditStageDialogClose }));

  const onCompleteEditBattle = (data) =>
    dispatch(
      editStageBattle({
        data: { id: stageBattleData.id, ...data },
        success: () => {
          setIsBattleEdited(true);
          loadStageBattles();
          loadStageByID();
          onBattleDialogClose();
        },
      }),
    );

  const onCompleteEditBattlesRights = (data) => {
    updateBattlesRights({ id: stageData.id, data }, ({ status }) => {
      if (status === 200) {
        onBattlesRightsDialogClose();
      }
    });
  };

  const onCompleteEditMultipleBattlesRights = (data) =>
    updateBattlesRightsByIds(
      { id: stageData.id, data: { rights: data, battle_ids: battlesSelected } },
      ({ status }) => {
        if (status === 200) {
          onMultipleBattlesRightsDialogClose();
        }
      },
    );

  const onDeleteStageCase = () => {
    onConfirmStageCaseDialogClose();
    dispatch(
      deleteStageCase({
        data: stageCaseData,
        success: () => {
          loadStageCases();
          onEditStageCaseDialogClose();
        },
      }),
    );
  };

  const onDeleteStageRole = () => {
    onConfirmStageRoleDialogClose();
    dispatch(
      deleteStageRole({
        data: stageRoleData,
        success: () => {
          loadStageRoles();
          onEditStageRoleDialogClose();
        },
      }),
    );
  };

  const onDeleteStage = () =>
    dispatch(
      deleteStage({
        data: stageData,
        success: () => {
          history.push(`/company/champ?id=${stageData.championship_id}`);
        },
      }),
    );

  const onDeleteBattle = () => {
    onConfirmBattleDialogClose();
    dispatch(
      deleteStageBattle({
        data: stageBattleData,
        success: () => {
          setIsBattleEdited(true);
          loadStageBattles();
          loadStageByID();
          onBattleDialogClose();
        },
      }),
    );
  };

  const onGenerateBattles = () =>
    dispatch(
      generateStageBattles({
        data: stageData,
        success: () => {
          setIsBattleEdited(true);
          loadStageBattles();
          loadStageByID();
          onNewBattleDialogClose();
        },
      }),
    );

  const onSendInvitations = () => {
    onConfirmInvitesDialogClose();
    onInfoDialogOpen('Приглашения на упражнение отправляются!');
    inviteStageBattles(stageData, (responseJson) => {
      onConfirmInvitesDialogClose();
      if (responseJson.status === 200) {
        setEmailResult(responseJson.body);
        // onInfoDialogOpen("Приглашения на упражнение успешно отправлены!")
      }
    });
  };

  const handleSwitchAutiSendInvitations = () => {
    autoInviteStageBattles(
      {
        id: stageData.id,
        data: {
          active: !autoSendInites,
        },
      },
      (responseJson) => {
        if (responseJson.status === 200) {
          onInfoDialogOpen('Статус автоматической отправки писем изменен');
          dispatch(changeAutoSendInites(!autoSendInites));
        }
      },
    );
  };

  const onInfoDialogOpen = (text) => {
    setInfoDialogText(text);
    onInfoDialogOpenSwitch();
  };

  useEffect(() => {
    loadStageByID();
  }, [loadStageByID]);

  return (
    <div className={root}>
      <BreadCrumbs
        data={[
          { name: 'Список компаний', href: '/companies' },
          {
            name: 'Компания',
            href: stageData.company_id
              ? `/company?id=${stageData.company_id}`
              : null,
          },
          {
            name: 'Чемпионат',
            href: stageData.championship_id
              ? `/company/champ?id=${stageData.championship_id}`
              : null,
          },
          { name: 'Этап' },
        ]}
      />
      <Typography variant="h5" className={header}>
        О этапе
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StageProfile data={stageData} isLoading={isLoadingStageData} />
        </Grid>
        <Grid item xs={12} md={8}>
          <StageStats
            data={stageData}
            isLoading={isLoadingStageData}
            stats={stats}
            colors={mapStageRoleToColor(stageRoles?.items ?? [])}
          />
        </Grid>
        <Grid container item xs={12} direction={'column'}>
          <StageScreenTabs
            stageBattlesHeaders={stageBattlesHeaders}
            stageRoles={stageRoles}
            battlesSelected={battlesSelected}
            toggleBattleSelection={toggleBattleSelection}
            onBattleDialogOpen={onBattleDialogOpen}
            setStageBattleData={setStageBattleData}
            setStageCaseData={setStageCaseData}
            onEditStageCaseDialogOpen={onEditStageCaseDialogOpen}
            setStageRoleData={setStageRoleData}
            onEditStageRoleDialogOpen={onEditStageRoleDialogOpen}
            loadStageBattles={loadStageBattles}
            loadStageCases={loadStageCases}
            loadStageRoles={loadStageRoles}
            stageId={idTemp}
            isBattleEdited={isBattleEdited}
            setIsBattleEdited={setIsBattleEdited}
          />
        </Grid>
      </Grid>
      <FabDial
        icon={<SettingsIcon />}
        className={settingsDial}
        direction="right"
        actions={[
          {
            icon: <EmailIcon />,
            name: 'Отправить всем приглашения на упражнение',
            onClick: onConfirmInvitesDialogOpen,
          },
          {
            icon: !autoSendInites ? <TimerIcon /> : <TimerOffIcon />,
            name: !autoSendInites
              ? 'Включить автоматическую отправку писем'
              : 'Выключить автоматическую отправку писем',
            onClick: handleSwitchAutiSendInvitations,
          },
          {
            icon: <VisibilityIcon />,
            name: 'Изменить права доступа',
            onClick: onBattlesRightsOpen,
          },
          {
            icon: <GetAppIcon />,
            name: 'Cкачать файл',
            onClick: onFileDownload,
          },
          {
            icon: (
              <div className={downloadIconContainer}>
                <GetAppIcon />
                <div className={downloadSubIcon}>К</div>
              </div>
            ),
            name: 'Cкачать список всех комментариев',
            onClick: onGetAllComments,
          },
        ]}
        onClick={onToggleSettingsDial}
        open={openSettingsDial}
      />
      {battlesSelected.length > 0 && (
        <Tooltip
          title="Изменить права доступа для выделенных упражнений"
          placement="right"
        >
          <Fab
            className={battlesRights}
            color="primary"
            aria-label="change rights"
            onClick={onMultipleBattlesRightsOpen}
          >
            <VisibilityIcon />
          </Fab>
        </Tooltip>
      )}
      <Fab
        className={fab1}
        color="primary"
        aria-label="add"
        onClick={onEditStageDialogOpen}
      >
        <EditIcon />
      </Fab>
      <FabDial
        icon={<AddIcon />}
        className={addDial}
        direction="left"
        actions={[
          {
            icon: <Typography variant="h5">Р</Typography>,
            name: 'Добавить роль',
            onClick: onNewStageRoleDialogOpen,
          },
          {
            icon: <Typography variant="h5">К</Typography>,
            name: 'Добавить кейс',
            onClick: onNewStageCaseDialogOpen,
          },
          {
            icon: <Typography variant="h5">У</Typography>,
            name: 'Добавить упражнение',
            onClick: onNewBattleDialogOpen,
          },
        ]}
        onClick={onToggleAddDial}
        open={openAddDial}
      />
      <NewStageDialog
        title={'Редактировать этап'}
        submitButtonText="Сохранить"
        data={stageData}
        rightElement={<DeleteButton onClick={onConfirmStageDialogOpen} />}
        open={openEditStageDialog}
        onClose={onEditStageDialogClose}
        onComplete={onCompleteEditStage}
        isLoading={isEditStageLoading}
      />
      <BattleDialog
        headerRoles={stageBattlesHeaders}
        data={{
          stage: { id: stageData.id },
          championship: { id: stageData.championship_id },
          battle: { id: stageBattleData.id },
          activeStep: stageBattleData.activeStep,
        }}
        open={openBattleDialog}
        onClose={onBattleDialogClose}
        onDelete={onConfirmBattleDialogOpen}
        onComplete={onCompleteEditBattle}
        isLoading={isEditBattleLoading}
      />
      <NewBattleDialog
        headerRoles={stageBattlesHeaders}
        data={{
          stage: { id: stageData.id },
          championship: { id: stageData.championship_id },
        }}
        open={openNewBattleDialog}
        onClose={onNewBattleDialogClose}
        onGenerateBattles={onGenerateBattles}
        onComplete={onCompleteNewBattle}
        isLoading={isEditBattleLoading}
      />
      <NewCaseDialog
        title={'Новый кейс'}
        open={openNewStageCaseDialog}
        onClose={onNewStageCaseDialogClose}
        onComplete={onCompleteNewStageCase}
        isLoading={isStageCaseLoading}
      />
      <NewCaseDialog
        title={'Редактировать кейс'}
        submitButtonText="Сохранить"
        data={stageCaseData}
        rightElement={<DeleteButton onClick={onConfirmStageCaseDialogOpen} />}
        open={openEditStageCaseDialog}
        onClose={onEditStageCaseDialogClose}
        onComplete={onCompleteEditStageCase}
        isLoading={isStageCaseLoading}
      />
      <NewRoleDialog
        title={'Новая роль'}
        open={openNewStageRoleDialog}
        onClose={onNewStageRoleDialogClose}
        onComplete={onCompleteNewStageRole}
        isLoading={isStageRoleLoading}
      />
      <NewRoleDialog
        title={'Редактировать роль'}
        submitButtonText="Сохранить"
        data={stageRoleData}
        rightElement={<DeleteButton onClick={onConfirmStageRoleDialogOpen} />}
        open={openEditStageRoleDialog}
        onClose={onEditStageRoleDialogClose}
        onComplete={onCompleteEditStageRole}
        isLoading={isStageRoleLoading}
      />
      <BattlesRightsDialog
        open={openBattlesRightsDialog}
        onClose={onBattlesRightsDialogClose}
        onConfirm={onCompleteEditBattlesRights}
      />
      <BattlesRightsDialog
        open={openMultipleBattlesRightsDialog}
        onClose={onMultipleBattlesRightsDialogClose}
        onConfirm={onCompleteEditMultipleBattlesRights}
        title={'Изменить права доступа выбранных упражнений'}
      />
      <ConfirmActionDialog
        title="Удалить этап?"
        text="Вы уверены, что хотите удалить все данные об этапе?"
        open={openConfirmStageDialog}
        onClose={onConfirmStageDialogClose}
        onConfirm={onDeleteStage}
      />
      <ConfirmActionDialog
        title="Удалить упражнение?"
        text="Вы уверены, что хотите удалить все данные об упражнении?"
        open={openConfirmBattleDialog}
        onClose={onConfirmBattleDialogClose}
        onConfirm={onDeleteBattle}
      />
      <ConfirmActionDialog
        title="Отправить всем приглашения на упражнение?"
        text="Вы уверены, что хотите отправить всем приглашения на упражнение?"
        open={openConfirmInvitesDialog}
        onClose={onConfirmInvitesDialogClose}
        onConfirm={onSendInvitations}
      />
      <ConfirmActionDialog
        title="Удалить кейс?"
        text="Вы уверены, что хотите удалить все данные о кейсе?"
        open={openConfirmStageCaseDialog}
        onClose={onConfirmStageCaseDialogClose}
        onConfirm={onDeleteStageCase}
      />
      <ConfirmActionDialog
        title="Удалить роль?"
        text="Вы уверены, что хотите удалить все данные о роли?"
        open={openConfirmStageRoleDialog}
        onClose={onConfirmStageRoleDialogClose}
        onConfirm={onDeleteStageRole}
      />
      <InfoDialog
        text={infoDialogText}
        open={openInfoDialog}
        onClose={onInfoDialogClose}
      />
      <EmailResultDialog
        onRepeat={onRepeat}
        onCSV={onEmailsCSVDownload}
        result={emailResult}
        onClose={OnEmailResultClosed}
      />
    </div>
  );
}
