import React, { useMemo } from 'react';

import TooltipPercentInput from '../../TooltipPercentInput';
import { setRoleWeight } from '../../reducer';

import { useStyles } from './styles';

const CompetenceRolesWeight = ({
  rolesWithWeights = [],
  competenceDispatch,
}) => {
  const styles = useStyles();

  const handleRoleWeightChange = (roleId, weight) => {
    competenceDispatch(setRoleWeight(roleId, weight));
  };

  const totalWeight = useMemo(
    () =>
      Math.round(
        rolesWithWeights.reduce(
          (total, roleWithWeight) => total + roleWithWeight.weight,
          0,
        ) * 100,
      ),
    [rolesWithWeights],
  );

  return (
    <div className={styles.roleWeightContainer}>
      {rolesWithWeights?.map((roleWithWeight, index) => (
        <div className={styles.roleWeightWrapper} key={index}>
          <div className={styles.roleNameWrapper}>{roleWithWeight.role}</div>
          <TooltipPercentInput
            weight={roleWithWeight.weight}
            tooltipText={'Вес роли'}
            onChange={(value) =>
              handleRoleWeightChange(roleWithWeight.id, value)
            }
            label="Вес роли"
            style={{ maxWidth: 150 }}
          />
        </div>
      ))}
      <div
        className={styles.totalWeight}
        style={{
          color:
            totalWeight > 99.9 && totalWeight <= 100
              ? 'green'
              : totalWeight > 100
              ? '#f01b11'
              : 'inherit',
        }}
      >
        Общий вес: {totalWeight}%
      </div>
      {totalWeight > 100 && (
        <div className={styles.warning}>
          Общий вес ролей не должен превышать 100%
        </div>
      )}
    </div>
  );
};

export default CompetenceRolesWeight;
