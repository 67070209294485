import React, { useMemo } from 'react';
import { format, isValid } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import 'date-fns';

import EventIcon from '@material-ui/icons/Event';
import { Loader, PercentsInput } from '.';

export default function StageProfile(props) {
  const { data, isLoading } = props;
  const { title, weight, time_start, time_finish } = data;

  const { paper, input } = useStyles();

  const stageDateRange = useMemo(() => {
    const timeStartDate = new Date(time_start);
    const timeFinishDate = new Date(time_finish);
    if (!(isValid(timeStartDate) && isValid(timeFinishDate))) return '';
    return `${format(timeStartDate, 'dd/MM/yyyy')} - ${format(
      timeFinishDate,
      'dd/MM/yyyy',
    )}`;
  }, [time_start, time_finish]);

  return (
    <Paper elevation={2} className={paper}>
      <TextField
        id="standard-read-only-input"
        label="Название этапа"
        value={title}
        className={input}
        multiline
        fullWidth
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextFieldComponent
        label="Даты проведения этапа"
        margin="normal"
        value={stageDateRange}
        variant="outlined"
        fullWidth
      />
      <PercentsInput
        textFieldProps={{
          label: 'Вес этапа',
          margin: 'normal',
          fullWidth: true,
          variant: 'outlined',
        }}
        maxWidth="100%"
        value={weight}
      />
      <Loader isLoading={isLoading} />
    </Paper>
  );
}

const TextFieldComponent = (props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EventIcon color={'inherit'} />
          </InputAdornment>
        ),
        readOnly: true,
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    marginBottom: 8,
  },
}));
