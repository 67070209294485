import React, { useMemo } from 'react';

import { ColoredCircle } from '../../../../components';
import CompetenceRolesWeight from './CompetenceRolesWeight';
import { useStyles } from '../styles';

const CompetenceRoles = ({ rolesWithWeights = [], competenceDispatch }) => {
  const styles = useStyles();

  const rolesWeightSum = useMemo(() => {
    return rolesWithWeights.reduce((total, role) => total + role?.weight, 0);
  }, [rolesWithWeights]);

  return (
    <div style={{ maxWidth: '405px' }}>
      <div className={styles.competenceHeaderWrapper}>
        <div className={styles.competenceHeader}>Вес ролей </div>
        <ColoredCircle
          minValueColor="#FF7575"
          maxValueColor="#80D856"
          percent={rolesWeightSum}
        />
      </div>
      <div className={styles.competenceContainer}>
        <CompetenceRolesWeight
          rolesWithWeights={rolesWithWeights}
          competenceDispatch={competenceDispatch}
        />
      </div>
    </div>
  );
};

export default CompetenceRoles;
