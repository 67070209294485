import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import HistoryIcon from '@material-ui/icons/History';

import {getVideoHistory} from '../network/fetchApi'

export default function VideoHistoryDialog(props) {
    const {data, open, onClose} = props;

    const [history, setHistory] = useState([])

    const loadVideoHistory = () => {
        if(data.video){
            getVideoHistory({id: data.video.id}, (responseJson) => {
                if(responseJson.status == 200){
                    setHistory(responseJson.body.items)
                }
            })
        }
    }

    useEffect(() => {
        if(open)loadVideoHistory()
    }, [open])

    return (
        <Dialog
            open={open}
            fullWidth
            TransitionComponent={Transition}
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">История загрузки видео</DialogTitle>
            <DialogContent>
                <List>
                    {history.map((item) => 
                        <HistoryPanel link={item.link} title={item.link} date={item.created_at}/>
                    )}
                    {history.length === 0 && (
                        <span>Пусто</span>
                    )}
                </List>
            </DialogContent>
            <DialogActions>
            <Button onClick={onClose} color="primary">
                Закрыть
            </Button>
            </DialogActions>
        </Dialog>
    );
}

const HistoryPanel = (props) => {
    const {link, title, date} = props;
    const dateString = `${new Date(date).toLocaleTimeString()} ${new Date(date).toLocaleDateString()}`
    return (
        <Paper style={{marginTop: 15, marginBottom: 15}}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <HistoryIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={<span>Ссылка: <a target="_blank" href={link}>{title}</a></span>}
                    secondary={`Дата: ${dateString}`}
                />
            </ListItem>
        </Paper>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
