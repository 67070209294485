import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  competenceGradesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8.5px',
  },
  horizontalLine: {
    height: 67,
    width: 0,
    border: '1.5px solid black',
    borderRadius: 6,
  },
  gradeLabel: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
  },
  gradeInput: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
