import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/Settings';

import { getCompanyList, getFile, addCompany } from '../network/fetchApi'
import { DataTable, NewCompanyDialog, TabPanel, SearchInput, FabDial } from '../components'
import { COMPANIES } from '../constants/headers'
import EmailStatsDialog from '../components/EmailStatsDialog';

export default function CompaniesScreen() {
  const { root, fab, tabs, settingsDial } = useStyles();
  let history = useHistory();

  const [isLoadingCompanies, setIsLoadingCompanies] = useState(true);
  const [isNewCompanyLoading, setIsNewCompanyLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [openNewCompanyDialog, setOpenNewCompanyDialog] = useState(false);
  const [companiesOrder, setCompaniesOrder] = useState([])
  const [companiesFilters, setCompaniesFilters] = useState([])
  const [showEmailStats, setShowEmailStats] = useState(false)
  const [openSettingsDial, setOpenSettingsDial] = useState(false);

  const loadCompanies = (params) => {
    setIsLoadingCompanies(true)

    getCompanyList(params, (responseJson) => {
      setIsLoadingCompanies(false)
      if (responseJson.status == 200) {
        let newData = responseJson.body
        setCompanies(newData)
      }
    })
  }

  const onNewCompanyDialogOpen = () => {
    setOpenNewCompanyDialog(true);
  };

  const onNewCompanyDialogClose = () => {
    setOpenNewCompanyDialog(false);
  };

  const onCompanyClick = (data) => {
    history.push(`/company?id=${data.id}`)
  }

  const onCompleteNewCompany = (data) => {
    setIsNewCompanyLoading(true)
    addCompany(data, (responseJson) => {
      setIsNewCompanyLoading(false)
      if (responseJson.status == 200) {
        setCompanies(companies)
        setCompanies({ ...companies, items: [responseJson.body, ...companies.items] })
        onNewCompanyDialogClose()
      }
    })
  }

  const onChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const onCompaniesOrderChange = (order) => {
    setCompaniesOrder(order)
  }

  const onCompaniesFiltersChange = (filters) => {
    setCompaniesFilters(filters)
  }

  useEffect(() => {
    loadCompanies({ ...companiesOrder, filters: companiesFilters })
  }, [companiesOrder, companiesFilters])


  const showEmailStatsDialog = () => {
    setShowEmailStats(true)
  }

  const closeEmailStats = () => {
    setShowEmailStats(false)
  }

  const onToggleSettingsDial = () => {
    setOpenSettingsDial(!openSettingsDial);
};

  return (
    <div className={root}>
      <Grid container spacing={3}>
        <Grid container item xs={12} direction={'column'}>
          <Grid item xs>
            <Tabs scrollButtons="auto" variant="scrollable" className={tabs} value={tabValue} onChange={onChangeTab}>
              <Tab label="Список компаний" id='tab-0' aria-controls='tabpanel-0' />
            </Tabs>
          </Grid>
          <Grid item xs>
            <TabPanel value={tabValue} index={0}>
              <SearchInput onFiltersChange={onCompaniesFiltersChange} fields={[{ title: "По названию", value: "name" }]} />
              <DataTable initialOrder={[{ field: 'name', order: 'asc' }]} onOrderChange={onCompaniesOrderChange} isLoading={isLoadingCompanies} headers={COMPANIES} data={companies} onRowClick={onCompanyClick} />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
      <FabDial
        icon={<SettingsIcon />}
        className={settingsDial}
        direction='right'
        actions={[
          { icon: <EmailIcon />, name: 'Статистика отправки почты', onClick: showEmailStatsDialog }
        ]}
        onClick={onToggleSettingsDial} open={openSettingsDial}
      />
      <Fab className={fab} color="primary" aria-label="add" onClick={onNewCompanyDialogOpen}>
        <AddIcon />
      </Fab>
      <NewCompanyDialog
        title="Новая компания"
        open={openNewCompanyDialog}
        onClose={onNewCompanyDialogClose}
        onComplete={onCompleteNewCompany}
        isLoading={isNewCompanyLoading}
      />
      <EmailStatsDialog
      open={showEmailStats}
      onClose={closeEmailStats}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  fab: {
    zIndex: 100,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  appBar: {
    position: 'relative',
  },
  tabs: {
    marginBottom: theme.spacing(2)
  },
  settingsDial: {
    zIndex: 100,
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2)
},
}));
