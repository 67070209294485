import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import FaceIcon from '@material-ui/icons/Face';

import { Loader } from '.';
import { BATTLES } from '../constants/headers';
import { Checkbox } from '@material-ui/core';

const headersPriority = {
  'Внутренний эксперт': 0,
  'Внутренние эксперты': 0,
  'Внутренний эксперты': 0,
  'Внутренние эксперт': 0,
  'Внешний эксперт': 1,
  'Внешние эксперты': 1,
  'Внешний эксперты': 1,
  'Внешние эксперт': 1,
};

export const sortHeaders = (a, b) => {
  const aPrior = headersPriority[a.title] ?? 1000;
  const bPrior = headersPriority[b.title] ?? 1000;

  if (aPrior > bPrior) return 1;
  if (aPrior < bPrior) return -1;
  return 0;
};

const mapTableHeaderToIconColor = (header, colors) => {
  return colors[header] ?? 'transparent';
};

export default function BattlesTable(props) {
  const {
    initialOrder,
    data,
    headers,
    onCellClick,
    isLoading,
    onOrderChange,
    selectedBattles,
    toggleBattle,
    isBattleEdited,
    setIsBattleEdited,
    colors,
  } = props;

  const { root, container, cell, legendIcon, headerContentContainer } =
    useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [orderBy, setOrderBy] = useState(initialOrder ? initialOrder : []);
  const [orderByAsc, setOrderByAsc] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const onSortClick = (orderBy) => {
    if (orderBy) {
      orderBy.map((item) => (item.order = orderByAsc ? 'asc' : 'desc'));
      setOrderBy(orderBy);
      setOrderByAsc(!orderByAsc);
      onCloseMenu();
    }
  };

  const getFullName = (data) => {
    return `${data.last_name} ${data.first_name} ${data.middle_name}`;
  };

  const getRoleFromHeader = (header, roles) => {
    if (!header || !roles) return false;
    const headerRole = roles.find(
      (role) => header.stage_roles_id === role.stage_roles_id,
    );
    return headerRole ? headerRole : false;
  };

  useEffect(() => {
    if (onOrderChange)
      onOrderChange({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        order_by: orderBy,
      });
  }, [page, rowsPerPage, orderBy, orderByAsc]);

  useEffect(() => {
    if (isBattleEdited) {
      setPage(0);
      setRowsPerPage(100);
      setOrderBy(initialOrder ? initialOrder : []);
      setIsBattleEdited(false);
    }
  }, [data]);

  const transformBattles = (battles) => {
    const list = battles?.items;
    if (!list) return {};

    const transformedBattles = {};

    list.forEach((item) => {
      item.roles.forEach((role) => {
        if (role.users_id && role.users_id.length > 0) {
          if (transformedBattles[role.stage_roles_id]) {
            transformedBattles[role.stage_roles_id] += role.users_id.length;
          } else {
            transformedBattles[role.stage_roles_id] = role.users_id.length;
          }
        }
      });
    });

    return transformedBattles;
  };

  const transformedBattles = useMemo(() => {
    return transformBattles(data);
  }, [data]);

  return (
    <Paper className={root}>
      <TableContainer className={container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{ whiteSpace: 'noWrap' }}>
              <TableCell></TableCell>
              <TableCell
                onClick={onOpenMenu}
                style={{ width: '20%', cursor: 'pointer' }}
                key={'player'}
                align={'left'}
                className={cell}
              >
                <div className={headerContentContainer}>
                  Участник
                  <div
                    className={legendIcon}
                    style={{
                      backgroundColor: mapTableHeaderToIconColor(
                        'player',
                        colors,
                      ),
                    }}
                  />
                </div>
              </TableCell>
              {BATTLES.map((battle) => (
                <TableCell
                  onClick={() => {
                    onSortClick(battle.orderBy);
                  }}
                  style={battle.style}
                  key={battle.id}
                  align={battle.align}
                  className={cell}
                >
                  <div className={headerContentContainer}>
                    {battle.label}
                    <div
                      className={legendIcon}
                      style={{
                        backgroundColor: mapTableHeaderToIconColor(
                          battle.id,
                          colors,
                        ),
                      }}
                    />
                  </div>
                </TableCell>
              ))}
              {headers.sort(sortHeaders).map((header, index) => (
                <TableCell
                  style={header.style}
                  key={index}
                  align={header.align}
                  className={cell}
                >
                  <div className={headerContentContainer}>
                    {header.title}
                    {transformedBattles[header.stage_roles_id] && (
                      <div
                        className={legendIcon}
                        style={{
                          backgroundColor: mapTableHeaderToIconColor(
                            header.stage_roles_id,
                            colors,
                          ),
                        }}
                      />
                    )}
                  </div>
                </TableCell>
              ))}
              <TableCell key={'experts_internal'} className={cell}>
                <div className={headerContentContainer}>
                  {'Внутренние эксперты (только просмотр)'}
                  {/* <div className={legendIcon} style={{ backgroundColor: mapTableHeaderToIconColor('Внутренние эксперты (только просмотр)') }} /> */}
                </div>
              </TableCell>
              <TableCell key={'experts_external'} className={cell}>
                <div className={headerContentContainer}>
                  {'Внешние эксперты (только просмотр)'}
                  {/* <div className={legendIcon} style={{ backgroundColor: mapTableHeaderToIconColor('Внешние эксперты (только просмотр)') }} /> */}
                </div>
              </TableCell>
            </TableRow>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={onCloseMenu}
            >
              <MenuItem
                onClick={() =>
                  onSortClick([
                    {
                      field: 'user_last_name',
                    },
                    {
                      field: 'user_first_name',
                    },
                    {
                      field: 'user_middle_name',
                    },
                  ])
                }
              >
                По ФИО
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onSortClick([
                    {
                      field: 'done',
                    },
                  ])
                }
              >
                По загрузке видео
              </MenuItem>
            </Menu>
          </TableHead>
          <TableBody>
            {data.items &&
              data.items.map((row) => {
                return (
                  <TableRow
                    style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell>
                      <Checkbox
                        checked={selectedBattles.includes(row.id)}
                        onChange={(e) => {
                          toggleBattle(row.id);
                        }}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>

                    <TableCell
                      className={cell}
                      onClick={() => onCellClick(row, 1)}
                      style={{
                        color:
                          row.info.player_info && row.info.player_info.done
                            ? 'green'
                            : 'black',
                      }}
                      key={'player'}
                      align={'left'}
                    >
                      {row.player_id != null ? (
                        <Chip
                          style={{
                            backgroundColor:
                              row.info.player_info && row.info.player_info.done
                                ? 'green'
                                : 'slategrey',
                            marginRight: 10,
                            color: 'white',
                            cursor: 'pointer',
                          }}
                          icon={<FaceIcon style={{ color: 'white' }} />}
                          label={getFullName(row.info.player_info)}
                        />
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell
                      className={cell}
                      onClick={() => onCellClick(row, 2)}
                      key={'caseNumber'}
                      align={'left'}
                    >
                      {row.case?.number != null ? row.case.number : '-'}
                    </TableCell>
                    <TableCell
                      className={cell}
                      onClick={() => onCellClick(row, 3)}
                      key={'client'}
                      align={'left'}
                    >
                      {row.client_id != null ? (
                        <Chip
                          style={{
                            backgroundColor: 'slategrey',
                            color: 'white',
                            cursor: 'pointer',
                          }}
                          icon={<FaceIcon style={{ color: 'white' }} />}
                          label={getFullName(row.info.client_info)}
                        />
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    {headers.sort(sortHeaders).map((header, index) => {
                      const headerRole = getRoleFromHeader(header, row.roles);
                      return (
                        <TableCell
                          key={index}
                          className={cell}
                          onClick={() => onCellClick(row, 4 + index)}
                          align={'left'}
                        >
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            {headerRole &&
                              headerRole.user_info.map((user, index) => (
                                <Chip
                                  key={index}
                                  style={{
                                    backgroundColor: user.done
                                      ? 'green'
                                      : 'slategrey',
                                    marginRight: 10,
                                    color: 'white',
                                    cursor: 'pointer',
                                  }}
                                  icon={<FaceIcon style={{ color: 'white' }} />}
                                  label={getFullName(user)}
                                />
                              ))}
                            {(!headerRole ||
                              headerRole.user_info.length == 0) &&
                              '-'}
                          </div>
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className={cell}
                      key={'expertsIn'}
                      align={'left'}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {row.info &&
                          row.info.experts_internal_info.map(
                            (expert, index) => (
                              <Chip
                                key={index}
                                style={{
                                  backgroundColor: expert.done
                                    ? 'green'
                                    : 'slategrey',
                                  marginRight: 10,
                                  color: 'white',
                                  cursor: 'pointer',
                                }}
                                icon={<FaceIcon style={{ color: 'white' }} />}
                                label={getFullName(expert)}
                              />
                            ),
                          )}
                        {(!row.info ||
                          row.info.experts_internal_info.length == 0) &&
                          '-'}
                      </div>
                    </TableCell>
                    <TableCell
                      className={cell}
                      key={'expertsOut'}
                      align={'left'}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {row.info &&
                          row.info.experts_external_info.map(
                            (expert, index) => (
                              <Chip
                                key={index}
                                style={{
                                  backgroundColor: expert.done
                                    ? 'green'
                                    : 'slategrey',
                                  marginRight: 10,
                                  color: 'white',
                                  cursor: 'pointer',
                                }}
                                icon={<FaceIcon style={{ color: 'white' }} />}
                                label={getFullName(expert)}
                              />
                            ),
                          )}
                        {(!row.info ||
                          row.info.experts_external_info.length == 0) &&
                          '-'}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={'Количество строк'}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} из ${count !== -1 ? count : `более ${to}`}`
        }
        rowsPerPageOptions={[1, 10, 50, 100]}
        component="div"
        count={data.hits ? data.hits : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Loader isLoading={isLoading} />
    </Paper>
  );
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    overflowX: 'auto',
    position: 'relative',
  },
  container: {
    //   maxHeight: 440,
  },
  cell: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  legendIcon: {
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    marginLeft: '2rem',
  },
  headerContentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
