import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import {Loader} from '.'
import {getFile} from '../network/fetchApi'

export default function CompanyProfile(props) {
    const {data, isLoading} = props;
    const {name, icon_link} = data;

    const {avatar, paper, input} = useStyles();

    return (
        <Paper elevation={2} className={paper}>
            <img alt={name} src={getFile(icon_link)} className={avatar} />
            <TextField
                id="standard-read-only-input"
                label="Название компании"
                value={name}
                variant="outlined"
                className={input}
                multiline
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Loader isLoading={isLoading}/>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
        padding: theme.spacing(3),
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        width: 'auto', 
        height: 'auto', 
        maxHeight: 300,
        maxWidth: '100%',
        marginBottom: theme.spacing(5)
    },
    input: {
        width: '100%'
    }
}));
