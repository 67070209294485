import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

export default function EmailResultDialog(props) {
  const { result, onRepeat, onCSV, onClose } = props;

  return (
    <Dialog
      open={result != undefined}
      fullWidth
      maxWidth={'sm'}
      TransitionComponent={Transition}
      onClose={onClose}
      size
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Внимание!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {result && result.fail_count > 0
            ? `Не удалось отправить все приглашения. Количество неотправленных писем: ${result.fail_count} `
            : 'Приглашения на упражнение успешно отправлены!'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {result && result.fail_count > 0 && (
          <Button
            onClick={() => onRepeat(result.campaign_id)}
            color="secondary"
          >
            Отправить повторно
          </Button>
        )}
        <Button onClick={() => onCSV(result.campaign_id)}>
          Скачать Результат
        </Button>
        <Button onClick={onClose} color="primary">
          Ок
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
