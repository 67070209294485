import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import { Loader } from '.';
import { Chip } from '@material-ui/core';
import CustomTooltip from './CustomTooltip';

export default function StageStats(props) {
  const { data, isLoading, stats, colors } = props;

  const {
    paper,
    header,
    input,
    statUploads,
    statComments,
    graphContainer,
    columnWrapper,
    columnStats,
    graph,
    wrapper,
    outerContainer,
    innerCircle,
  } = useStyles();

  return (
    <div className={wrapper}>
      <div>
        <Typography variant="h3" align="left" style={{ fontWeight: '700' }}>
          Статистика
        </Typography>
      </div>

      <Paper
        elevation={1}
        className={paper}
        style={{
          borderRadius: '1.5rem',
          // boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.161)',
          width: 'min-content',
        }}
      >
        <div className={statUploads}>
          {stats &&
            Object.entries(stats.columns).map(([key, value], index) => {
              return (
                <CustomTooltip key={index} title={value.tooltip}>
                  <div className={columnWrapper}>
                    <Typography
                      variant="h6"
                      style={{ color: '#828282', lineHeight: 1 }}
                    >
                      {value.done + '/' + value.total}
                    </Typography>
                    <div className={graphContainer} key={index}>
                      <div
                        className={graph}
                        style={{
                          height: `${
                            (value.done / (value.total ? value.total : 1)) * 100
                          }%`,
                          background: value.color,
                        }}
                      ></div>
                    </div>
                  </div>
                </CustomTooltip>
              );
            })}
          {stats && stats.videos && (
            <CustomTooltip title={stats.videos.tooltip}>
              <div className={outerContainer}>
                <svg width="11rem" height="11.07rem" transform="rotate(90)">
                  <circle
                    stroke={stats.videos.color ?? '#4073D3'}
                    strokeWidth={'8%'}
                    strokeLinecap="round"
                    strokeDasharray={`${
                      (45.45 * 2 * Math.PI * stats.videos.done) /
                      stats.videos.total
                    }% ,${45.45 * 2 * Math.PI}%`}
                    fill="none"
                    cx="50%"
                    cy="50%"
                    r="45.45%"
                  ></circle>
                </svg>
                <div className={innerCircle}>
                  <Chip
                    style={{
                      backgroundColor: stats.videos.color ?? '#4073D3',
                      color: 'white',
                      fontSize: '1.25rem',
                      fontWeight: '700',
                    }}
                    label={stats.videos.done + '/' + stats.videos.total}
                  />
                </div>
              </div>
            </CustomTooltip>
          )}
        </div>
        <Loader isLoading={isLoading} />
      </Paper>
    </div>
  );
}

// const LinearProgressWithLabel = (props) => {
//     const {value, total} = props;

//     return (
//       <Box display="flex" alignItems="center">
//         <Box width="100%" mr={1}>
//           <LinearProgress variant="determinate" value={(value/total)*100} />
//         </Box>
//         <Box minWidth={35}>
//           <Typography variant="body2" color="textSecondary">{`${value?value:0}/${total?total:0}`}</Typography>
//         </Box>
//       </Box>
//     );
//   }

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: '',
    borderRadius: '',
  },
  input: {
    width: '100%',
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  statUploads: {
    marginTop: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1.25rem 1.25rem',
    minWidth: '18rem',
    minHeight: '14.143rem',
  },

  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '5vw',
    [theme.breakpoints.down('sm')]: {
      marginRight: '5vw',
    },
  },
  columnStats: {},
  graphContainer: {
    marginTop: '1rem',
    width: '1.5rem',
    height: '8.125rem',
    background: '#FFFFFF',
    boxShadow:
      'inset 0px 2px 5px rgba(0, 0, 0, 0.12), inset 0px -1px 3px rgba(0, 0, 0, 0.05)',
    borderRadius: '1.25rem',
    display: 'flex',
    alignItems: 'flex-end',
  },
  graph: {
    width: '100%',
    borderRadius: '1.25rem',
  },
  statComments: {
    padding: theme.spacing(3),
  },
  outerContainer: {
    height: '11rem',
    width: '11rem',
    position: 'relative',
    borderRadius: '50%',
    boxShadow: 'inset 0px 4px 5px rgba(0, 0, 0, 0.16)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerCircle: {
    position: 'absolute',
    width: 'calc(11rem - 24px)',
    height: 'calc(11rem - 24px)',
    borderRadius: '50%',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    paddingLeft: '2rem',
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
      paddingLeft: '0rem',
      paddingBottom: '1rem',
      paddingRight: '0.5rem',
    },
  },
}));
