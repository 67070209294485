import React, { useState, useCallback } from 'react';

export const useEditCriterion = () => {
  const [idsOfEditedCriteria, setIdsOfEditedCriteria] = useState([]);

  const editingCriterion = useCallback(
    (id) => {
      return new Set(idsOfEditedCriteria).has(id);
    },
    [idsOfEditedCriteria],
  );

  const editCriterion = useCallback((id) => {
    setIdsOfEditedCriteria((criteriaIds) => {
      const criteriaIdsSet = new Set(criteriaIds);
      if (!criteriaIdsSet.has(id)) {
        criteriaIdsSet.add(id);
        return [...criteriaIdsSet.values()];
      }
      return criteriaIds;
    });
  }, []);

  const stopEditingCriterion = useCallback((id) => {
    setIdsOfEditedCriteria((criteriaIds) => {
      const criteriaIdsSet = new Set(criteriaIds);
      if (criteriaIdsSet.has(id)) {
        criteriaIdsSet.delete(id);
        return [...criteriaIdsSet.values()];
      }
      return criteriaIds;
    });
  }, []);

  return { editingCriterion, editCriterion, stopEditingCriterion };
};
