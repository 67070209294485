import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CloseIcon from '@material-ui/icons/Close';

export default function FabDial(props) {
    const {icon, actions, open, onOpen, onClose, onClick, className, direction} = props;
    const {speedDial, speedDialIcon} = useStyles();

    return (
            <SpeedDial
            ariaLabel="SpeedDial example"
            className={className}
            hidden={false}
            icon={<SpeedDialIcon icon={icon} openIcon={<CloseIcon/>} className={speedDialIcon}/>}
            onClick={onClick}
            open={open}
            direction={direction}
            >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.onClick}
                />
            ))}
            </SpeedDial>
    );
}

const useStyles = makeStyles((theme) => ({
    speedDialIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
