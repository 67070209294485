import React, {forwardRef, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from '@material-ui/core/StepButton';

import {Loader} from '.'

export default function StepperDialog(props) {
    const {title, data, completed, allCompleted, open, onClose, onComplete, submitButtonText, rightElement, isLoading, strict} = props;
    const {root, appBar, header, backButton, content, buttonsContainer, stepContent, stepIcon} = useStyles();

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const getStepContent = (index) => {
        if(index < data.length) return data[index].content
        else return 'Unknown stepIndex';
    }

    useEffect(() => {
        if(open) setActiveStep(0)
    },[open])

    return (
        <Dialog className={root} fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
            <AppBar className={appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={header}>
                        {title}
                    </Typography>
                    {rightElement}
                </Toolbar>
            </AppBar>
            <Stepper style={{overflow: 'auto'}} nonLinear activeStep={activeStep} alternativeLabel={true}>
                    {data.map((step, index) => (
                    <Step key={step.title}>
                        <StepButton disabled={strict} onClick={handleStep(index)}>
                            {step.title}
                        </StepButton>
                    </Step>
                    ))}
            </Stepper>
            <div className={content}>
                <div className={stepContent}>
                    {getStepContent(activeStep)}
                </div>
                {!strict && <div className={buttonsContainer}>
                        <Button style={{flex: 1}} disabled={!allCompleted} variant="contained" color="primary" onClick={onComplete}>
                            {submitButtonText?submitButtonText:'Создать'}
                        </Button>
                </div>}
                {strict && <div className={buttonsContainer}>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={backButton}
                        >
                            Назад
                        </Button>
                        <Button disabled={activeStep < data.length-1?!completed[activeStep]:!allCompleted} variant="contained" color="primary" onClick={activeStep < data.length-1?handleNext:onComplete}>
                            {activeStep < data.length-1?'Далее':'Создать'}
                        </Button>
                </div>}
            </div>
            <Loader isLoading={isLoading}/>
        </Dialog>
    );
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        position: 'relative',
    },
    appBar: {
        position: 'relative',
    },
    header: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    content: {
        height: '100%',
        margin: theme.spacing(5),
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    stepContent: {
        width: '100%',
        flexGrow: 1, 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonsContainer:{
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
}));
