export const stageDataSelector = (state) => state.stage.data;
export const stageDataLoadingSelector = (state) => state.stage.loading;
export const autoSendInitesSelector = (state) => state.stage.autoSendInites;
export const stageEditingSelector = (state) => state.stage.editing;

export const stageBattlesSelector = (state) => state.stage.battles.data;
export const stageBattlesLoadingSelector = (state) =>
  state.stage.battles.loading;
export const stageBattlesHeadersSelector = (state) =>
  state.stage.battles.headers;

export const stageCasesSelector = (state) => state.stage.cases.data;
export const stageCasesLoadingSelector = (state) => state.stage.cases.loading;

export const stageRolesSelector = (state) => state.stage.roles.data;
export const stageRolesLoadingSelector = (state) => state.stage.roles.loading;

export const battleActionLoadingSelector = (state) =>
  state.stage.battleAction.loading;

export const caseActionLoadingSelector = (state) =>
  state.stage.caseAction.loading;
export const caseActionErrorSelector = (state) => state.stage.caseAction.error;

export const roleActionLoadingSelector = (state) =>
  state.stage.roleAction.loading;
