import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function SearchInput(props) {
  const { fields, onFiltersChange } = props;

  const { root } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [field, setField] = useState({});
  const [searchInput, setSearchInput] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFiledChange = (field) => {
    setField(field);
    handleClose();
  };

  const onSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    if (fields && fields.length > 0) {
      setField(fields[0]);
    }
  }, []);

  useEffect(() => {
    if (onFiltersChange)
      onFiltersChange([
        {
          field: field.value,
          search_any: searchInput,
        },
      ]);
  }, [searchInput]);

  return (
    <div className={root}>
      <TextField
        id="outlined-full-width"
        label="Поиск"
        placeholder="Введите текст"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment: (
            <span
              onClick={handleClick}
              style={{
                whiteSpace: 'nowrap',
                marginRight: 5,
                cursor: 'pointer',
              }}
            >
              {field.title}:
            </span>
          ),
        }}
        variant="outlined"
        onChange={onSearchChange}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fields &&
          fields.map((field, index) => (
            <MenuItem key={index} onClick={() => onFiledChange(field)}>
              {field.title}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 10,
  },
}));
