import React from 'react';
import Grid from '@material-ui/core/Grid';

import { PercentsInput, HelpTooltip } from '../../../../components';
import { useStyles } from './styles';

const TooltipPercentInput = ({
  label,
  weight,
  tooltipText,
  onChange,
  inputProps,
  style = {},
}) => {
  const styles = useStyles();

  return (
    <Grid
      item
      container
      alignItems="center"
      xs={9}
      className={styles.weightAndTooltipWrapper}
      style={style}
    >
      <Grid item xs={6}>
        <div className={styles.criterionWeightLabel}>{label}</div>
      </Grid>
      <Grid item xs={4}>
        <PercentsInput inputProps={inputProps} value={weight} onChange={onChange} />
      </Grid>
      <Grid item xs={2}>
        <HelpTooltip text={tooltipText} />
      </Grid>
    </Grid>
  );
};

export default TooltipPercentInput;
