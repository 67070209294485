import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeError } from '../../utils/serializeError';
import CompetenceAPI from '../../network/CompetenceAPI';
import { SOMETHING_WENT_WRONG } from '../../network/const';

const initialState = {
  data: {},
  loading: false,
  error: null,
  updating: false,
  updateSuccess: false,
  updateError: null,
};

export const getCompetence = createAsyncThunk(
  'competence/getCompetence',
  async ({ stageId }) => {
    try {
      return await CompetenceAPI.getCompetencies({
        filters: [{ field: 'stage_id', equal: stageId }],
      });
    } catch (error) {
      throw serializeError(error);
    }
  },
);

export const updateCompetence = createAsyncThunk(
  'competence/updateCompetence',
  async ({ id, data }) => {
    try {
      return await CompetenceAPI.updateCompetence(id, data);
    } catch (error) {
      throw serializeError(error);
    }
  },
);

const competenceSlice = createSlice({
  name: 'competence',
  initialState,
  reducers: {
    resetUpdateSuccess: (state) => {
      state.updateSuccess = false;
    },
  },
  extraReducers: (builder) => {
    // Get competence
    builder.addCase(getCompetence.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCompetence.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.body.length > 0) state.data = action.payload.body[0];
    });
    builder.addCase(getCompetence.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Update competence
    builder.addCase(updateCompetence.pending, (state) => {
      state.updating = true;
      state.updateSuccess = false;
      state.updateError = null;
    });
    builder.addCase(updateCompetence.fulfilled, (state, action) => {
      state.updating = false;
      state.updateSuccess = true;
      state.data = action.payload.body;
    });
    builder.addCase(updateCompetence.rejected, (state, action) => {
      state.updating = false;
      state.updateSuccess = false;
      state.updateError = action.error?.message || 'Что-то пошло не так';
    });
  },
});

export const { resetUpdateSuccess } = competenceSlice.actions;
export default competenceSlice.reducer;
