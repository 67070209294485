import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';

import MenuIcon from '@material-ui/icons/Menu';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExtensionIcon from '@material-ui/icons/Extension';

import { useDispatch, useSelector } from 'react-redux';
import { setAdminInfo } from '../redux/slices/admin.slice';
import { getUserInfo, userLogOut } from '../network/fetchApi';

import { useHistory, useLocation } from 'react-router-dom';

import { UserProfile } from '.';

const drawerCloseWidth = 74;

export default function AppBarMenu(props) {
  const { children } = props;
  const { root, appBar, menuButton, title, content, drawerItemIcon, list } =
    useStyles();

  const [openLeftDrawer, setOpenLeftDrawer] = useState(false);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);

  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.admin);

  let history = useHistory();
  let location = useLocation();

  const loadUserData = () => {
    getUserInfo((responseJson) => {
      if (responseJson.status == 200 && responseJson.body.role == 'ADMIN') {
        dispatch(setAdminInfo(responseJson.body));
      } else {
        history.push('/');
      }
    });
  };

  const logOut = () => {
    userLogOut((responseJson) => {
      if (responseJson.status == 200) history.push('/');
    });
  };

  const toggleLeftDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenLeftDrawer(open);
  };

  const toggleRightDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenRightDrawer(open);
  };

  const onLeftDrawerItemClick = (path) => {
    history.push(path);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  if (!info) {
    return null;
  }

  return (
    <div className={root}>
      <AppBar position="fixed" className={appBar}>
        <Toolbar>
          <IconButton
            onClick={toggleLeftDrawer(true)}
            edge="start"
            className={menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Typography variant="h6" className={title}>
            Involver 360
          </Typography>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={toggleRightDrawer(true)}
            color="inherit"
          >
            <AccountCircle fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main className={content}>
        <Toolbar />
        {children}
      </main>
      <React.Fragment key={'left'}>
        <SwipeableDrawer
          anchor={'left'}
          open={openLeftDrawer}
          onClose={toggleLeftDrawer(false)}
          onOpen={toggleLeftDrawer(true)}
        >
          <div
            className={list}
            role="presentation"
            onClick={toggleLeftDrawer(false)}
            onKeyDown={toggleLeftDrawer(false)}
          >
            <List subheader={<ListSubheader>Меню</ListSubheader>}>
              <Divider />
              {[
                {
                  title: 'Список компаний',
                  path: '/companies',
                  icon: <LocationCityIcon fontSize="large" />,
                },
                // {title: 'Список кейсов', path: '/cases', icon: <ExtensionIcon fontSize='large'/>}
              ].map((item) => (
                <ListItem
                  onClick={() => onLeftDrawerItemClick(item.path)}
                  style={{ paddingLeft: 0 }}
                  selected={location.pathname == item.path}
                  button
                  key={item.title}
                >
                  <ListItemIcon className={drawerItemIcon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              ))}
            </List>
          </div>
        </SwipeableDrawer>
      </React.Fragment>
      <React.Fragment key={'right'}>
        <SwipeableDrawer
          anchor={'right'}
          open={openRightDrawer}
          onClose={toggleRightDrawer(false)}
          onOpen={toggleRightDrawer(true)}
        >
          <UserProfile data={info} onLogout={logOut} />
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {},
  list: {
    width: 250,
  },
  drawerItemIcon: {
    width: drawerCloseWidth,
    textAlign: 'center',
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: 160,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
  },
  accountTitle: {
    paddingRight: 10,
  },
}));
