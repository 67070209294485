import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useQuery from '../useQuery';
import {
  deleteChamp,
  deleteChampUser,
  getChamp,
  getChampUserList,
  updateChamp,
  updateChampUser,
  updateUserInfo,
} from '../../network/fetchApi';

const useLoadChampData = ({
  onNewChampDialogClose,
  onEditUserDialogClose,
  onConfirmUserDialogClose,
  onUserDialogClose,
  onUserDialogOpen,
}) => {
  const [champData, setChampData] = useState({});
  const [champUsers, setChampUsers] = React.useState([]);
  const [userData, setUserData] = useState({});

  const [isLoadingChampData, setIsLoadingChampData] = useState(true);
  const [isLoadingChampUsers, setIsLoadingChampUsers] = useState(true);

  const [isEditChampLoading, setIsEditChampLoading] = useState(false);
  const [isEditUserLoading, setIsEditUserLoading] = useState(false);

  const query = useQuery();
  const history = useHistory();

  const loadChampByID = (id) => {
    setIsLoadingChampData(true);

    getChamp({ id }, (responseJson) => {
      setIsLoadingChampData(false);

      if (responseJson.status == 200) {
        console.log('loadChampByID_response', responseJson.body);
        setChampData(responseJson.body);
      }
    });
  };

  const loadChampUsers = (params = {}) => {
    setIsLoadingChampUsers(true);
    const id = query.get('id');

    if (params.order_by == null) {
      params.order_by = [
        {
          field: 'last_name',
          order: 'asc',
        },
        {
          field: 'first_name',
          order: 'asc',
        },
        {
          field: 'middle_name',
          order: 'asc',
        },
      ];
    }

    getChampUserList({ id }, params, (responseJson) => {
      setIsLoadingChampUsers(false);

      if (responseJson.status == 200) {
        console.log('getChampUserList_response', responseJson.body);
        setChampUsers(responseJson.body);
      }
    });
  };

  const onCompleteEditChamp = (data) => {
    setIsEditChampLoading(true);

    updateChamp(data, (responseJson) => {
      setIsEditChampLoading(false);

      if (responseJson.status == 200) {
        setChampData(responseJson.body);

        onNewChampDialogClose();
      }
    });
  };

  const onCompleteEditUser = (data) => {
    setIsEditUserLoading(true);

    updateUserInfo(data, (responseJson) => {
      setIsEditUserLoading(false);

      if (responseJson.status == 200) {
        setIsEditUserLoading(true);

        updateChampUser(
          { championship_id: champData.id, ...data },
          (responseJson) => {
            setIsEditUserLoading(false);

            if (responseJson.status == 200) {
              setUserData(responseJson.body);

              if (champUsers.items)
                setChampUsers({
                  ...champUsers,
                  items: champUsers.items.map((item) =>
                    item.id == responseJson.body.id ? responseJson.body : item,
                  ),
                });

              onEditUserDialogClose();
            }
          },
        );
      }
    });
  };

  const onDeleteChamp = () => {
    setIsEditChampLoading(true);

    deleteChamp(champData, (responseJson) => {
      setIsEditChampLoading(false);

      if (responseJson.status == 200) {
        history.push(`/company?id=${champData.company_id}`);
      }
    });
  };

  const onDeleteChampUser = () => {
    setIsEditUserLoading(true);

    deleteChampUser(
      { championship_id: champData.id, ...userData },
      (responseJson) => {
        setIsEditUserLoading(false);

        if (responseJson.status == 200) {
          loadChampUsers();
          onConfirmUserDialogClose();
          onEditUserDialogClose();
          onUserDialogClose();
        }
      },
    );
  };

  const onUserClick = (data) => {
    setUserData(data);
    onUserDialogOpen();
  };

  return {
    champData,
    champUsers,
    userData,
    isLoadingChampData,
    isLoadingChampUsers,
    isEditChampLoading,
    isEditUserLoading,
    loadChampByID,
    loadChampUsers,
    onCompleteEditChamp,
    onCompleteEditUser,
    onDeleteChamp,
    onDeleteChampUser,
    onUserClick,
  };
};

export default useLoadChampData;
