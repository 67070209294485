import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export default function UserItem (props) {
    const {data, expanded} = props;
    const {first_name, middle_name, last_name, email, password, post, team, chief} = data;
    const {accordionHeader} = useStyles();

    return (
            <Accordion expanded={expanded} style={{boxShadow:'none', borderBottomWidth: 1, borderBottomColor:'rgba(0, 0, 0, .125)', borderBottomStyle: 'solid', margin: 0}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <AccountCircleIcon fontSize='large'/>
                    <Typography className={accordionHeader}>{`${last_name} ${first_name} ${middle_name}`}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{marginBottom: 10}}>
                        <span style={{fontWeight: 'bold'}}>Почта: </span>{email}
                    </div>
                    <div style={{marginBottom: 10}}>
                        <span style={{fontWeight: 'bold'}}>Пароль: </span>{password}
                    </div>
                    <div style={{marginBottom: 10}}>
                        <span style={{fontWeight: 'bold'}}>Должность: </span>{post}
                    </div>
                    <div style={{marginBottom: 10}}>
                        <span style={{fontWeight: 'bold'}}>Команда: </span>{team}
                    </div>
                    {/* <div style={{marginBottom: 10}}>
                        <span style={{fontWeight: 'bold'}}>Часовой пояс:</span> Часовой пояс
                    </div> */}
                    <div style={{marginBottom: 10}}>
                        <span style={{fontWeight: 'bold'}}>ФИО руководителя: </span>{chief}
                    </div>
                </AccordionDetails>
            </Accordion>
    )
}

const useStyles = makeStyles((theme) => ({
    accordionHeader: {
        marginLeft: 5,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        alignSelf: 'center'
      },
}));
