import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';

import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import { useHistory } from 'react-router-dom';
import useQuery from '../utils/useQuery';

import {
  BreadCrumbs,
  ChampProfile,
  DataTable,
  FabDial,
  NewChampDialog,
  NewStageDialog,
  TabPanel,
  NewUserDialog,
  EditUserDialog,
  DeleteButton,
  UserDialog,
  DetachChampButton,
  ConfirmActionDialog,
  InfoDialog,
  SearchInput,
  ExportDialog,
} from '../components';

import {
  getStageList,
  addStage,
  confirmChampUsers,
  resendEmails,
  getEmailsCSV,
} from '../network/fetchApi';
import { STAGES, USERS } from '../constants/headers';
import EmailResultDialog from '../components/EmailResultDialog';
import useLoadChampData from '../utils/hooks/useLoadChampData';
import { Button } from '@material-ui/core';

export default function ChampScreen() {
  const { root, tabs, header, speedDial, fab, settingsDial } = useStyles();
  const query = useQuery();
  const history = useHistory();

  const [isLoadingChampStages, setIsLoadingChampStages] = useState(true);
  const [isNewStageLoading, setIsNewStageLoading] = useState(false);
  const [openAddDial, setOpenAddDial] = useState(false);
  const [openSettingsDial, setOpenSettingsDial] = useState(false);
  const [openNewStageDialog, setOpenNewStageDialog] = useState(false);
  const [openNewUserDialog, setOpenNewUserDialog] = useState(false);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [champStages, setChampStages] = useState([]);
  const [openConfirmUserDialog, setOpenConfirmUserDialog] = useState(false);
  const [openConfirmChampDialog, setOpenConfirmChampDialog] = useState(false);
  const [infoDialogText, setInfoDialogText] = useState('');
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [stagesOrder, setStagesOrder] = useState({});
  const [stagesFilters, setStagesFilters] = useState([]);
  const [usersOrder, setUsersOrder] = useState({});
  const [usersFilters, setUsersFilters] = useState([]);
  const [emailResult, setEmailResult] = useState();
  const [openNewChampDialog, setOpenNewChampDialog] = useState(false);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [openExportDialog, setOpenExportDialog] = useState(false);

  const onNewChampDialogOpen = () => {
    setOpenNewChampDialog(true);
  };

  const onNewChampDialogClose = () => {
    setOpenNewChampDialog(false);
  };

  const onEditUserDialogOpen = () => {
    setOpenEditUserDialog(true);
  };

  const onEditUserDialogClose = () => {
    setOpenEditUserDialog(false);
  };

  const onConfirmUserDialogClose = () => {
    setOpenConfirmUserDialog(false);
  };

  const onConfirmUserDialogOpen = () => {
    setOpenConfirmUserDialog(true);
  };

  const onUserDialogClose = () => {
    setOpenUserDialog(false);
  };

  const onUserDialogOpen = () => {
    setOpenUserDialog(true);
  };

  const onInfoDialogClose = () => {
    setOpenInfoDialog(false);
  };

  const onInfoDialogOpen = (text) => {
    setInfoDialogText(text);
    setOpenInfoDialog(true);
  };

  const onConfirmChampDialogClose = () => {
    setOpenConfirmChampDialog(false);
  };

  const onConfirmChampDialogOpen = () => {
    setOpenConfirmChampDialog(true);
  };

  const onNewStageDialogOpen = () => {
    setOpenNewStageDialog(true);
  };

  const onNewStageDialogClose = () => {
    setOpenNewStageDialog(false);
  };

  const onNewUserDialogOpen = () => {
    setOpenNewUserDialog(true);
  };

  const onNewUserDialogClose = () => {
    setOpenNewUserDialog(false);
  };

  const onToggleSettingsDial = () => {
    setOpenSettingsDial(!openSettingsDial);
  };

  const onToggleAddDial = () => {
    setOpenAddDial(!openAddDial);
  };

  const onUsersOrderChange = (order) => {
    setUsersOrder(order);
  };

  const onUsersFiltersChange = (filters) => {
    setUsersFilters(filters);
  };

  const onStagesOrderChange = (order) => {
    setStagesOrder(order);
  };

  const onStagesFiltersChange = (filters) => {
    setStagesFilters(filters);
  };

  const onExportDialogOpen = () => {
    setOpenExportDialog(true);
  };

  const onExportDialogClose = () => {
    setOpenExportDialog(false);
  };

  const {
    loadChampByID,
    loadChampUsers,
    onCompleteEditChamp,
    onCompleteEditUser,
    onDeleteChamp,
    onDeleteChampUser,
    onUserClick,
    champData,
    champUsers,
    userData,
    isLoadingChampData,
    isLoadingChampUsers,
    isEditChampLoading,
    isEditUserLoading,
  } = useLoadChampData({
    onNewChampDialogClose,
    onEditUserDialogClose,
    onConfirmUserDialogClose,
    onUserDialogClose,
    onUserDialogOpen,
  });

  const OnEmailResultClosed = () => {
    setEmailResult(undefined);
  };

  const onEmailsCSVDownload = (campaignId) => {
    getEmailsCSV(campaignId);
  };

  const onRepeat = (campaignId) => {
    setEmailResult(undefined);
    onInfoDialogOpen('Приглашения на упражнение отправляются!');
    resendEmails(campaignId, (responseJson) => {
      if (responseJson.status == 200) {
        setEmailResult(responseJson.body);
      }
    });
  };

  const loadChampStages = (params = {}) => {
    setIsLoadingChampStages(true);

    params.filters = [
      ...params.filters,
      {
        field: 'championship_id',
        equal: query.get('id'),
      },
    ];

    if (params.order_by && params.order_by.length === 0)
      params.order_by = [
        {
          field: 'title',
          order: 'asc',
        },
      ];

      console.log(`Params was `, params)

    getStageList(params, (responseJson) => {
      setIsLoadingChampStages(false);

      if (responseJson.status == 200) {
        console.log('getStageList_response', responseJson.body);
        setChampStages(responseJson.body);
      }
    });
  };

  const onCompleteNewStage = (data) => {
    console.log(`Added new stage: `, data);
    setIsNewStageLoading(true);

    addStage(
      { ...data, championship_id: champData.id, enable_actions: true },
      (responseJson) => {
        setIsNewStageLoading(false);
        if (responseJson.status == 200) {
          if (champStages.items)
            setChampStages({
              ...champStages,
              items: [responseJson.body, ...champStages.items],
            });

          onNewStageDialogClose();
        }
      },
    );
  };

  const onCompleteNewUser = () => {
    loadChampUsers();
    onNewUserDialogClose();
  };

  const onStageClick = (data) => {
    history.push(`/company/champ/stage?id=${data.id}`);
  };

  const onChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const onSendUsersConfirmation = () => {
    onInfoDialogOpen('Приглашения аккаунтам отправляются!');

    confirmChampUsers({ id: champData.id }, (responseJson) => {
      if (responseJson.status == 200) {
        setEmailResult(responseJson.body);
      }
    });
  };

  useEffect(() => {
    loadChampStages({ ...stagesOrder, filters: stagesFilters });
  }, [stagesOrder, stagesFilters]);

  useEffect(() => {
    loadChampUsers({ ...usersOrder, filters: usersFilters });
  }, [usersOrder, usersFilters]);

  const champID = query.get('id');

  useEffect(() => {
    loadChampByID(champID);
  }, []);

  return (
    <div className={root}>
      <BreadCrumbs
        data={[
          { name: 'Список компаний', href: '/companies' },
          {
            name: 'Компания',
            href: champData.company_id
              ? `/company?id=${champData.company_id}`
              : null,
          },
          { name: 'Чемпионат' },
        ]}
      />

      <Button variant='contained' onClick={onExportDialogOpen} color='primary'>
        Экспорт
      </Button>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography variant="h5" className={header}>
            О чемпионате
          </Typography>
          <ChampProfile data={champData} isLoading={isLoadingChampData} />
        </Grid>
        <Grid container item xs={12} direction={'column'}>
          <Grid item xs>
            <Tabs
              scrollButtons="auto"
              variant="scrollable"
              className={tabs}
              value={tabValue}
              onChange={onChangeTab}
            >
              <Tab
                label="Список этапов"
                id="tab-0"
                aria-controls="tabpanel-0"
              />
              <Tab
                label="Список аккаунтов"
                id="tab-1"
                aria-controls="tabpanel-1"
              />
            </Tabs>
          </Grid>
          <Grid item xs>
            <TabPanel value={tabValue} index={0}>
              <SearchInput
                onFiltersChange={onStagesFiltersChange}
                fields={[{ title: 'По названию', value: 'title' }]}
              />
              <DataTable
                initialOrder={[{ field: 'title', order: 'asc' }]}
                onOrderChange={onStagesOrderChange}
                isLoading={isLoadingChampStages}
                headers={STAGES}
                data={champStages}
                onRowClick={onStageClick}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <SearchInput
                onFiltersChange={onUsersFiltersChange}
                fields={[
                  { title: 'По фамилии', value: 'last_name' },
                  { title: 'По имени', value: 'first_name' },
                  { title: 'По отчеству', value: 'middle_name' },
                  { title: 'По почте', value: 'email' },
                  { title: 'По должности', value: 'post' },
                  { title: 'По руководителю', value: 'chief' },
                  { title: 'По команде', value: 'team' },
                ]}
              />
              <DataTable
                initialOrder={[
                  { field: 'last_name', order: 'asc' },
                  { field: 'first_name', order: 'asc' },
                  { field: 'middle_name', order: 'asc' },
                ]}
                onOrderChange={onUsersOrderChange}
                isLoading={isLoadingChampUsers}
                headers={USERS}
                data={champUsers}
                onRowClick={onUserClick}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
      <FabDial
        icon={<SettingsIcon />}
        className={settingsDial}
        direction="right"
        actions={[
          {
            icon: <EmailIcon />,
            name: 'Отправить всем подтверждения почты',
            onClick: onSendUsersConfirmation,
          },
        ]}
        onClick={onToggleSettingsDial}
        open={openSettingsDial}
      />
      <Fab
        className={fab}
        color="primary"
        aria-label="add"
        onClick={onNewChampDialogOpen}
      >
        <EditIcon />
      </Fab>
      <FabDial
        icon={<AddIcon />}
        className={speedDial}
        direction="left"
        actions={[
          {
            icon: <Typography variant="h5">А</Typography>,
            name: 'Добавить аккаунт',
            onClick: onNewUserDialogOpen,
          },
          {
            icon: <Typography variant="h5">Э</Typography>,
            name: 'Добавить этап',
            onClick: onNewStageDialogOpen,
          },
        ]}
        onClick={onToggleAddDial}
        open={openAddDial}
      />
      <NewChampDialog
        title={'Редактировать чемпионат'}
        data={champData}
        rightElement={<DeleteButton onClick={onConfirmChampDialogOpen} />}
        submitButtonText="Сохранить"
        open={openNewChampDialog}
        onClose={onNewChampDialogClose}
        onComplete={onCompleteEditChamp}
        isLoading={isEditChampLoading}
      />
      <NewStageDialog
        title={'Новый этап'}
        open={openNewStageDialog}
        onClose={onNewStageDialogClose}
        onComplete={onCompleteNewStage}
        isLoading={isNewStageLoading}
      />
      <NewUserDialog
        params={{ champ_id: champData.id }}
        open={openNewUserDialog}
        onClose={onNewUserDialogClose}
        onComplete={onCompleteNewUser}
      />
      <EditUserDialog
        title={'Редактировать аккаунт'}
        params={{ disabled: { email: true } }}
        data={userData}
        submitButtonText={'Сохранить'}
        rightElement={<DetachChampButton onClick={onConfirmUserDialogOpen} />}
        open={openEditUserDialog}
        onClose={onEditUserDialogClose}
        onComplete={onCompleteEditUser}
        isLoading={isEditUserLoading}
      />
      <UserDialog
        data={userData}
        open={openUserDialog}
        onEdit={onEditUserDialogOpen}
        onClose={onUserDialogClose}
      />
      <ConfirmActionDialog
        title="Удалить чемпионат?"
        text="Вы уверены, что хотите удалить все данные о чемпионате?"
        open={openConfirmChampDialog}
        onClose={onConfirmChampDialogClose}
        onConfirm={onDeleteChamp}
      />
      <ConfirmActionDialog
        title="Удалить аккаунт с чемпионата?"
        text="Вы уверены, что хотите удалить аккаунт с чемпионата?"
        open={openConfirmUserDialog}
        onClose={onConfirmUserDialogClose}
        onConfirm={onDeleteChampUser}
      />
      <InfoDialog
        text={infoDialogText}
        open={openInfoDialog}
        onClose={onInfoDialogClose}
      />

      <EmailResultDialog
        onRepeat={onRepeat}
        onCSV={onEmailsCSVDownload}
        result={emailResult}
        onClose={OnEmailResultClosed}
      />
      
      <ExportDialog 
        open={openExportDialog}
        onClose={onExportDialogClose}
        stages={champStages}
        championshipID={champID}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  settingsDial: {
    zIndex: 100,
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  fab: {
    zIndex: 100,
    position: 'fixed',
    bottom: 85,
    right: theme.spacing(2),
  },
  speedDial: {
    zIndex: 100,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
