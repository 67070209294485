import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExtensionIcon from '@material-ui/icons/Extension';

export default function CaseItem (props) {
        const {data, expanded} = props;
        const {title, number, task_general, task_player, task_client} = data;
        const {accordionHeader} = useStyles();

        return (
                <Accordion expanded={expanded} style={{boxShadow:'none', borderBottomWidth: 1, borderBottomColor:'rgba(0, 0, 0, .125)', borderBottomStyle: 'solid'}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <ExtensionIcon fontSize='large'/>
                        <Typography className={accordionHeader}>{title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{marginBottom: 10}}>
                                    <span style={{fontWeight: 'bold'}}>Номер: </span>{number}
                                </div>
                                <div style={{marginBottom: 10}}>
                                    <span style={{fontWeight: 'bold'}}>Общее условие: </span>{task_general} 
                                </div>
                                <div style={{marginBottom: 10}}>
                                    <span style={{fontWeight: 'bold'}}>Уловие для Участника: </span>{task_player} 
                                </div>
                                <div style={{marginBottom: 10}}>
                                    <span style={{fontWeight: 'bold'}}>Условие для Клиента: </span>{task_client} 
                                </div>
                    </AccordionDetails>
                </Accordion>
        )
    }

const useStyles = makeStyles((theme) => ({
    accordionHeader: {
        marginLeft: 5,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        alignSelf: 'center'
      },
}));
