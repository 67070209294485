import React, { useState, useCallback, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';

import { PercentsInput, StepperDialog } from '.';

export default function NewStageDialog({
  data,
  title,
  open,
  onClose,
  rightElement,
  submitButtonText,
  isLoading,
  onComplete,
}) {
  const [completed, setCompleted] = useState({});
  const [allCompleted, setAllCompleted] = useState(false);

  const [newData, setNewData] = useState({});

  const onNewTitleChange = (event) =>
    setNewData({ ...newData, title: event.target.value });

  const onNewDateFromChange = (time_start) =>
    setNewData({ ...newData, time_start });

  const onNewDateToChange = (time_finish) =>
    setNewData({ ...newData, time_finish });

  const onNewWeightChange = (weight) => setNewData({ ...newData, weight });

  const validateStep1 = useCallback(
    () => newData.title && newData.title.length > 0,
    [newData.title],
  );

  const validateStep2 = useCallback(
    () => newData.time_start && newData.time_finish,
    [newData.time_start, newData.time_finish],
  );

  useEffect(() => {
    if (newData) {
      let completed = {};

      completed[0] = validateStep1();
      completed[1] = validateStep2();
      setAllCompleted(validateStep1() && validateStep2());

      setCompleted(completed);
    }
  }, [newData, validateStep1, validateStep2]);

  useEffect(() => {
    setNewData(data ? data : {});
  }, [open, data]);

  return (
    <StepperDialog
      title={title}
      data={[
        {
          title: 'Введите название этапа',
          content: <Step1 {...newData} onTitleChange={onNewTitleChange} />,
        },
        {
          title: 'Выберите даты',
          content: (
            <Step2
              {...newData}
              onDateFromChange={onNewDateFromChange}
              onDateToChange={onNewDateToChange}
            />
          ),
        },
        {
          title: 'Введите вес этапа',
          content: <Step3 {...newData} onWeightChange={onNewWeightChange} />,
        },
      ]}
      completed={completed}
      allCompleted={allCompleted}
      open={open}
      onClose={onClose}
      onComplete={() => {
        onComplete(newData);
      }}
      rightElement={rightElement}
      submitButtonText={submitButtonText}
      isLoading={isLoading}
    />
  );
}

const Step1 = (props) => {
  const { title, onTitleChange } = props;

  return (
    <TextField
      multiline
      value={title}
      onChange={onTitleChange}
      style={{ width: '80%', maxWidth: 500 }}
      id="standard-basic"
      label="Введите название"
      variant="outlined"
    />
  );
};

const Step2 = (props) => {
  const { time_start, time_finish, onDateFromChange, onDateToChange } = props;

  return (
    <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
      <div style={{ width: '80%', maxWidth: 500 }}>
        <KeyboardDatePicker
          autoOk={true}
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          style={{ width: '100%' }}
          id="date-picker-inline"
          label="Дата начала"
          placeholder="Введите дату начала"
          value={time_start ? time_start : null}
          onChange={onDateFromChange}
          inputVariant="outlined"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          autoOk={true}
          style={{ width: '100%' }}
          id="date-picker-inline"
          label="Дата конца"
          placeholder="Введите дату конца"
          value={time_finish ? time_finish : null}
          onChange={onDateToChange}
          inputVariant="outlined"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

const Step3 = ({ weight, onWeightChange }) => {
  return (
    <PercentsInput
      textFieldProps={{
        label: 'Вес этапа',
        fullWidth: true,
        variant: 'outlined',
      }}
      maxWidth={500}
      value={weight}
      onChange={onWeightChange}
    />
  );
};
