import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  gradesWrapper: {
    width: '100%',
    padding: '0 10px',
  },
  gradesTitle: {
    padding: '10px 0 20px',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '18px',
  },
  gradeWrapper: {
    margin: '0 8px',

    '&:first-child': {
      margin: '0 8px 0 0',
    },

    '&:lsat-child': {
      margin: '0 0 0 8px',
    },
  },
  editableTextWrapper: {
    paddingTop: 18,
  },
  gradeDescriptionTextArea: {
    fontSize: 11,
  },
});
