import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

export default function TripleSlider (props) {
    const {data, title, onChange, value} = props;
    const {root} = useStyles();

    const [marks, setMarks] = useState([])

    function valueLabelFormat(value) {
        return marks.findIndex((mark) => mark.value === value) + 1;
    }

    const onValueChange = (event, newValue) => {
        onChange(data[newValue]?.value)
    }

    useEffect(()=> {
        setMarks(data.map((item, index) => ({...item, value: index})))
    },[])

    return (
        <div className={root}>
            <Typography id="discrete-slider" gutterBottom align='center'>{title}</Typography>
            <PrettoSlider
                defaultValue={0}
                value={data.findIndex((item, index) => item.value === value)}
                valueLabelFormat={valueLabelFormat}
                aria-labelledby="discrete-slider"
                valueLabelDisplay='off'
                step={null}
                marks={marks}
                max={marks.length-1}
                track={false}
                onChange={onValueChange}
            />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20
    }
}));

const PrettoSlider = withStyles({
    root: {
      color: '#4051B5',
      height: 8,
    },
    mark: {
        display: 'none'
    },
    markLabel:{
        marginTop: 5,
        fontSize: 15
    },
    markLabelActive: {
        fontWeight: 'bold',
        color: '#4051B5'
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -6,
      marginLeft: -10,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);