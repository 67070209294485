import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { getFile } from '../network/fetchApi';

export const COMPANIES = [
  {
    id: 'icon_link',
    label: 'Логотип',
    align: 'center',
    style: {
      width: '10%',
    },
    format: (params) => (
      <div
        style={{
          height: 80,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          style={{
            width: 'auto',
            height: 'auto',
            maxHeight: 80,
            maxWidth: '100%',
          }}
          src={getFile(params.value)}
        />
      </div>
    ),
  },
  {
    id: 'name',
    label: 'Название',
    align: 'left',
    style: {
      width: '90%',
      cursor: 'pointer',
    },
    orderBy: [
      {
        field: 'name',
      },
    ],
  },
];

export const CHAMPS = [
  {
    id: 'title',
    label: 'Название',
    align: 'left',
    style: {
      width: '60%',
      cursor: 'pointer',
    },
    orderBy: [
      {
        field: 'title',
      },
    ],
  },
  {
    id: 'time_start',
    label: 'Дата начала',
    align: 'left',
    style: {
      width: '20%',
    },
    format: (params) =>
      new Date(params.value).toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
  },
  {
    id: 'time_finish',
    label: 'Дата завершения',
    align: 'left',
    style: {
      width: '20%',
    },
    format: (params) =>
      new Date(params.value).toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
  },
];

export const STAGES = [
  {
    id: 'title',
    label: 'Название',
    align: 'left',
    style: {
      width: '55%',
      cursor: 'pointer',
    },
    orderBy: [
      {
        field: 'title',
      },
    ],
  },
  {
    id: 'weight',
    label: ({ data }) =>
      `Вес этапа, всего = ${(
        100 * data.reduce((acc, item) => acc + (item.weight || 0), 0)
      ).toFixed()}%`,
    align: 'left',
    style: {
      width: '15%',
    },
    format: (params) => `${(100 * params.value).toFixed()}%`,
  },
  {
    id: 'time_start',
    label: 'Дата начала',
    align: 'left',
    style: {
      width: '15%',
    },
    format: (params) =>
      new Date(params.value).toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
  },
  {
    id: 'time_finish',
    label: 'Дата конца',
    align: 'left',
    style: {
      width: '15%',
    },
    format: (params) =>
      new Date(params.value).toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
  },
];

export const CASES = [
  {
    id: 'number',
    label: 'Номер',
    align: 'left',
    style: {
      wordBreak: 'keep-all',
      cursor: 'pointer',
    },
    orderBy: [
      {
        field: 'number',
      },
    ],
  },
  {
    id: 'title',
    label: 'Название',
    align: 'left',
    style: {
      minWidth: 300,
    },
  },
  // {
  //     id: 'task_general',
  //     label: 'Общее условие',
  //     align: 'left',
  //     style: {
  //       minWidth: 300
  //     }
  // },
  // {
  //     id: 'task_player',
  //     label: 'Условие для участника',
  //     align: 'left',
  //     style: {
  //       minWidth: 300
  //     }
  // },
  // {
  //     id: 'task_client',
  //     label: 'Условие для клиента',
  //     align: 'left',
  //     style: {
  //       minWidth: 300
  //     }
  // },
];

export const NEWUSERS = [
  {
    id: 'last_name',
    label: 'Фамилия',
    align: 'left',
    style: {
      width: '15%',
    },
  },
  {
    id: 'first_name',
    label: 'Имя',
    align: 'left',
    style: {
      width: '15%',
    },
  },
  {
    id: 'middle_name',
    label: 'Отчество',
    align: 'left',
    style: {
      width: '15%',
    },
  },
  {
    id: 'email',
    label: 'Почта',
    align: 'left',
    style: {
      width: '25%',
    },
  },
  {
    id: 'registered',
    label: 'Зарегистрирован',
    align: 'center',
    format: (params) =>
      params.value ? (
        <CheckIcon style={{ color: 'green' }} />
      ) : (
        <CloseIcon style={{ color: 'red' }} />
      ),
  },
  {
    id: 'email_confirmed',
    label: 'Подтвержден',
    align: 'center',
    style: {
      cursor: 'pointer',
    },
    format: (params) =>
      params.value ? (
        <CheckIcon style={{ color: 'green' }} />
      ) : (
        <CloseIcon style={{ color: 'red' }} />
      ),
    orderBy: [
      {
        field: 'email_confirmed',
      },
    ],
  },
];

export const USERS = [
  {
    id: 'full_name',
    label: 'ФИО',
    align: 'left',
    style: {
      width: '40%',
      cursor: 'pointer',
    },
    format: (params) =>
      `${params.row['last_name']} ${params.row['first_name']} ${params.row['middle_name']}`,
    orderBy: [
      {
        field: 'last_name',
      },
      {
        field: 'first_name',
      },
      {
        field: 'middle_name',
      },
    ],
  },
  {
    id: 'chief',
    label: 'Руководитель',
    align: 'left',
    style: {
      width: '25%',
      cursor: 'pointer',
    },
    orderBy: [
      {
        field: 'chief',
      },
    ],
  },
  // {
  //     id: 'password',
  //     label: 'Пароль',
  //     align: 'left',
  //     style: {
  //       width: '10%',
  //     }
  // },
  {
    id: 'post',
    label: 'Должность',
    align: 'left',
    style: {
      width: '15%',
      cursor: 'pointer',
    },
    orderBy: [
      {
        field: 'post',
      },
    ],
  },
  // {
  //     id: 'boss',
  //     label: 'Руководитель',
  //     align: 'left',
  //     style: {
  //       width: '15%',
  //     }
  // },
  {
    id: 'team',
    label: 'Команда',
    align: 'left',
    style: {
      width: '20%',
      cursor: 'pointer',
    },
    orderBy: [
      {
        field: 'team',
      },
    ],
  },
  {
    id: 'email_confirmed',
    label: 'Подтвержден',
    align: 'center',
    style: {
      cursor: 'pointer',
    },
    format: (params) =>
      params.value ? (
        <CheckIcon style={{ color: 'green' }} />
      ) : (
        <CloseIcon style={{ color: 'red' }} />
      ),
    orderBy: [
      {
        field: 'email_confirmed',
      },
    ],
  },
  {
    id: 'is_player',
    label: 'Участник',
    align: 'center',
    style: {
      cursor: 'pointer',
    },
    format: (params) =>
      params.value ? (
        <CheckIcon style={{ color: 'green' }} />
      ) : (
        <span style={{ color: 'red' }}>Н/У</span>
      ),
    orderBy: [
      {
        field: 'is_player',
      },
    ],
  },
];

export const BATTLES = [
  {
    id: 'case_number',
    label: 'Номер кейса',
    style: {
      width: '20%',
      cursor: 'pointer',
    },
    orderBy: [
      {
        field: 'case_id',
      },
    ],
  },
  {
    id: 'client',
    label: 'Клиент',
    style: {
      cursor: 'pointer',
    },
    orderBy: [
      {
        field: 'client_last_name',
      },
      {
        field: 'client_first_name',
      },
      {
        field: 'client_middle_name',
      },
    ],
  },
];

export const ROLES = [
  {
    id: 'title',
    label: 'Название',
    align: 'left',
  },
];
