import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export default function UserDialog(props) {
    const {data, open, onClose, onEdit} = props;
    const {root, input} = useStyles();

    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth={'md'}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Об аккаунте"}</DialogTitle>
                <DialogContent>
                    <Grid className={root} container direction='column' spacing='1'>
                        <Grid item xs={12} spacing='5' style={{marginBottom: 20}}>
                            <TextField 
                                fullWidth
                                className={input}
                                value={`${data.last_name} ${data.first_name} ${data.middle_name}`}
                                label="ФИО" 
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                className={input}
                                value={data.email} 
                                label="Эл. почта" 
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                className={input}
                                value={data.password?data.password:""} 
                                label="Пароль" 
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                            />
                        </Grid>
                        <Grid item xs={12} spacing='5' style={{marginBottom: 20}}>
                            <TextField 
                                fullWidth
                                className={input}
                                value={data.email_confirmed?"Подтвержден":'Не подтвержден'} 
                                label="Статус" 
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                className={input}
                                value={data.post} 
                                label="Должность" 
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                className={input}
                                value={data.chief} 
                                label="Руководитель" 
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                className={input}
                                value={data.team} 
                                label="Команда" 
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{marginBottom: 20}}>
                        <TextField 
                            fullWidth
                            className={input}
                            value={data.is_player?'Да':'Нет'} 
                            label='Играет роль "Участник"' 
                            InputLabelProps={{
                                shrink: true,
                              }}
                        />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onEdit} color="primary">
                        Редактировать
                    </Button>
                    <Button onClick={onClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    root: {

    },
    input: {
    }
}));
