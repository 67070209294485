import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
  BattlesTable,
  DataTable,
  SearchInput,
  TabPanel,
} from '../../../components';
import { CASES, ROLES } from '../../../constants/headers';
import { useStyles } from '../styles';
import { mapStageRoleToColor } from '../const';
import { useSelector } from 'react-redux';
import {
  stageBattlesLoadingSelector,
  stageBattlesSelector,
  stageCasesLoadingSelector,
  stageCasesSelector,
  stageRolesLoadingSelector,
} from '../../../redux/selectors/stage.selecrtors';
import CompetenceTab from '../CompetenceTab';

const StageScreenTabs = ({
  stageBattlesHeaders,
  stageRoles,
  battlesSelected,
  toggleBattleSelection,
  onBattleDialogOpen,
  setStageBattleData,
  setStageCaseData,
  onEditStageCaseDialogOpen,
  setStageRoleData,
  onEditStageRoleDialogOpen,
  loadStageBattles,
  loadStageCases,
  loadStageRoles,
  stageId,
  isBattleEdited,
  setIsBattleEdited,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [battlesOrder, setBattlesOrder] = useState({});
  const [battlesFilters, setBattlesFilters] = useState([]);
  const [casesOrder, setCasesOrder] = useState({});
  const [casesFilters, setCasesFilters] = useState([]);
  const [rolesOrder, setRolesOrder] = useState({});

  const isLoadingStageBattles = useSelector(stageBattlesLoadingSelector);
  const stageBattles = useSelector(stageBattlesSelector);
  const isLoadingStageCases = useSelector(stageCasesLoadingSelector);
  const stageCases = useSelector(stageCasesSelector);
  const isLoadingStageRoles = useSelector(stageRolesLoadingSelector);

  React.useEffect(() => {
    console.log('stageBattles', stageBattles);
  }, [stageBattles]);

  const { tabs } = useStyles();

  const onBattleClick = (data, activeStep) => {
    setStageBattleData({ ...data, activeStep });
    onBattleDialogOpen();
  };

  const onChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const onCasesOrderChange = (order) => {
    setCasesOrder(order);
  };

  const onCasesFiltersChange = (filters) => {
    setCasesFilters(filters);
  };

  const onRolesOrderChange = (order) => {
    setRolesOrder(order);
  };

  const onBattlesOrderChange = (order) => {
    setBattlesOrder(order);
  };

  const onBattlesFiltersChange = (filters) => {
    setBattlesFilters(filters);
  };

  const openEditStageCaseDialog = (data) => {
    setStageCaseData(data);
    onEditStageCaseDialogOpen();
  };

  const openEditStageRoleDialog = (data) => {
    setStageRoleData(data);
    onEditStageRoleDialogOpen();
  };

  useEffect(() => {
    loadStageBattles({ ...battlesOrder, filters: battlesFilters });
  }, [battlesOrder, battlesFilters, loadStageBattles]);

  useEffect(() => {
    loadStageCases({ ...casesOrder, filters: casesFilters });
  }, [casesOrder, casesFilters, loadStageCases]);

  useEffect(() => {
    loadStageRoles({ ...rolesOrder });
  }, [rolesOrder, loadStageRoles]);

  return (
    <>
      <Grid item xs>
        <Tabs
          scrollButtons="auto"
          variant="scrollable"
          className={tabs}
          value={tabValue}
          onChange={onChangeTab}
        >
          <Tab
            label="Список упражнений"
            id="tab-0"
            aria-controls="tabpanel-0"
          />
          <Tab label="Список кейсов" id="tab-1" aria-controls="tabpanel-1" />
          <Tab label="Список ролей" id="tab-2" aria-controls="tabpanel-2" />
          <Tab
            label="Настройка компетенций"
            id="tab-3"
            aria-controls="tabpanel-3"
          />
        </Tabs>
      </Grid>
      <Grid item xs>
        <TabPanel value={tabValue} index={0}>
          <SearchInput
            onFiltersChange={onBattlesFiltersChange}
            fields={[
              { title: 'По фамилии Участника', value: 'user_last_name' },
              { title: 'По имени Участника', value: 'user_first_name' },
              { title: 'По отчеству Участника', value: 'user_middle_name' },
              { title: 'По номеру кейса', value: 'case_number' },
              { title: 'По фамилии Клиента', value: 'client_last_name' },
              { title: 'По имени Клиента', value: 'client_first_name' },
              { title: 'По отчеству Клиента', value: 'client_middle_name' },
              {
                title: 'По фамилии Внутреннего эксперта',
                value: 'experts_internal_last_name',
              },
              {
                title: 'По имени Внутреннего эксперта',
                value: 'experts_internal_first_name',
              },
              {
                title: 'По отчеству Внутреннего эксперта',
                value: 'experts_internal_middle_name',
              },
              {
                title: 'По фамилии Внешнего эксперта',
                value: 'experts_external_last_name',
              },
              {
                title: 'По имени Внешнего эксперта',
                value: 'experts_external_first_name',
              },
              {
                title: 'По отчеству Внешнего эксперта',
                value: 'experts_external_middle_name',
              },
            ]}
          />
          <BattlesTable
            initialOrder={[
              { field: 'user_last_name', order: 'asc' },
              { field: 'user_first_name', order: 'asc' },
              { field: 'user_middle_name', order: 'asc' },
            ]}
            onOrderChange={onBattlesOrderChange}
            isLoading={isLoadingStageBattles}
            headers={stageBattlesHeaders.filter(
              (header) => header.code_name === '',
            )}
            data={stageBattles}
            onCellClick={onBattleClick}
            selectedBattles={battlesSelected}
            toggleBattle={toggleBattleSelection}
            isBattleEdited={isBattleEdited}
            setIsBattleEdited={setIsBattleEdited}
            colors={mapStageRoleToColor(stageRoles?.items ?? [])}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <SearchInput
            onFiltersChange={onCasesFiltersChange}
            fields={[{ title: 'По названию', value: 'title' }]}
          />
          <DataTable
            initialOrder={[{ field: 'number', order: 'asc' }]}
            onOrderChange={onCasesOrderChange}
            isLoading={isLoadingStageCases}
            headers={CASES}
            data={stageCases}
            onRowClick={openEditStageCaseDialog}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <DataTable
            initialOrder={[{ field: 'title', order: 'asc' }]}
            onOrderChange={onRolesOrderChange}
            isLoading={isLoadingStageRoles}
            headers={ROLES}
            data={stageRoles}
            onRowClick={openEditStageRoleDialog}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <CompetenceTab stageId={stageId} />
        </TabPanel>
      </Grid>
    </>
  );
};

export default StageScreenTabs;
