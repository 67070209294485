import React, { useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';

const calculateTextSize = (text, styles) => {
  const tmp = document.createElement('span');
  tmp.style = { ...tmp.style, ...styles };
  tmp.textContent = text;
  document.body.appendChild(tmp);
  const { width, height } = tmp.getBoundingClientRect();
  document.body.removeChild(tmp);
  return { width, height };
};

const VariableWidthInput = ({ className, value, onChange }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const { width } = calculateTextSize(value || '0', inputRef.current.style);
      if (width) {
        inputRef.current.style.width = `${20 + width}px`;
      }
    }
  }, [value]);

  return (
    <TextField
      type="number"
      inputRef={inputRef}
      InputProps={{ disableUnderline: true }}
      value={value}
      onChange={onChange}
      className={className}
    />
  );
};

export default VariableWidthInput;
