import React, { forwardRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import { TabPanel, EditBattle, ResultBattle, DeleteButton, Loader } from '.';
import { getBattleFull } from '../network/fetchApi';
import { sortHeaders } from './BattlesTable';

export default function BattleDialog(props) {
  const { headerRoles, data, open, onClose, onDelete, onComplete, isLoading } =
    props;
  const { appBar, header, tabs } = useStyles();

  const [tabValue, setTabValue] = useState(0);
  const [newData, setNewData] = useState();

  const loadBattleDataFull = () => {
    if (data.battle && data.battle.id) {
      const params = {
        order_by: [
          {
            field: 'time',
            order: 'asc',
          },
        ],
        limit: 100,
      };
      getBattleFull({ id: data.battle.id }, params, (responseJson) => {
        if (responseJson.status == 200) {
          const {
            player,
            client,
            experts_internal,
            experts_external,
            battle,
            video,
            championship,
          } = responseJson.body;
          const body = {
            rights: battle.rights,
            roles: battle.roles.map((role) => ({
              ...role,
              users: role.user_info,
            })),
            stageCase: responseJson.body.case,
            player,
            client,
            experts_internal,
            experts_external,
            battle,
            video,
            championship,
          };
          setNewData(body);
        }
      });
    } else {
      setNewData(data);
    }
  };

  const onChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    loadBattleDataFull();
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Tabs
            scrollButtons="auto"
            variant="scrollable"
            className={tabs}
            value={tabValue}
            onChange={onChangeTab}
          >
            <Tab
              label="Редактировать упражнение"
              id="tab-0"
              aria-controls="tabpanel-0"
            />
            <Tab
              label="Результаты упражнения"
              id="tab-1"
              aria-controls="tabpanel-1"
            />
          </Tabs>
          <div style={{ flex: 1 }} />
          <DeleteButton onClick={onDelete} />
        </Toolbar>
      </AppBar>
      <TabPanel style={{ height: '100%' }} value={tabValue} index={0}>
        <EditBattle
          newData={{ ...newData, ...data }}
          setNewData={setNewData}
          headerRoles={[...headerRoles].sort(sortHeaders)}
          defaultActiveStep={data.activeStep}
          open={open}
          submitButtonText={'Сохранить'}
          onComplete={onComplete}
          isLoading={isLoading}
        />
      </TabPanel>
      <TabPanel
        style={{ height: '100%', overflow: 'auto' }}
        value={tabValue}
        index={1}
      >
        <ResultBattle data={data} />
      </TabPanel>
    </Dialog>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  header: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  tabs: {
    margin: theme.spacing(2),
  },
}));
