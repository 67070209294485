import React, { useMemo, useCallback } from 'react';
import Button from '@material-ui/core/Button';

import { ColoredCircle } from '../../../../components';
import CriterionCard from './CriterionCard';
import { addCriterion, removeCriterion, setCompetenceField } from '../reducer';
import { useStyles } from '../styles';
import { useEditCriterion } from './hooks/useEditCriterion';

const Criteria = ({ criteria, competenceDispatch }) => {
  const styles = useStyles();

  const { editingCriterion, editCriterion, stopEditingCriterion } =
    useEditCriterion();

  const criteriaWeightsSum = useMemo(
    () =>
      Object.values(criteria).reduce(
        (accum, criterion) => accum + criterion.weight,
        0,
      ),
    [criteria],
  );

  const handleAddCriterionClick = () => competenceDispatch(addCriterion());

  const handleRemoveCriterion = useCallback(
    (localCriterionId) => {
      competenceDispatch(removeCriterion(localCriterionId));
    },
    [competenceDispatch],
  );

  const handleUpdateCriterion = useCallback(
    (fields, data) => competenceDispatch(setCompetenceField(fields, data)),
    [competenceDispatch],
  );

  return (
    <>
      <div className={styles.competenceHeaderWrapper} style={{ maxWidth: 490 }}>
        <div className={styles.competenceHeader}>Критерии оценки</div>
        <ColoredCircle
          minValueColor="#FF7575"
          maxValueColor="#80D856"
          percent={criteriaWeightsSum}
        />
      </div>
      <div className={styles.competenceContainer} style={{ maxWidth: 490 }}>
        {Object.entries(criteria)
          .sort(
            ([, a], [, b]) =>
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime(),
          )
          .map(([localCriterionId, criterion]) => (
            <CriterionCard
              criterion={criterion}
              key={localCriterionId}
              isEditing={editingCriterion(localCriterionId)}
              editCriterion={editCriterion}
              stopEditingCriterion={stopEditingCriterion}
              localCriterionId={localCriterionId}
              updateCriterion={handleUpdateCriterion}
              removeCriterion={handleRemoveCriterion}
            />
          ))}
        <div
          className={styles.totalWeight}
          style={{
            color:
              criteriaWeightsSum > 0.999 && criteriaWeightsSum <= 1
                ? 'green'
                : criteriaWeightsSum > 1
                ? '#f01b11'
                : 'inherit',
          }}
        >
          Общий вес: {Math.round(criteriaWeightsSum * 100)}%
        </div>
        {criteriaWeightsSum > 1 && (
          <div className={styles.warning}>
            Общий вес критериев не должен превышать 100%
          </div>
        )}
      </div>
      <div style={{ maxWidth: 490 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCriterionClick}
        >
          Добавить компетенции
        </Button>
      </div>
    </>
  );
};

export default Criteria;
