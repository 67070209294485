import React, { forwardRef, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';

import { NEWUSERS } from '../constants/headers';
import {
  parseExcel,
  checkUsers,
  registerUsers,
  updateUsersInfo,
  addChampUsers,
} from '../network/fetchApi';

import { Loader, TripleSlider } from '.';

export default function NewUserDialog(props) {
  const { params, open, onClose, onComplete } = props;
  const {
    root,
    appBar,
    header,
    backButton,
    content,
    buttonsContainer,
    stepContent,
  } = useStyles();

  const [completed, setCompleted] = useState({});

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [regUsers, setRegUsers] = useState([]);
  const [champUsers, setChampUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const addNewChampUsers = () => {
    setIsLoading(true);
    addChampUsers(
      {
        id: params?.champ_id,
        data: champUsers.map((item) => ({
          id: item.id,
          is_player: item.is_player !== '',
        })),
      },
      (responseJson) => {
        setIsLoading(true);
        if (responseJson.status == 200) {
          onComplete();
        }
      },
    );
  };

  const addNewUsers = () => {
    const newUsers = [];
    const oldUsers = [];

    regUsers.forEach((item) => {
      const userItem = { ...item };
      delete userItem.is_player;
      delete userItem.registered;
      delete userItem.email_confirmed;

      if (item.registered) {
        oldUsers.push(userItem);
      } else {
        newUsers.push(userItem);
      }
    });

    setIsLoading(true);
    let canNextStep = true;
    updateUsersInfo(oldUsers, (responseJson) => {
      if (responseJson.status !== 200) {
        alert('Не удалось обновить данные пользователей');
        canNextStep = false;
      }
      registerUsers(newUsers, (responseJson) => {
        setIsLoading(false);
        if (responseJson.status !== 200) {
          alert('Не удалось зарегистрировать новых пользователей');
        } else if (canNextStep) {
          handleNext();
        }
      });
    });
  };

  const getUsersJsonFromExcel = (e) => {
    let reader = new FileReader();
    setIsLoading(true);
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      const body = {
        row: 1,
        cell: 0,
        fields: [
          'email',
          'last_name',
          'first_name',
          'middle_name',
          'post',
          'chief',
          'team',
          'is_player',
        ],
        in: reader.result.split(',')[1],
      };
      parseExcel(body, (responseJson) => {
        setIsLoading(false);
        if (responseJson.status == 200) {
          const newUsers = responseJson.body.map((item) => ({
            ...item,
            email: item.email?.trim(),
          }));
          onUsersChange(newUsers);
        }
      });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStep = (index) => {
    if (index < data.length) return data[index];
    else return 'Unknown stepIndex';
  };

  const onChampUsersChange = (users) => {
    setChampUsers(users);
  };

  const onRegUsersChange = (users) => {
    setRegUsers(users);
  };

  const onUsersChange = (users) => {
    setUsers(users);
  };

  const onUserChange = (user) => {
    setUser((prev) => ({ ...prev, ...user }));
  };

  const validateStep1 = () => {
    return (
      (users && users.length > 0) || (user && user.email && user.email !== '')
    );
  };

  const validateStep2 = () => {
    return regUsers && regUsers.length > 0;
  };

  const validateStep3 = () => {
    return champUsers && champUsers.length > 0;
  };

  useEffect(() => {
    let completed = {};

    completed[0] = validateStep1();
    completed[1] = validateStep2();
    completed[2] = validateStep3();

    setCompleted(completed);
  }, [users, user, regUsers, champUsers]);

  useEffect(() => {
    if (open) {
      setUsers([]);
      setUser(null);
      setRegUsers([]);
      setChampUsers([]);
      setIsLoading(false);
      setActiveStep(0);
    }
  }, [open]);

  const data = [
    {
      title: 'Загрузка данных аккаунтов',
      content: (
        <StepInput
          user={user}
          users={users}
          getUsersJsonFromExcel={getUsersJsonFromExcel}
          onUsersChange={onUsersChange}
          onUserChange={onUserChange}
        />
      ),
      button: {
        title: 'Далее',
        handler: handleNext,
      },
    },
    {
      title: 'Регистрация аккаунтов',
      content: (
        <StepReg
          users={users.concat(
            user && user.email && user.email !== '' ? [user] : [],
          )}
          onRegUsersChange={onRegUsersChange}
          onUsersChange={onUsersChange}
        />
      ),
      button: {
        title: 'Зарегистрировать',
        handler: addNewUsers,
      },
    },
    {
      title: 'Регистрация на чемпионат',
      content: (
        <StepAddToChamp
          users={users.concat(
            user && user.email && user.email !== '' ? [user] : [],
          )}
          onChampUsersChange={onChampUsersChange}
          onUsersChange={onUsersChange}
        />
      ),
      button: {
        title: 'Добавить',
        handler: addNewChampUsers,
      },
    },
  ];

  return (
    <Dialog
      className={root}
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={header}>
            Добавить аккаунты
          </Typography>
        </Toolbar>
      </AppBar>
      <Stepper
        style={{ overflow: 'auto' }}
        nonLinear
        activeStep={activeStep}
        alternativeLabel={true}
      >
        {data.map((step, index) => (
          <Step key={index}>
            <StepButton disabled={!completed[0]} onClick={handleStep(index)}>
              {step.title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div className={content}>
        <div className={stepContent}>{getStep(activeStep).content}</div>
        <div className={buttonsContainer}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={backButton}
          >
            Назад
          </Button>
          <Button
            disabled={!completed[activeStep]}
            variant="contained"
            color="primary"
            onClick={getStep(activeStep).button.handler}
          >
            {getStep(activeStep).button.title}
          </Button>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </Dialog>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StepInput = (props) => {
  const { users, user, getUsersJsonFromExcel, onUserChange } = props;
  const { inputContainer, input } = useStyles();
  const fileInputRef = useRef();

  const onEmailChange = (event) => {
    onUserChange({ email: event.target.value.trim() });
  };

  const onNameChange = (event) => {
    onUserChange({ first_name: event.target.value });
  };

  const onLastNameChange = (event) => {
    onUserChange({ last_name: event.target.value });
  };

  const onMiddleNameChange = (event) => {
    onUserChange({ middle_name: event.target.value });
  };

  const onJobChange = (event) => {
    onUserChange({ post: event.target.value });
  };

  const onBossChange = (event) => {
    onUserChange({ chief: event.target.value });
  };

  const onTeamChange = (event) => {
    onUserChange({ team: event.target.value });
  };

  const onIsPlayerChange = (value) => {
    onUserChange({ is_player: value });
  };

  return (
    <Grid className={inputContainer} container direction="column" spacing="1">
      <Grid item xs={12}>
        <input
          accept=".xlsx"
          ref={fileInputRef}
          type="file"
          hidden
          onChange={getUsersJsonFromExcel}
        />
        <Button
          onClick={() => fileInputRef.current.click()}
          style={{ width: '100%' }}
          color={users.length === 0 ? 'primary' : 'secondary'}
          variant="contained"
          startIcon={users.length === 0 ? <GetAppIcon /> : <RefreshIcon />}
        >
          {users.length === 0
            ? 'Загрузить файл Excel'
            : 'Загрузить новый файл Excel'}
        </Button>
      </Grid>
      <Typography align="center" style={{ marginTop: 20 }}>
        или
      </Typography>
      <Grid item xs={12}>
        <TextField
          required
          value={user?.email}
          onChange={onEmailChange}
          className={input}
          id="standard-basic"
          label="Введите эл. почту"
        />
      </Grid>
      <Grid container item xs={12} spacing="1">
        <Grid item xs={12} sm>
          <TextField
            value={user?.last_name}
            onChange={onLastNameChange}
            className={input}
            id="standard-basic"
            label="Введите фамилию"
          />
        </Grid>
        <Grid item xs={12} sm>
          <TextField
            value={user?.first_name}
            onChange={onNameChange}
            className={input}
            id="standard-basic"
            label="Введите имя"
          />
        </Grid>
        <Grid item xs={12} sm>
          <TextField
            value={user?.middle_name}
            onChange={onMiddleNameChange}
            className={input}
            id="standard-basic"
            label="Введите отчество"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={user?.post}
          onChange={onJobChange}
          className={input}
          id="standard-basic"
          label="Введите должность"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={user?.chief}
          onChange={onBossChange}
          className={input}
          id="standard-basic"
          label="Введите ФИО руководителя"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={user?.team}
          onChange={onTeamChange}
          className={input}
          id="standard-basic"
          label="Введите название Команды"
        />
      </Grid>
      <Grid item xs={12}>
        <TripleSlider
          title={'Играет роль "Участник" в данном чемпионате:'}
          onChange={onIsPlayerChange}
          value={user?.is_player}
          data={[
            {
              value: '',
              label: 'Нет',
            },
            {
              value: 'у',
              label: 'Да',
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

const StepReg = (props) => {
  const { users, onRegUsersChange } = props;
  const { toolbar, highlight, title } = useStyles();

  const [selected, setSelected] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState([]);

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(checkedUsers);
    } else setSelected([]);
  };

  const handleClick = (value) => {
    setSelected((prev) => {
      const newSelected = prev.concat();
      const index = prev.findIndex((item) => item.email === value.email);
      index === -1 ? newSelected.push(value) : newSelected.splice(index, 1);
      return newSelected;
    });
  };

  useEffect(() => {
    onRegUsersChange(
      selected.map((item) => {
        const prevUserData = users.find((user) => user.email === item.email);
        return {
          ...item,
          ...(prevUserData || {}),
        };
      }),
    );
  }, [selected]);

  useEffect(() => {
    checkUsers(users, (responseJson) => {
      setCheckedUsers(responseJson.body.items);
    });
  }, []);

  return (
    <Paper
      style={{
        width: '100%',
        maxHeight: 400,
        flex: 1,
        overflow: 'auto',
        margin: 50,
      }}
    >
      <Toolbar className={toolbar}>
        <Typography
          className={title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {selected.length > 0
            ? `Выбрано: ${selected.length}`
            : 'Добавить/обновить данные аккаунтов'}
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < checkedUsers.length
                  }
                  checked={
                    checkedUsers.length > 0 &&
                    selected.length === checkedUsers.length
                  }
                  onChange={onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </TableCell>
              {NEWUSERS.map((headCell) => (
                <TableCell key={headCell.id} align={'left'}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {checkedUsers.map((row, index) => {
              const user = users.find((user) => user.email === row.email);

              const isItemSelected =
                selected.findIndex((item) => item.email === row.email) !== -1;
              return (
                <TableRow
                  hover
                  key={row.id}
                  onClick={(event) => handleClick(row)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.name}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': row.id }}
                    />
                  </TableCell>
                  {NEWUSERS.map((header) => {
                    const value = row[header.id];
                    const inputValue = user ? user[header.id] : undefined;
                    const valueChanged =
                      inputValue !== undefined && inputValue !== value;
                    const nextValue = valueChanged
                      ? value + ` --> ${inputValue}`
                      : value;

                    console.log(
                      'values',
                      value,
                      inputValue,
                      valueChanged,
                      nextValue,
                    );
                    return (
                      <TableCell
                        style={{
                          wordBreak: 'break-word',
                          ...header.style,
                          backgroundColor: valueChanged ? 'orange' : 'white',
                        }}
                        key={header.id}
                        align={header.align}
                      >
                        {header.format
                          ? header.format({ value: nextValue, row })
                          : nextValue}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const StepAddToChamp = (props) => {
  const { users, onChampUsersChange } = props;
  const { toolbar, highlight, title } = useStyles();

  const [selected, setSelected] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState(users);

  console.log('checked', checkedUsers);

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(checkedUsers);
    } else setSelected([]);
  };

  const handleClick = (value) => {
    setSelected((prev) => {
      const newSelected = prev.concat();
      const index = prev.findIndex((item) => item.id === value.id);
      index === -1 ? newSelected.push(value) : newSelected.splice(index, 1);
      return newSelected;
    });
  };

  useEffect(() => {
    onChampUsersChange(selected);
  }, [selected]);

  useEffect(() => {
    checkUsers(users, (responseJson) => {
      const newChecked = responseJson.body.items.filter(
        (user) => user.registered,
      );

      setCheckedUsers(
        newChecked.map((u) => {
          const dataUser = users.find((us) => us.email === u.email);

          if (dataUser) {
            return {
              ...u,
              is_player: dataUser.is_player ?? '',
            };
          }

          return u;
        }),
      );
    });
  }, []);

  return (
    <Paper
      style={{
        width: '100%',
        maxHeight: 400,
        flex: 1,
        overflow: 'auto',
        margin: 50,
      }}
    >
      <Toolbar className={toolbar}>
        <Typography
          className={title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {selected.length > 0
            ? `Выбрано: ${selected.length}`
            : 'Добавить аккаунты на чемпионат'}
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table
          // style={{width: '100%'}}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < checkedUsers.length
                  }
                  checked={
                    checkedUsers.length > 0 &&
                    selected.length === checkedUsers.length
                  }
                  onChange={onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </TableCell>
              {NEWUSERS.map((headCell) => (
                <TableCell key={headCell.id} align={'left'}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {checkedUsers.map((row, index) => {
              const isItemSelected =
                selected.findIndex((item) => item.id === row.id) !== -1;
              return (
                <TableRow
                  hover
                  key={row.id}
                  onClick={(event) => handleClick(row)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.name}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': row.id }}
                    />
                  </TableCell>
                  {NEWUSERS.map((header) => {
                    const value = row[header.id];
                    return (
                      <TableCell
                        style={{ wordBreak: 'break-word', ...header.style }}
                        key={header.id}
                        align={header.align}
                      >
                        {header.format ? header.format({ value, row }) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    position: 'relative',
  },
  appBar: {
    position: 'relative',
  },
  header: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  content: {
    height: '100%',
    margin: theme.spacing(5),
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepContent: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputContainer: {
    width: '80%',
    maxWidth: 600,
  },
  input: {
    width: '100%',
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  title: {
    flex: '1 1 100%',
  },
}));
