import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import {
  LoginScreen,
  CompaniesScreen,
  CompanyScreen,
  ChampScreen,
  StageScreen,
  CasesScreen,
} from './screens';
import { AppBarMenu } from './components';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LoginScreen />
        </Route>
        <AppBarMenu>
          <Route path="/companies">
            <CompaniesScreen />
          </Route>
          <Route exact path="/company">
            <CompanyScreen />
          </Route>
          <Route exact path="/company/champ">
            <ChampScreen />
          </Route>
          <Route exact path="/company/champ/stage">
            <StageScreen />
          </Route>
          <Route path="/cases">
            <CasesScreen />
          </Route>
        </AppBarMenu>
      </Switch>
    </Router>
  );
}

export default App;
