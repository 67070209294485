import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export default function BreadCrumbs(props) {
  const { data } = props;
  const { root } = useStyles();

  return (
    <div className={root}>
      {data && (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {data.map((link, index) => {
            if (index == data.length - 1) {
              return (
                <Typography key={index} color="textPrimary">
                  {link.name}
                </Typography>
              );
            } else {
              return (
                <Link key={index} color="inherit" href={link.href}>
                  {link.name}
                </Link>
              );
            }
          })}
        </Breadcrumbs>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));
