import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addBattle,
  addCase,
  addStageRoles,
  deleteCase,
  getBattleList,
  getCaseList,
  getChamp,
  getStage,
  getStageRoleList,
  updateBattle,
  updateCase,
  updateStage,
  updateStageRoles,
  deleteStageRole as deleteStageRoleAPICall,
  deleteBattle,
  deleteStage as deleteStageAPICall,
  generateStageBattles as generateStageBattlesAPICall,
} from '../../network/fetchApi';

const initialState = {
  data: {},
  loading: false,
  error: null,
  autoSendInites: null,
  editing: false,
  battles: {
    data: {
      items: [],
      hits: 0,
    },
    headers: [],
    loading: false,
    error: null,
  },
  battleAction: {
    loading: false,
    error: null,
  },
  cases: {
    data: {
      items: [],
      hits: 0,
    },
    loading: false,
    error: null,
  },
  caseAction: {
    loading: false,
    error: null,
  },
  competenceAction: {
    loading: false,
    error: null
  },
  roles: {
    data: {
      items: [
        {
          id: 'айдишник',
          title: 'Название моей роли',
          rights: {
            //права доступа
            write: true,
            comments: 'self',
            video: {
              upload: true,
              watch: true,
            },
          },
        },
      ],
      hits: 0,
    },
    loading: false,
    error: null,
  },
  roleAction: {
    loading: false,
    error: null,
  },
};

const SOMETHING_WENT_WRONG = 'Что-то пошло не так';

export const loadStageData = createAsyncThunk(
  'stage/loadStageData',
  ({ id }) =>
    new Promise((resolve, reject) => {
      getStage({ id }, (stageRes) => {
        if (!stageRes.ok) return reject(SOMETHING_WENT_WRONG);
        const stageData = stageRes.body;
        getChamp({ id: stageRes.body.championship_id }, (champRes) => {
          if (!champRes.ok) return reject(SOMETHING_WENT_WRONG);
          stageData.company_id = champRes.body.company_id;
          resolve(stageData);
        });
      });
    }),
);

export const editStage = createAsyncThunk(
  'stage/editStage',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      updateStage(data, (updateStageResp) => {
        if (!updateStageResp.ok) return reject(SOMETHING_WENT_WRONG);
        resolve(updateStageResp.body);
        success && success();
      });
    }),
);

export const loadStageBattles = createAsyncThunk(
  'stage/loadStageBattles',
  ({ params }) =>
    new Promise((resolve, reject) => {
      getBattleList(params, (battleListRes) => {
        if (!battleListRes.ok) return reject(SOMETHING_WENT_WRONG);
        const { items: list, headers, hits } = battleListRes.body;
        resolve({ list, headers, hits });
      });
    }),
);

export const deleteStage = createAsyncThunk(
  'stage/deleteStage',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      deleteStageAPICall(data, (deleteStageResp) => {
        if (!deleteStageResp.ok) return reject(SOMETHING_WENT_WRONG);
        success && success();
        resolve();
      });
    }),
);

export const addStageBattle = createAsyncThunk(
  'stage/addStageBattle',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      console.log('data: ', data);
      addBattle(data, (addStageBattleResp) => {
        if (!addStageBattleResp.ok) return reject(SOMETHING_WENT_WRONG);
        success && success();
        resolve();
      });
    }),
);

export const editStageBattle = createAsyncThunk(
  'stage/editStageBattle',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      updateBattle(data, (updateBattleResp) => {
        if (!updateBattleResp.ok) return reject(SOMETHING_WENT_WRONG);
        resolve();
        success && success();
      });
    }),
);

export const deleteStageBattle = createAsyncThunk(
  'stage/deleteStageBattle',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      deleteBattle(data, (deleteBattleResp) => {
        if (!deleteBattleResp.ok) return reject(SOMETHING_WENT_WRONG);
        resolve();
        success && success();
      });
    }),
);

export const generateStageBattles = createAsyncThunk(
  'stage/generateStageBattles',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      generateStageBattlesAPICall(data, (generateBattlesResp) => {
        if (!generateBattlesResp.ok) return reject(SOMETHING_WENT_WRONG);
        resolve();
        success && success();
      });
    }),
);

export const loadStageCases = createAsyncThunk(
  'stage/loadStageCases',
  ({ params }) =>
    new Promise((resolve, reject) => {
      getCaseList(params, (casesResp) => {
        if (!casesResp.ok) reject(SOMETHING_WENT_WRONG);
        resolve(casesResp.body);
      });
    }),
);

export const addStageCase = createAsyncThunk(
  'stage/addStageCase',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      addCase(data, (addCaseResp) => {
        if (!addCaseResp.ok) return reject(SOMETHING_WENT_WRONG);
        success && success();
        resolve(addCaseResp.body);
      });
    }),
);

export const editStageCase = createAsyncThunk(
  'stage/editStageCase',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      updateCase(data, (updateCaseResp) => {
        if (!updateCaseResp.ok) return reject(SOMETHING_WENT_WRONG);
        resolve(updateCaseResp.body);
        success && success();
      });
    }),
);

export const deleteStageCase = createAsyncThunk(
  'stage/deleteStageCase',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      deleteCase(data, (deleteCaseResp) => {
        if (!deleteCaseResp.ok) return reject(SOMETHING_WENT_WRONG);
        resolve();
        success && success();
      });
    }),
);

export const loadStageRoles = createAsyncThunk(
  'stage/loadStageRoles',
  ({ id }) =>
    new Promise((resolve, reject) => {
      getStageRoleList({ id }, (roleListRes) => {
        if (!roleListRes.ok) return reject(SOMETHING_WENT_WRONG);
        resolve(roleListRes.body);
      });
    }),
);

export const addStageRole = createAsyncThunk(
  'stage/addStageRole',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      addStageRoles(data, (addStageRoleResp) => {
        if (!addStageRoleResp.ok) return reject(SOMETHING_WENT_WRONG);
        success && success();
        resolve();
      });
    }),
);

export const editStageRole = createAsyncThunk(
  'stage/editStageRole',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      updateStageRoles(data, (updateStageRoleResp) => {
        if (!updateStageRoleResp.ok) return reject(SOMETHING_WENT_WRONG);
        resolve();
        success && success();
      });
    }),
);

export const deleteStageRole = createAsyncThunk(
  'stage/deleteStageRole',
  ({ data, success }) =>
    new Promise((resolve, reject) => {
      deleteStageRoleAPICall(data, (deleteStageRoleResp) => {
        if (!deleteStageRoleResp.ok) return reject(SOMETHING_WENT_WRONG);
        resolve();
        success && success();
      });
    }),
);

// TODO: Split this slice into several slices
const stateSlice = createSlice({
  name: 'stage',
  initialState,
  reducers: {
    changeAutoSendInites: (state, action) => {
      state.autoSendInites = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Load stage
    builder.addCase(loadStageData.pending, (state) => {
      state.data = {};
      state.loading = false;
      state.error = null;
    });
    builder.addCase(loadStageData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.data.autoSendInites = action.payload.send_mails;
      state.autoSendInites = action.payload.send_mails;
    });
    builder.addCase(loadStageData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Edit stage
    builder.addCase(editStage.pending, (state) => {
      state.editing = false;
    });
    builder.addCase(editStage.fulfilled, (state, action) => {
      state.editing = false;
      state.data = action.payload;
    });
    builder.addCase(editStage.rejected, (state) => {
      state.editing = false;
    });
    // Delete stage
    builder.addCase(deleteStage.pending, (state) => {
      state.editing = false;
    });
    builder.addCase(deleteStage.fulfilled, (state) => {
      state.editing = false;
    });
    builder.addCase(deleteStage.rejected, (state) => {
      state.editing = false;
    });
    // Load stage battles
    builder.addCase(loadStageBattles.fulfilled, (state, action) => {
      state.battles.loading = false;
      state.battles.data = {
        items: action.payload.list,
        hits: action.payload.hits,
      };
      state.battles.headers = action.payload.headers;
    });
    builder.addCase(loadStageBattles.rejected, (state, action) => {
      state.battles.loading = false;
      state.battles.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Add stage battle
    builder.addCase(addStageBattle.pending, (state) => {
      state.battleAction.loading = true;
      state.battleAction.error = null;
    });
    builder.addCase(addStageBattle.fulfilled, (state) => {
      state.battleAction.loading = false;
    });
    builder.addCase(addStageBattle.rejected, (state, action) => {
      state.battleAction.loading = false;
      state.battleAction.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Edit stage battle
    builder.addCase(editStageBattle.pending, (state) => {
      state.battleAction.loading = true;
      state.battleAction.error = null;
    });
    builder.addCase(editStageBattle.fulfilled, (state) => {
      state.battleAction.loading = false;
    });
    builder.addCase(editStageBattle.rejected, (state, action) => {
      state.battleAction.loading = false;
      state.battleAction.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Delete stage battle
    builder.addCase(deleteStageBattle.pending, (state) => {
      state.battleAction.loading = true;
      state.battleAction.error = null;
    });
    builder.addCase(deleteStageBattle.fulfilled, (state) => {
      state.battleAction.loading = false;
    });
    builder.addCase(deleteStageBattle.rejected, (state, action) => {
      state.battleAction.loading = false;
      state.battleAction.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Generate stage battles
    builder.addCase(generateStageBattles.pending, (state) => {
      state.battleAction.loading = true;
      state.battleAction.error = null;
    });
    builder.addCase(generateStageBattles.fulfilled, (state) => {
      state.battleAction.loading = false;
    });
    builder.addCase(generateStageBattles.rejected, (state, action) => {
      state.battleAction.loading = false;
      state.battleAction.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Load stage cases
    builder.addCase(loadStageCases.pending, (state) => {
      state.cases.loading = true;
      state.cases.error = null;
    });
    builder.addCase(loadStageCases.fulfilled, (state, action) => {
      state.cases.loading = false;
      state.cases.data = action.payload;
    });
    builder.addCase(loadStageCases.rejected, (state, action) => {
      state.cases.loading = false;
      state.cases.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Add stage case
    builder.addCase(addStageCase.pending, (state) => {
      state.caseAction.loading = true;
      state.caseAction.error = null;
    });
    builder.addCase(addStageCase.fulfilled, (state, action) => {
      state.caseAction.loading = false;
      state.cases.data.items.push(action.payload);
    });
    builder.addCase(addStageCase.rejected, (state, action) => {
      state.caseAction.loading = false;
      state.caseAction.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Edit stage case
    builder.addCase(editStageCase.pending, (state) => {
      state.caseAction.loading = true;
      state.caseAction.error = null;
    });
    builder.addCase(editStageCase.fulfilled, (state, action) => {
      state.caseAction.loading = false;
      const caseIndex = state.cases.data.items.findIndex(
        (stageCase) => stageCase.id === action.payload.id,
      );
      if (caseIndex !== -1) state.cases.data.items[caseIndex] = action.payload;
    });
    builder.addCase(editStageCase.rejected, (state, action) => {
      state.caseAction.loading = false;
      state.caseAction.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Delete stage case
    builder.addCase(deleteStageCase.pending, (state) => {
      state.caseAction.loading = true;
      state.caseAction.error = null;
    });
    builder.addCase(deleteStageCase.fulfilled, (state) => {
      state.caseAction.loading = false;
    });
    builder.addCase(deleteStageCase.rejected, (state, action) => {
      state.caseAction.loading = false;
      state.caseAction.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Load stage roles
    builder.addCase(loadStageRoles.pending, (state) => {
      state.roles.loading = true;
      state.cases.error = null;
    });
    builder.addCase(loadStageRoles.fulfilled, (state, action) => {
      state.roles.loading = false;
      state.roles.data = action.payload;
    });
    builder.addCase(loadStageRoles.rejected, (state, action) => {
      state.roles.loading = false;
      state.roles.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Add stage role
    builder.addCase(addStageRole.pending, (state) => {
      state.roleAction.loading = true;
      state.roleAction.error = null;
    });
    builder.addCase(addStageRole.fulfilled, (state) => {
      state.roleAction.loading = false;
    });
    builder.addCase(addStageRole.rejected, (state, action) => {
      state.roleAction.loading = false;
      state.roleAction.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Edit stage role
    builder.addCase(editStageRole.pending, (state) => {
      state.roleAction.loading = true;
      state.roleAction.error = null;
    });
    builder.addCase(editStageRole.fulfilled, (state) => {
      state.roleAction.loading = false;
    });
    builder.addCase(editStageRole.rejected, (state, action) => {
      state.roleAction.loading = false;
      state.roleAction.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
    // Delete stage role
    builder.addCase(deleteStageRole.pending, (state) => {
      state.roleAction.loading = true;
      state.roleAction.error = null;
    });
    builder.addCase(deleteStageRole.fulfilled, (state) => {
      state.roleAction.loading = false;
    });
    builder.addCase(deleteStageRole.rejected, (state, action) => {
      state.roleAction.loading = false;
      state.roleAction.error = action.error?.message || SOMETHING_WENT_WRONG;
    });
  },
});

export const { changeAutoSendInites } = stateSlice.actions;

export default stateSlice.reducer;
