import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const hexToRGB = (hexStr) => {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexStr)) return { r: 0, g: 0, b: 0 };

  let splitStrArr = hexStr.substring(1).split('');
  if (splitStrArr.length === 3)
    splitStrArr = [
      splitStrArr[0],
      splitStrArr[0],
      splitStrArr[1],
      splitStrArr[1],
      splitStrArr[2],
      splitStrArr[2],
    ];
  const splitStr = '0x' + splitStrArr.join('');
  return {
    r: (splitStr >> 16) & 255,
    g: (splitStr >> 8) & 255,
    b: splitStr & 255,
  };
};

const ColoredCircle = ({
  minValueColor,
  maxValueColor,
  percent,
  style = {},
}) => {
  const styles = useStyles();

  const backgroundColor = useMemo(() => {
    const correctedPercent = (() => {
      if (percent > 1) return 1;
      if (percent < 0) return 0;
      return percent;
    })();
    const rgbaMin = hexToRGB(minValueColor);
    const rgbaMax = hexToRGB(maxValueColor);
    const red = (rgbaMax.r - rgbaMin.r) * correctedPercent + rgbaMin.r;
    const green = (rgbaMax.g - rgbaMin.g) * correctedPercent + rgbaMin.g;
    const blue = (rgbaMax.b - rgbaMin.b) * correctedPercent + rgbaMin.b;
    return `rgba(${red},${green},${blue},1)`;
  }, [minValueColor, maxValueColor, percent]);

  return (
    <div
      className={styles.coloredCircle}
      style={{ backgroundColor, ...style }}
    ></div>
  );
};

const useStyles = makeStyles({
  coloredCircle: {
    width: 18,
    height: 18,
    borderRadius: '100%',
    transition: "background-color 0.5s ease"
  },
});

export default ColoredCircle;
