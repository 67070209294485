import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const getElemSelectionData = (elem) => ({
  selectionStart: elem.selectionStart,
  selectionEnd: elem.selectionEnd,
});

const PercentsInput = ({
  value,
  inputProps,
  textFieldProps,
  onChange,
  maxWidth = 100,
}) => {
  const [innerValue, setInnerValue] = useState(
    isNaN(value) ? '0%' : `${100 * value}%`,
  );

  const styles = useStyles();

  const inputRef = useRef(null);
  const handleChange = (event) => {
    if (!onChange) return;

    const value = event.target.value;
    const regex = /^(?<number>[0-9]*)%(?<numberAfterPercent>[0-9]?)$/gu;
    const regexResult = regex.exec(value);
    if (!regexResult) return;
    const parsedValue = parseInt(
      regexResult.groups.number + regexResult.groups.numberAfterPercent,
    );

    const isParsedNaN = isNaN(parsedValue);
    if (isParsedNaN) {
      setInnerValue('0%');
      onChange(0);
      return;
    }

    const correctedParsed = (
      parsedValue > 100
        ? parsedValue - Math.floor(parsedValue / 100) * 100
        : parsedValue
    ).toFixed();
    setInnerValue(`${correctedParsed}%`);
    onChange(correctedParsed / 100);
  };

  const handleKeyDown = (event) => {
    if (!(event.target && event.target.value)) return;

    switch (event.key) {
      case 'ArrowDown':
        (() => {
          event.preventDefault();
          const { selectionStart } = getElemSelectionData(event.target);
          const lastPos = event.target.value.length - 1;
          event.target.setSelectionRange(
            event.shiftKey ? selectionStart : lastPos,
            lastPos,
          );
        })();
        break;
      case 'ArrowRight':
        (() => {
          const { selectionStart, selectionEnd } = getElemSelectionData(
            event.target,
          );
          const length = event.target.value.length;
          if (selectionEnd + 1 >= length) {
            event.preventDefault();
            event.target.setSelectionRange(
              selectionEnd + 1 === length ? selectionEnd : selectionStart,
              selectionEnd,
            );
          }
        })();
    }
  };

  const handleClick = (event) => {
    if (!(event.target && event.target.value)) return;
    const { selectionStart, selectionEnd } = getElemSelectionData(event.target);
    const length = event.target.value.length;
    if (selectionEnd >= length)
      event.target.setSelectionRange(selectionStart, length - 1);
  };

  const handleFocus = (event) => {
    event.persist();
    setTimeout(() => {
      if (!(event.target && event.target.value)) return;
      const { selectionStart, selectionEnd } = getElemSelectionData(
        event.target,
      );
      const length = event.target.value.length;
      if (selectionEnd >= length)
        event.target.setSelectionRange(selectionStart, length - 1);
    });
  };

  useEffect(() => {
    const newCaretPos = innerValue.length - 1;
    if (inputRef.current && inputRef.current.selectionEnd >= newCaretPos) {
      inputRef.current.setSelectionRange(newCaretPos, newCaretPos);
    }
  }, [innerValue]);

  useEffect(() => {
    setInnerValue(isNaN(value) ? '0%' : `${(100 * value).toFixed()}%`);
  }, [value]);

  return (
    <TextField
      type="text"
      inputRef={inputRef}
      value={innerValue}
      className={styles.percentsInput}
      onChange={handleChange}
      onClick={handleClick}
      style={{ maxWidth }}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      InputProps={{
        ...inputProps,
        ...(!(textFieldProps && textFieldProps.variant === 'outline') && {
          disableUnderline: true,
        }),
      }}
      {...textFieldProps}
    />
  );
};

const useStyles = makeStyles({
  percentsInput: {
    backgroundColor: 'rgba(252, 252, 252, 1)',
    borderRadius: 6,
  },
});

export default PercentsInput;
