import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { CirclePicker } from 'react-color';

import { StepperDialog, TripleSlider } from '.';

export default function NewRoleDialog(props) {
  const {
    data,
    title,
    open,
    onClose,
    rightElement,
    submitButtonText,
    isLoading,
    onComplete,
  } = props;
  const { root } = useStyles();

  const [allCompleted, setAllCompleted] = useState(false);
  const [completed, setCompleted] = useState({});

  const [newData, setNewData] = useState({});

  const onNewTitleChange = (event) => {
    setNewData({ ...newData, title: event.target.value });
  };

  const onNewColorChange = (color) => {
    setNewData({
      ...newData,
      graph_color: color?.hex,
    });
  };

  const onRightCommentsViewChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        comments: value,
      },
    });
  };

  const onRightCommentsWriteChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        write: value,
      },
    });
  };

  const onRightVideoViewChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        watch: value,
      },
    });
  };

  const onRightVideoUploadChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        upload: value,
      },
    });
  };

  const onRightGradesGiveChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        give_grades: value,
      },
    });
  };

  const onRightGradesSeeChange = (value) => {
    setNewData({
      ...newData,
      rights: {
        ...newData?.rights,
        see_grades: value,
      },
    });
  };

  const validateStep1 = () => {
    return newData.title && newData.title.length > 0;
  };

  useEffect(() => {
    if (newData) {
      let completed = {};

      completed[0] = validateStep1();
      setAllCompleted(validateStep1());

      setCompleted(completed);
    }
  }, [newData]);

  useEffect(() => {
    setNewData(
      data
        ? data
        : {
            rights: {
              comments: 'none',
              write: false,
              watch: false,
              upload: false,
              give_grades: false,
              see_grades: 'no',
            },
          },
    );
  }, [open]);

  return (
    <StepperDialog
      title={title + (newData.title ? `: ${newData.title}` : '')}
      data={[
        {
          title: 'Название роли',
          content: <StepTitle {...newData} onTitleChange={onNewTitleChange} />,
        },
        {
          title: 'Права доступа роли',
          content: (
            <StepRights
              {...newData}
              onRightCommentsViewChange={onRightCommentsViewChange}
              onRightCommentsWriteChange={onRightCommentsWriteChange}
              onRightVideoViewChange={onRightVideoViewChange}
              onRightVideoUploadChange={onRightVideoUploadChange}
              onRightGradesGiveChange={onRightGradesGiveChange}
              onRightGradesSeeChange={onRightGradesSeeChange}
            />
          ),
        },
        {
          title: 'Цвет роли',
          content: <StepColor {...newData} onColorChange={onNewColorChange} />,
        },
      ]}
      completed={completed}
      submitButtonText={submitButtonText}
      allCompleted={allCompleted}
      open={open}
      onClose={onClose}
      rightElement={Boolean(data?.code_name) ? undefined : rightElement}
      onComplete={() => {
        onComplete(newData);
      }}
      isLoading={isLoading}
    />
  );
}

const StepTitle = (props) => {
  const { title, onTitleChange } = props;

  return (
    <TextField
      value={title}
      onChange={onTitleChange}
      style={{ width: '80%', maxWidth: 500 }}
      multiline
      rowsMax={10}
      id="standard-basic"
      label="Введите название"
      variant="outlined"
    />
  );
};

const StepColor = (props) => {
  const { graph_color, onColorChange } = props;

  return (
    <CirclePicker onChange={onColorChange} color={graph_color ?? undefined} />
  );
};

const StepRights = (props) => {
  const {
    rights,
    onRightCommentsViewChange,
    onRightCommentsWriteChange,
    onRightVideoViewChange,
    onRightVideoUploadChange,
    onRightGradesGiveChange,
    onRightGradesSeeChange,
  } = props;

  return (
    <div>
      <TripleSlider
        title={'Какие комментарии доступны для просмотра:'}
        onChange={onRightCommentsViewChange}
        value={rights?.comments}
        data={[
          {
            value: 'none',
            label: 'Никакие',
          },
          {
            value: 'self',
            label: 'Только свои',
          },
          {
            value: 'all',
            label: 'Все',
          },
        ]}
      />
      <TripleSlider
        title={'Оставлять и редактировать свои комментарии:'}
        onChange={onRightCommentsWriteChange}
        value={rights?.write}
        data={[
          {
            value: false,
            label: 'Нет',
          },
          {
            value: true,
            label: 'Да',
          },
        ]}
      />
      <TripleSlider
        title={'Загружать видео на упражнение:'}
        onChange={onRightVideoUploadChange}
        value={rights?.upload}
        data={[
          {
            value: false,
            label: 'Нет',
          },
          {
            value: true,
            label: 'Да',
          },
        ]}
      />
      <TripleSlider
        title={'Просматривать видео упражнений:'}
        onChange={onRightVideoViewChange}
        value={rights?.watch}
        data={[
          {
            value: false,
            label: 'Нет',
          },
          {
            value: true,
            label: 'Да',
          },
        ]}
      />
      <TripleSlider
        title={'Ставить оценки:'}
        onChange={onRightGradesGiveChange}
        value={rights?.give_grades}
        data={[
          {
            value: false,
            label: 'Нет',
          },
          {
            value: true,
            label: 'Да',
          },
        ]}
      />
      <TripleSlider
        title={'Видеть оценки:'}
        onChange={onRightGradesSeeChange}
        value={rights?.see_grades}
        data={[
          {
            value: 'no',
            label: 'Нет',
          },
          {
            value: 'self',
            label: 'Только свои',
          },
          {
            value: 'yes',
            label: 'Да',
          },
        ]}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    maxWidth: 800,
  },
  input: {
    width: '100%',
  },
  formControl: {
    marginTop: 10,
  },
}));
