import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom";
import useQuery from '../utils/useQuery'

import {BreadCrumbs, CompanyProfile, DataTable, NewChampDialog, NewCompanyDialog, 
  TabPanel, DeleteButton, ConfirmActionDialog, SearchInput} from '../components'
import {getCompany, updateCompany, deleteCompany, addChamp, getChampList} from '../network/fetchApi'
import {CHAMPS} from '../constants/headers'

export default function CompanyScreen() {
    const {root, header, fab1, fab2, tabs} = useStyles();
    const query = useQuery();
    const history = useHistory();

    const [isLoadingCompanyData, setIsLoadingCompanyData] = useState(true);
    const [isLoadingCompanyChamps, setIsLoadingCompanyChamps] = useState(true);
    const [isEditCompanyLoading, setIsEditCompanyLoading] = useState(false);
    const [isNewChampLoading, setIsNewChampLoading] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const [companyChamps, setCompanyChamps] = useState([]);
    const [openNewChampDialog, setOpenNewChampDialog] = useState(false);
    const [openNewCompanyDialog, setOpenNewCompanyDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [champsOrder, setChampsOrder] = useState({})
    const [champsFilters, setChampsFilters] = useState([])

    const loadCompanyByID = (id) => {
      setIsLoadingCompanyData(true)
      getCompany({id}, (responseJSON) => {
        setIsLoadingCompanyData(false)
        if(responseJSON.status == 200){
          setCompanyData(responseJSON.body)
        }
      })
    }

    const onCompleteEditCompany = (data) => {
      setIsEditCompanyLoading(true)
      updateCompany(data, (responseJson) => {
        setIsEditCompanyLoading(false)
        if(responseJson.status == 200){
          setCompanyData(responseJson.body)
          onNewCompanyDialogClose()
        }
      })
    }
    
    const onDeleteCompany = () => {
      setIsEditCompanyLoading(true)
      deleteCompany(companyData,(responseJson) => {
        setIsEditCompanyLoading(false)
        if(responseJson.status == 200){
          history.push(`/companies`)
        }
      })
    }

    const loadCompanyChamps = (params = {}) => {
      setIsLoadingCompanyChamps(true)
      params.filters = [...params.filters, {
        field: 'company_id',
        equal: query.get("id")
      }]
      getChampList(params, (responseJson) => {
        setIsLoadingCompanyChamps(false)
        if(responseJson.status == 200){
          setCompanyChamps(responseJson.body)
        }
      })
    }

    const onCompleteNewChamp = (data) => {
      setIsNewChampLoading(true)
      addChamp({...data, company_id: companyData.id}, (responseJson) => {
        setIsNewChampLoading(false)
        if(responseJson.status == 200){
          setCompanyChamps({...companyChamps, items: [responseJson.body, ...companyChamps.items]})
          onNewChampDialogClose()
        }
      })
    }

    const onChangeTab = (event, newValue) => {
      setTabValue(newValue);
    };

    const onConfirmDialogOpen = () => {
      setOpenConfirmDialog(true);
    };

    const onConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
    };

    const onNewChampDialogOpen = () => {
        setOpenNewChampDialog(true);
    };
  
    const onNewChampDialogClose = () => {
        setOpenNewChampDialog(false);
    };

    const onNewCompanyDialogOpen = () => {
      setOpenNewCompanyDialog(true);
    };
  
    const onNewCompanyDialogClose = () => {
      setOpenNewCompanyDialog(false);
    };

    const onChampClick = (data) => {
      history.push(`/company/champ?id=${data.id}`)
    }

    useEffect(() => {
      const companyID = query.get("id");
      loadCompanyByID(companyID)
    }, [])

    const onChampsOrderChange = (order) => {
      setChampsOrder(order)
    }
  
    const onChampsFiltersChange = (filters) => {
      setChampsFilters(filters)
    }
  
    useEffect(() => {
      loadCompanyChamps({...champsOrder, filters: champsFilters})
    }, [champsOrder, champsFilters])

    return (
      <div className={root}>
          <BreadCrumbs data={[{name: "Список компаний", href: '/companies'}, {name: "Компания"}]}/>
          <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                  <Typography variant="h5" className={header}>О компании</Typography>
                  <CompanyProfile data={companyData} isLoading={isLoadingCompanyData}/>
              </Grid>
              <Grid container item xs={12} direction={'column'}>
                  <Grid item xs>
                      <Tabs className={tabs} value={tabValue} onChange={onChangeTab}>
                          <Tab label="Список чемпионатов" id='tab-0' aria-controls='tabpanel-0'/>
                      </Tabs>
                  </Grid>
                  <Grid item xs>
                      <TabPanel value={tabValue} index={0}>
                        <SearchInput  onFiltersChange={onChampsFiltersChange} fields={[{title: "По названию", value: "title"}]}/>
                        <DataTable initialOrder={[{field: 'title', order: 'asc'}]} onOrderChange={onChampsOrderChange} isLoading={isLoadingCompanyChamps} headers={CHAMPS} data={companyChamps} onRowClick={onChampClick}/>
                      </TabPanel>
                  </Grid>
              </Grid>
          </Grid>
          <Fab className={fab2} color="primary" aria-label="add" onClick={onNewChampDialogOpen}>
              <AddIcon />
          </Fab>
          <Fab className={fab1} color="primary" aria-label="add" onClick={onNewCompanyDialogOpen}>
              <EditIcon />
          </Fab>
          <NewChampDialog 
            title={"Новый чемпионат"} 
            open={openNewChampDialog} 
            onClose={onNewChampDialogClose}
            onComplete={onCompleteNewChamp}
            isLoading={isNewChampLoading}
          />
          <NewCompanyDialog 
            title="Редактировать компанию" 
            data={companyData} 
            rightElement={<DeleteButton onClick={onConfirmDialogOpen}/>}
            submitButtonText="Сохранить"
            open={openNewCompanyDialog} onClose={onNewCompanyDialogClose}
            onComplete={onCompleteEditCompany}
            isLoading={isEditCompanyLoading}
          />
          <ConfirmActionDialog
            title="Удалить компанию?"
            text="Вы уверены, что хотите удалить все данные о компании?"
            open={openConfirmDialog}
            onClose={onConfirmDialogClose}
            onConfirm={onDeleteCompany}
          />
      </div>
    );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  fab1: {
    zIndex: 100,
    position: 'fixed',
    bottom: 85,
    right: theme.spacing(2),
  },
  fab2: {
    zIndex: 100,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  tabs: {
    marginBottom: theme.spacing(2)
  },
}));
