import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';

import {CASES} from '../constants/headers'
import {TabPanel, DataTable, DeleteButton,
    NewCaseDialog, ConfirmActionDialog} from '../components'
import {getCaseList, addCase, updateCase, deleteCase} from '../network/fetchApi'

export default function CasesScreen() {
    const {root, tabs, fab} = useStyles();

    const [isLoadingCases, setIsLoadingCases] = useState(false);
    const [isCaseLoading, setIsCaseLoading] = useState(false);
    const [openConfirmCaseDialog, setOpenConfirmCaseDialog] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [openNewCaseDialog, setOpenNewCaseDialog] = useState(false);
    const [openEditCaseDialog, setOpenEditCaseDialog] = useState(false);
    const [cases, setCases] = useState([]);
    const [caseData, setCaseData] = useState({});

    const loadCases = (params) => {
        setIsLoadingCases(true)
        getCaseList(params, (responseJson) => {
            setIsLoadingCases(false)
            if(responseJson.status == 200) {
                setCases(responseJson.body)
            }
        })
    }

    const onCompleteNewCase = (data) => {
        setIsCaseLoading(true)
        addCase(data, (responseJson) => {
            setIsCaseLoading(false)
            if(responseJson.status == 200) {
                setCases({...cases, items: [responseJson.body, ...cases.items]})
                onNewCaseDialogClose()
            }
        })
    }

    const onCompleteEditCase = (data) => {
        setIsCaseLoading(true)
        updateCase(data, (responseJson) => {
            setIsCaseLoading(false)
            if(responseJson.status == 200) {
                setCases({...cases, items: cases.items.map((item) => item.id == responseJson.body.id?responseJson.body:item)})
                onEditCaseDialogClose()
            }
        })
    }

    const onDeleteCase = () => {
        setIsCaseLoading(true)
        deleteCase(caseData, (responseJson) => {
            setIsCaseLoading(false)
            if(responseJson.status == 200){
                loadCases()
                onConfirmCaseDialogClose()
                onEditCaseDialogClose()
            }
        })
    }

    const onConfirmCaseDialogClose = () => {
        setOpenConfirmCaseDialog(false);
    };

    const onConfirmCaseDialogOpen = () => {
        setOpenConfirmCaseDialog(true);
    };

    const onNewCaseDialogOpen = () => {
        setOpenNewCaseDialog(true);
      };
    
    const onNewCaseDialogClose = () => {
        setOpenNewCaseDialog(false);
    };

    const onEditCaseDialogOpen = (data) => {
        console.log("onEditCaseDialogOpen: " + JSON.stringify(data))
        setCaseData(data)
        setOpenEditCaseDialog(true);
      };
    
    const onEditCaseDialogClose = () => {
        setOpenEditCaseDialog(false);
    };
      
    const onChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div className={root}>
            <Grid container spacing={3}>
                <Grid container item xs={12} direction={'column'}>
                    <Grid item xs>
                        <Tabs scrollButtons="auto" variant="scrollable" className={tabs} value={tabValue} onChange={onChangeTab}>
                            <Tab label="Список кейсов" id='tab-0' aria-controls='tabpanel-0'/>
                        </Tabs>
                    </Grid>
                    <Grid item xs>
                        <TabPanel value={tabValue} index={0}>
                            <DataTable isLoading={isLoadingCases} headers={CASES} data={cases} onLoadData={loadCases} onRowClick={onEditCaseDialogOpen}/>
                        </TabPanel>
                    </Grid>
                </Grid>
            </Grid>                
            <Fab className={fab} color="primary" aria-label="add" onClick={onNewCaseDialogOpen}>
                <AddIcon />
            </Fab>
            <NewCaseDialog 
                title={'Новый кейс'} 
                open={openNewCaseDialog} 
                onClose={onNewCaseDialogClose}
                onComplete={onCompleteNewCase}
                isLoading={isCaseLoading}
            />
            <NewCaseDialog 
                title={'Редактировать кейс'} 
                submitButtonText='Сохранить'
                data={caseData} 
                rightElement={<DeleteButton onClick={onConfirmCaseDialogOpen}/>}
                open={openEditCaseDialog} onClose={onEditCaseDialogClose}
                onComplete={onCompleteEditCase}
                isLoading={isCaseLoading}
            />
            <ConfirmActionDialog
                title="Удалить кейс?"
                text="Вы уверены, что хотите удалить все данные о кейсе?"
                open={openConfirmCaseDialog}
                onClose={onConfirmCaseDialogClose}
                onConfirm={onDeleteCase}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    header: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2)
    },
    fab1: {
        position: 'fixed',
        bottom: 85,
        right: theme.spacing(2),
    },
    fab: {
        zIndex: 100,
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    tabs: {
        marginBottom: theme.spacing(2)
    },
}));
