import { v4 as uuidv4 } from 'uuid';

export const competenceInitialState = {
  criteria_to_delete: [],
  criteria: {},
  id: '',
  inner_expert_weight: 0,
  max_grade: 0,
  min_grade: 0,
  outer_expert_weight: 0,
  participant_weight: 0,
  stage_id: '',
  roles_with_weights: [],
};

const SET_COMPETENCE_DATA = 'SET_COMPETENCE_DATA';
const SET_COMPETENCE_FIELD = 'SET_COMPETENCE_FIELD';
const SET_COMPETENCE_GRADE = 'SET_COMPETENCE_GRADE';
const ADD_CRITERION = 'ADD_CRITERION';
const REMOVE_CRITERION = 'REMOVE_CRITERION';
const SET_ROLE_WEIGHT = 'SET_ROLE_WEIGHT';

export const setCompetenceData = (payload) => ({
  type: SET_COMPETENCE_DATA,
  payload,
});

export const setCompetenceField = (fields, value) => ({
  type: SET_COMPETENCE_FIELD,
  fields,
  value,
});

export const setCompetenceGrade = ({ minGrade, maxGrade }) => ({
  type: SET_COMPETENCE_GRADE,
  minGrade,
  maxGrade,
});

export const addCriterion = () => ({
  type: ADD_CRITERION,
});

export const removeCriterion = (tempId) => ({
  type: REMOVE_CRITERION,
  tempId,
});

export const setRoleWeight = (roleId, weight) => ({
  type: SET_ROLE_WEIGHT,
  roleId,
  weight,
});

export const competenceReducer = (state = competenceInitialState, action) => {
  switch (action.type) {
    case SET_COMPETENCE_DATA:
      return (() => {
        const criteria = action.payload.criteria || [];
        return {
          ...action.payload,
          criteria: criteria.reduce(
            (acc, criterion) => ({ ...acc, [uuidv4()]: criterion }),
            {},
          ),
          criteria_to_delete: [],
        };
      })();
    case SET_COMPETENCE_FIELD:
      return (() => {
        const newState = JSON.parse(JSON.stringify(state));
        let stateValue = newState;
        for (let i = 0; i < action.fields.length - 1; ++i) {
          stateValue = stateValue[action.fields[i]];
        }
        const lastField = action.fields[action.fields.length - 1];
        stateValue[lastField] = action.value;

        return newState;
      })();
    case SET_COMPETENCE_GRADE:
      return (() => {
        const newMinGrade = action.minGrade || state.min_grade;
        const newMaxGrade = action.maxGrade || state.max_grade;
        return {
          ...state,
          min_grade: newMinGrade,
          max_grade: newMaxGrade,
          criteria: Object.fromEntries(
            Object.entries(state.criteria).map(
              ([localCriterionId, criterion]) => [
                localCriterionId,
                {
                  ...criterion,
                  grades: Array.from({
                    length: newMaxGrade - newMinGrade + 1,
                  }).map((_, index) => {
                    const grade = newMinGrade + index;
                    const valuesInGrades = criterion.grades.find(
                      (gradeData) => gradeData.grade === grade,
                    );
                    return (
                      valuesInGrades || {
                        grade: grade,
                        description: '',
                      }
                    );
                  }),
                },
              ],
            ),
          ),
        };
      })();
    case ADD_CRITERION:
      return {
        ...state,
        criteria: (() => {
          const criteriaMap = new Map(Object.entries(state.criteria));
          criteriaMap.set(uuidv4(), {
            grades: Array.from({
              length: state.max_grade - state.min_grade + 1,
            }).map((_, index) => ({
              grade: state.min_grade + index,
              description: '',
            })),
            name: '',
            weight: 0,
          });
          return Object.fromEntries(criteriaMap);
        })(),
      };
    case REMOVE_CRITERION:
      return {
        ...state,
        ...(state.criteria[action.tempId].id && {
          criteria_to_delete: [
            ...state.criteria_to_delete,
            state.criteria[action.tempId].id,
          ],
        }),
        criteria: (() => {
          const criteriaMap = new Map(Object.entries(state.criteria));
          criteriaMap.delete(action.tempId);
          return Object.fromEntries(criteriaMap);
        })(),
      };
    case SET_ROLE_WEIGHT:
      return {
        ...state,
        roles_with_weights: state.roles_with_weights.map((role) =>
          role.id === action.roleId ? { ...role, weight: action.weight } : role,
        ),
      };
    default:
      return state;
  }
};
