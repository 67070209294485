import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  roleWeightContainer: {
    boxShadow: '0 0 6px -4px grey',
    borderRadius: 6,
    padding: 5.5,
    boxSizing: 'border-box',
    backgroundColor: 'rgba(252, 252, 252, 1)',
    display: 'flex',
    flexDirection: 'column',
  },
  roleNameWrapper: {
    backgroundColor: '#fff',
    borderRadius: 6,
    padding: '4px 7px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  warning: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#f01b11',
    marginTop: '8px',
  },
  totalWeight: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: '8px',
  },
  roleWeightWrapper: {
    display: 'flex',
    width: '100%',
    padding: '5px 0',

    '&:first-child': {
      padding: 0,
    },
    '&:last-child': {
      padding: 0,
    },
  },
});
