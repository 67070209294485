import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

export default function DeleteButton(props) {
    const {onClick} = props;

    return (
        <Button color='inherit' onClick={onClick} endIcon={<DeleteIcon/>}>
            Удалить 
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({

}));
