import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";
import React, { useMemo } from 'react';

const SymbolCircle = ({ symbol, selected,  className, size = 28, onClick = () => {} }) => {
  const styles = useStyles();
  
  const internalClassName = useMemo(() => {
    if (selected)
      return `${styles.symbolCircle} ${styles.symbolCircleSelected}`;
      
    return styles.symbolCircle;
  }, [selected, styles]);

  const handleClick = () => onClick(symbol);

  return (
    <div
      className={classNames(
          internalClassName,
          className
      )}
      onClick={handleClick}
      style={{ width: size, height: size, lineHeight: `${size}px` }}
    >
      {symbol}
    </div>
  );
};

const useStyles = makeStyles({
  symbolCircle: {
    backgroundColor: '#bcbcbc',
    color: '#fff',
    borderRadius: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'Roboto, sans-serif',

    '&:hover': {
      backgroundColor: '#888888',
    },
  },
  symbolCircleSelected: {
    backgroundColor: 'green',

    '&:hover': {
      backgroundColor: 'green',
    },
  },
});

export default SymbolCircle;
