import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import BattleAPI from '../network/BattleAPI';
import { useSwitch } from '../utils/hooks/useSwitch';
import GradesPanel from './GradesPanel';
import GradesViewOnlyModal from './GradesViewOnlyModal';

import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';

import { CommentItem, CommentsMenu, ConfirmActionDialog, EditCommentDialog, Loader, LoaderProgress, VideoHistoryDialog } from '.';
import {
    addBattleComment,
    addBattleVideo, createVimeoVideo,
    deleteAllComment,
    deleteComment,
    getBattleFull,
    getEmailsCSV,
    getVideoCSV, inviteStageBattle,
    resendEmails,
    updateComment
} from '../network/fetchApi';
import EmailResultDialog from './EmailResultDialog';

export default function ResultBattle(props) {
    const { data } = props;
    const { root } = useStyles();
    var player = useRef(null)
    const fileInputRef = useRef();

    const [
      gradesViewOnlyModalOpen,
      openGradesViewOnlyModal,
      closeGradesViewOnlyModal,
    ] = useSwitch();

    const [openEditComment, setOpenEditComment] = useState(false);
    const [openConfirmCommentDialog, setOpenConfirmCommentDialog] = useState(false);
    const [openConfirmAllCommentsDialog, setOpenConfirmAllCommentsDialog] = useState(false);
    const [openConfirmBattleInvite, setOpenConfirmBattleInvite] = useState(false);
    const [openVideoHistoryDialog, setOpenVideoHistoryDialog] = useState(false);
    const [isVideoLoading, setIsVideoLoading] = useState(false);
    const [uploadVideoProgress, setUploadVideoProgress] = useState(0);
    const [commentEditData, setCommentEditData] = useState({});
    const [battleData, setBattleData] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [commentText, setCommentText] = useState("");
    const [playedSeconds, setCurTime] = useState(0);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [emailResult, setEmailResult] = useState()
    const [gradesData, setGradesData] = useState(null);


    const battleGradesByCriterion = useMemo(() => 
             
        gradesData?.average_grades_by_criteria
          ?.map(({ criterion_name, average_grade }) => {
            return {
              criterion_name,
              average_grade
            };
          }) ?? []
      
    , [gradesData?.average_grades_by_criteria]);
  
    const loadBattleByID = async (id, params = {}) => {
      try {
        const response = await BattleAPI.getBattleFull(id, params);
        if (response.status === 200) {
          setGradesData(response.body);
        }
      } catch (error) {
        console.log("Error while getting Battle data: ", error)
      }
    };

    const OnEmailResultClosed = () => {
        setEmailResult(undefined)
    }

    const onEmailsCSVDownload = (campaignId) => {
        getEmailsCSV(campaignId)
    }


    
    const onRepeat = (campaignId) => {
        setEmailResult(undefined)
        resendEmails(campaignId, (responseJson) => {
            if (responseJson.status == 200) {
                setEmailResult(responseJson.body)
                // onInfoDialogOpen("Приглашения на упражнение успешно отправлены!")
            }
        })
    }

    const loadBattleDataFull = () => {
        if (data.battle && data.battle.id) {
            const params = {
                order_by: [{
                    field: 'time',
                    order: 'asc'
                }],
                limit: 100
            }
            getBattleFull({ id: data.battle.id }, params, (responseJson) => {
                if (responseJson.status == 200) {          
                    const { player, client, experts_internal, experts_external, battle, video } = responseJson.body;
                    const body = {
                        ...data,
                        stageCase: responseJson.body.case,
                        player,
                        client,
                        experts_internal,
                        experts_external,
                        battle,
                        video
                    }
                    setBattleData(body)
                }
            })
        }
    }

    const downloadCommentsCSV = () => {
        getVideoCSV({ id: battleData.video.id })
    }

    const onCommentSend = () => {
        if (commentText != "") {
            const body = {
                id: battleData.battle.id,
                text: commentText,
                time: Math.floor(playedSeconds)
            }
            addBattleComment(body, (responseJson) => {
                if (responseJson.status == 200) {
                    playVideo()
                    setCommentText("")
                    setBattleData({
                        ...battleData,
                        video: {
                            ...battleData.video,
                            comments: {
                                items: [
                                    ...battleData.video.comments.items,
                                    responseJson.body
                                ]
                            }
                        }
                    })
                }
            })
        }
    }

    const onEditCommentComplete = (data) => {
        updateComment(data, (responseJson) => {
            if (responseJson.status == 200) {
                onEditCommentClose()
                setBattleData({
                    ...battleData,
                    video: {
                        ...battleData.video,
                        comments: {
                            items: [
                                ...battleData.video.comments.items.map((item) => item.id == responseJson.body.id ? responseJson.body : item)
                            ]
                        }
                    }
                })
            }
        })
    }

    const onDeleteComment = () => {
        deleteComment(commentEditData, (responseJson) => {
            if (responseJson.status == 200) {
                onConfirmCommentDialogClose()
                onEditCommentClose()
                if (battleData.video && battleData.video.comments && battleData.video.comments.items) {
                    setBattleData({
                        ...battleData,
                        video: {
                            ...battleData.video,
                            comments: {
                                items: battleData.video.comments.items.filter(item => item.id !== commentEditData.id)
                            }
                        }
                    })
                }
            }
        })
    }

    const onDeleteAllComments = () => {
        deleteAllComment({ id: battleData.battle.id }, (responseJson) => {
            if (responseJson.status == 200) {
                onConfirmAllCommentsDialogClose()
                if (battleData.video && battleData.video.comments && battleData.video.comments.items) {
                    setBattleData({
                        ...battleData,
                        video: {
                            ...battleData.video,
                            comments: {
                                items: []
                            }
                        }
                    })
                }
            }
        })
        console.log("delete all comments")
    }

    const onUploadNewVideo = (e) => {
        const file = e.target.files[0]
        if (file != null) {
            setIsVideoLoading(true)
            createVimeoVideo(file, (responseJson) => {
                if (responseJson.status == 200) {
                    setIsVideoLoading(true)
                    addBattleVideo({ id: battleData.battle.id, link: responseJson.body.link }, (responseJson) => {
                        setIsVideoLoading(false)
                        setUploadVideoProgress(0)
                        if (responseJson.status == 200) {
                            loadBattleDataFull()
                        }
                    })
                }
            },
                (value) => {
                    setIsVideoLoading(true)
                    setUploadVideoProgress(value)
                })
        }
    }

    const onSendInvitations = () => {
        inviteStageBattle({ id: battleData.battle.id }, (responseJson) => {
            if (responseJson.status == 200) {
                onConfirmBattleInviteDialogClose()
                setEmailResult(responseJson.body)
            }
        })
    }

    const handleProgress = (status) => {
        const { playedSeconds } = status;
        setCurTime(playedSeconds)
    }

    const stopVideo = () => {
        setIsPlaying(false);
    }

    const playVideo = () => {
        setIsPlaying(true);
    }

    const seekVideoTo = (time) => {
        player.current.seekTo(time, 'seconds')
    }

    const onCommentChange = (event) => {
        setCommentText(event.target.value);
    };

    const onEditCommentOpen = (data) => {
        setCommentEditData(data)
        setOpenEditComment(true);
    };

    const onEditCommentClose = () => {
        setOpenEditComment(false);
    };

    const onVideoHistoryDialogClose = () => {
        setOpenVideoHistoryDialog(false);
    }

    const onVideoHistoryDialogOpen = () => {
        setOpenVideoHistoryDialog(true);
    }

    const onConfirmCommentDialogClose = () => {
        setOpenConfirmCommentDialog(false);
    }

    const onConfirmCommentDialogOpen = () => {
        setOpenConfirmCommentDialog(true);
    }

    const onConfirmAllCommentsDialogClose = () => {
        setOpenConfirmAllCommentsDialog(false);
    }

    const onConfirmAllCommentsDialogOpen = () => {
        setOpenConfirmAllCommentsDialog(true);
    }

    const onConfirmBattleInviteDialogClose = () => {
        setOpenConfirmBattleInvite(false);
    }

    const onConfirmBattleInviteDialogOpen = () => {
        setOpenConfirmBattleInvite(true);
    }

    const onOpenCommentsMenu = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const onCloseCommentsMenu = () => {
        setMenuAnchor(null);
    };

    const getTime = (value) => {
        var date = new Date(0);
        date.setSeconds(value);
        return date.toISOString().substr(11, 8);
    }

    useEffect(() => {
        loadBattleDataFull()
        loadBattleByID(data.battle.id)
    }, [data])

    if (battleData == null) return <Loader isLoading={true} />

    return (
        <div className={root}>
            <Grid container direction='row' style={{ height: '100%' }}>
                <Grid item xs={12} md='8' style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: '50%', height: 400, width: '100%', backgroundColor: 'black', position: 'relative' }}>
                        {battleData.video && <ReactPlayer
                            width="100%"
                            height='100%'
                            playing={isPlaying}
                            onProgress={handleProgress}
                            onPlay={playVideo}
                            ref={player}
                            controls
                            url={battleData.video.link}
                        />}
                        <LoaderProgress isLoading={isVideoLoading} value={uploadVideoProgress} />
                    </div>
                    <div style={{ width: '100%', paddingTop: 20, paddingBottom: 20, display: 'flex' }}>
                        <input
                            accept="video/*"
                            ref={fileInputRef}
                            type="file"
                            hidden
                            onChange={onUploadNewVideo}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => fileInputRef.current.click()}
                            style={{ marginRight: 20, flex: 1 }}
                            startIcon={<GetAppIcon />}
                        >
                            Загрузить новое видео
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onVideoHistoryDialogOpen}
                            style={{ flex: 1 }}
                            startIcon={<HistoryIcon />}
                        >
                            История загрузки видео
                        </Button>
                    </div>
                    <div style={{ width: '100%', paddingBottom: 20, display: 'flex' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onConfirmBattleInviteDialogOpen}
                            style={{ flex: 1 }}
                            startIcon={<EmailIcon />}
                        >
                            Отправить приглашение на упражнение
                        </Button>
                    </div>
                    <div>
                        <GradesPanel
                            battleGradesByCriterion={battleGradesByCriterion}
                            openGradesViewOnlyModal={openGradesViewOnlyModal}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md='4' style={{ padding: 20, height: '100%' }}>
                    <Paper elevation='3' style={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', padding: 10, height: 60, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant='h5'>Комментарии</Typography>
                            <IconButton
                                aria-controls="simple-menu" aria-haspopup="true"
                                onClick={onOpenCommentsMenu}
                            >
                                <SettingsIcon />
                            </IconButton>
                            <CommentsMenu anchor={menuAnchor} onClose={onCloseCommentsMenu} onClearClick={onConfirmAllCommentsDialogOpen} onDownloadClick={downloadCommentsCSV} />
                        </div>
                        <div style={{ flexGrow: 1, overflow: 'auto', paddingTop: 10 }}>
                            {battleData.video && battleData.video.comments && battleData.video.comments.items.map((comment) => (
                                <CommentItem data={comment} onClick={seekVideoTo} onEdit={onEditCommentOpen} />
                            ))}
                        </div>
                        <div style={{ padding: 10 }}>
                            <div>{`Время: ${getTime(playedSeconds)}`}</div>
                            <TextField
                                id="standard-full-width"
                                label="Комментарий"
                                multiline
                                rowsMax={15}
                                variant="outlined"
                                style={{ width: '100%' }}
                                placeholder="Введите комментарий"
                                margin="normal"
                                value={commentText}
                                onChange={onCommentChange}
                                onFocus={stopVideo}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment style={{ alignSelf: 'flex-end', marginBottom: 8 }} variant='filled' position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={onCommentSend}
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <VideoHistoryDialog
                data={battleData}
                open={openVideoHistoryDialog}
                onClose={onVideoHistoryDialogClose}
            />
            <EditCommentDialog data={commentEditData} onDelete={onConfirmCommentDialogOpen} open={openEditComment} onClose={onEditCommentClose} onComplete={onEditCommentComplete} />
            <ConfirmActionDialog
                title="Удалить коментарий?"
                text="Вы уверены, что хотите удалить комментарий?"
                open={openConfirmCommentDialog}
                onClose={onConfirmCommentDialogClose}
                onConfirm={onDeleteComment}
            />
            <ConfirmActionDialog
                title="Удалить все комментарии упражнения?"
                text="Вы уверены, что хотите удалить все комментарии упражнения?"
                open={openConfirmAllCommentsDialog}
                onClose={onConfirmAllCommentsDialogClose}
                onConfirm={onDeleteAllComments}
            />
            <ConfirmActionDialog
                title="Отправить приглашения на упражнение?"
                text="Вы уверены, что хотите отправить приглашения на упражнение?"
                open={openConfirmBattleInvite}
                onClose={onConfirmBattleInviteDialogClose}
                onConfirm={onSendInvitations}
            />
            <EmailResultDialog
                onRepeat={onRepeat}
                onCSV={onEmailsCSVDownload}
                result={emailResult}
                onClose={OnEmailResultClosed}
            />
            <GradesViewOnlyModal
                open={gradesViewOnlyModalOpen}
                onClose={closeGradesViewOnlyModal}
                gradesInfo={gradesData?.detailed_grade_info}
                gradesRange={gradesData?.min_max_grade_for_stage}
            />
        </div>
    )
}

const useStyles = makeStyles({
    root: {
        height: '100%'
    },
});
