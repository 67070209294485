const colors = [
  '#CD6155',
  '#EC7063',
  '#AF7AC5',
  '#A569BD',
  '#5499C7',
  '#5DADE2',
  '#48C9B0',
  '#45B39D',
  '#52BE80',
  '#58D68D',
  '#F4D03F',
  '#F5B041',
  '#EB984E',
  '#DC7633',
  '#CACFD2 ',
  '#5D6D7E ',
];

export const mapStageRoleToColor = (roles) => {
  let tempColors = [...colors];

  const colorMappings = {};

  roles.forEach((role) => {
    if (role.code_name === 'player') {
      colorMappings.player = role.graph_color ?? '#4073D3';
    } else if (role.rights?.write) {
      if (role.code_name === 'client') {
        colorMappings.client = role.graph_color ?? '#79E874';
      } else {
        if (role.graph_color) {
          colorMappings[role.id] = role.graph_color;
        } else {
          const randIndex = 0;

          const newColor = tempColors[randIndex];

          tempColors.splice(randIndex, 1);

          colorMappings[role.id] = newColor;
        }
      }
    }
  });

  return colorMappings;
};

export const mapStageResponseToStats = (response, colors, roles) => {
  if (!response || !colors || !roles || !response.stats) return undefined;

  const playerId = roles.find((item) => item.code_name === 'player')?.id;
  const clientId = roles.find((item) => item.code_name === 'client')?.id;

  const stats = {
    videos: {
      total: response.videos_total,
      done: response.videos_uploaded,
      color: colors['player'],
      tooltip: 'Видео загруженные участниками',
    },
    columns: {},
  };

  response.stats.forEach((statItem) => {
    const title = roles.find((item) => {
      return item.id === statItem.stage_role_id;
    })?.title;

    stats.columns[statItem.stage_role_id] = {
      total: statItem.total_comments,
      done: statItem.done_comments,
      color:
        statItem.id === playerId
          ? colors.player
          : statItem.id === clientId
            ? colors.client
            : colors[statItem.stage_role_id],
      tooltip: `Комментарии - ${title}`,
    };
  });

  return stats;
};