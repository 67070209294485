import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

const CompetenceUpdatedModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle style={{ textAlign: 'center' }}>Успех!</DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 24px 16px',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        Компетенция успешно обновлена
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onClose} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompetenceUpdatedModal;
