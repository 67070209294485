import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  weightAndTooltipWrapper: {
    backgroundColor: '#fff',
    borderRadius: 6,
    padding: '0 6px',
  },
  criterionWeightLabel: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    fontSize: 11,
    lineHeight: '23px',
    color: '#828282',
  },
});
