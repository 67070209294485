import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Button from '@material-ui/core/Button';

export default function SincBattlesButton(props) {
    const {onClick} = props;

    return (
        <Button color='inherit' onClick={onClick} endIcon={<PlaylistAddIcon/>}>
            Заполнить упражнения 
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({

}));
