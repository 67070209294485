import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  criterionCardContainer: {
    boxShadow: '0 0 6px -4px grey',
    borderRadius: 6,
    padding: 16,
    boxSizing: 'border-box',
    transition: 'height 0.5s ease',
    backgroundColor: 'rgba(252, 252, 252, 1)',
    margin: '5.5px 0',

    '&:first-child': {
      margin: '0 0 5.5px',
    },
    '&:last-child': {
      margin: '5.5px 0 0',
    },
  },
  criterionNameInputWrapper: {
    paddingRight: 8,
    alignSelf: 'baseline',
  },
  weightAndTooltipWrapper: {
    alignSelf: 'baseline',
    backgroundColor: '#fff',
    borderRadius: 6,
    padding: '10px 6px',
  },
  criterionWeightLabel: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    fontSize: 11,
    lineHeight: '23px',
    color: '#828282',
  },
  deleteCriteriaIcon: {
    color: '#FFA5A5',
    padding: 0,
    marginBottom: 6,
    '&:hover': {
      color: '#FB4646',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 28,
    },
  },
  editCriteriaIcon: {
    color: '#FFDBA5',
    padding: 0,
    '&:hover': {
      color: '#FCBA56',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 28,
    },
  },
  confirmEditCriteriaIcon: {
    color: '#C5EA8A',
    padding: 0,
    '&:hover': {
      color: '#AAE944',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 28,
    },
  },
});
